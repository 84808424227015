interface AnyObject {
    [key: string]: any;
}

export function convertEmptyStringToUndefined(obj: AnyObject): AnyObject {
    for (let key in obj) {
        if (obj[key] === '') {
            obj[key] = undefined;
        }
    }
    return obj;
}

type RecursivelyReplaceNullWithUndefined<T> = T extends null
    ? undefined
    : T extends Date
    ? T
    : {
          [K in keyof T]: T[K] extends (infer U)[] ? RecursivelyReplaceNullWithUndefined<U>[] : RecursivelyReplaceNullWithUndefined<T[K]>;
      };

export function nullsToUndefined<T>(obj: T): RecursivelyReplaceNullWithUndefined<T> {
    if (obj === null) {
        return undefined as any;
    }
    if (obj === '') {
        return undefined as any;
    }

    // object check based on: https://stackoverflow.com/a/51458052/6489012
    if (obj?.constructor.name === 'Object') {
        for (let key in obj) {
            obj[key] = nullsToUndefined(obj[key]) as any;
        }
    }
    return obj as any;
}
