import { CompanyId } from 'interfaces/company-id';
import { generateParamPaginate, getBaseHeaderApi, setParam } from './services/apiHelper';
import { baseApi } from './services/services';
import { EmployeeId } from 'interfaces/employee-id';
import { ApiResponse, ApiResponseWithPagination } from 'interfaces/api-response';
import { Attendance } from 'interfaces/attendance';
import { PaginateRequest } from 'interfaces/api-request';
import { Employee } from 'interfaces/employee';
import { getToken } from 'lib/helpers/tokenHandler';

export interface CheckInRequestBody extends CompanyId, EmployeeId {
    location: string;
    jobdesc: string;
    longitude: string;
    latitude: string;
    work_category_id?: string;
    cost_center_id?: string;
    photos: any[];
    other_work_category: string;
}

export interface CheckOutRequestBody {
    longitude: string;
    latitude: string;
    photos: any[];
}

export interface EmployeeExportExcelRequestBody {
    employee_id?: number;
    from_date?: Date;
    until_date?: Date;
}

export const attendanceApi = baseApi.enhanceEndpoints({ addTagTypes: ['attendance', 'checkAttendance'] }).injectEndpoints({
    endpoints(builder) {
        return {
            getAttendance: builder.query<ApiResponseWithPagination<Attendance[]>, PaginateRequest & { work_category?: string }>({
                query: (request) => ({
                    url: `/attendance?${generateParamPaginate(request)}${setParam('work_category', request.work_category)}`,
                    method: 'GET',
                    headers: getBaseHeaderApi()
                }),
                providesTags: ['attendance']
            }),
            getAttendanceCompany: builder.query<ApiResponseWithPagination<Attendance[]>, PaginateRequest & { work_category?: string }>({
                query: (request) => ({
                    url: `/attendance/company?${generateParamPaginate(request)}${setParam('work_category', request.work_category)}`,
                    method: 'GET',
                    headers: getBaseHeaderApi()
                }),
                providesTags: ['attendance']
            }),
            getCheckAttendance: builder.query<ApiResponse<Attendance>, void>({
                query: () => ({
                    url: '/check_attendance',
                    method: 'GET',
                    headers: getBaseHeaderApi()
                }),
                providesTags: ['checkAttendance']
            }),
            checkIn: builder.mutation({
                query: (data: CheckInRequestBody) => ({
                    url: '/attendance',
                    method: 'POST',
                    headers: getBaseHeaderApi(),
                    data
                }),
                invalidatesTags: ['attendance', 'checkAttendance']
            }),
            checkOut: builder.mutation<any, { data: CheckOutRequestBody; id: string | any }>({
                query: ({ data, id }) => ({
                    url: `/attendance/${id}`,
                    method: 'PUT',
                    headers: getBaseHeaderApi(),
                    data
                }),
                invalidatesTags: ['attendance', 'checkAttendance']
            }),
            exportExcelAttendanceEmployee: builder.mutation<ApiResponse<{ file_name: string }>, EmployeeExportExcelRequestBody>({
                query: (data) => ({
                    url: `/attendance/employee_export_excel`,
                    method: 'POST',
                    headers: getBaseHeaderApi(),
                    data
                })
            }),
            exportPdfAttendanceEmployee: builder.mutation<ApiResponse<{ file_name: string }>, EmployeeExportExcelRequestBody>({
                query: (data) => ({
                    url: `/attendance/employee_export_pdf`,
                    method: 'POST',
                    headers: getBaseHeaderApi(),
                    data
                })
            })
        };
    }
});

export const {
    useGetAttendanceQuery,
    useGetCheckAttendanceQuery,
    useCheckInMutation,
    useCheckOutMutation,
    useGetAttendanceCompanyQuery,
    useExportExcelAttendanceEmployeeMutation,
    useExportPdfAttendanceEmployeeMutation
} = attendanceApi;
