import { Dialog, DialogContent, CircularProgress, DialogTitle, DialogActions, Button, Box, Divider, Grid } from '@mui/material';
import { FormInput } from 'components/input/hook-form';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { decodeHashId } from 'lib/helpers/hasher';
import Cookies from 'universal-cookie';
import config from 'config';
import { WorkCategoryRequestBody } from 'lib/api/admin/adminWorkCategoryApi';
import { SubCompany } from 'interfaces/sub-company';
import { SubCompanyRequestBody, useUpdateSubCompanyMutation } from 'lib/api/admin/adminSubCompanyApi';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import { LeaveFlowRequestBody, useUpdateLeaveFlowMutation } from 'lib/api/admin/adminLeaveFlowApi';
import { LeaveFlow } from 'interfaces/leave-flow';
import CustomSelect from 'components/input/CustomSelect';
import SelectEmployee from 'modules/admin/employee/components/SelectEmployee';
import { Department } from 'interfaces/department';
import { useGetDepartmentsQuery } from 'lib/api/admin/adminDepartmentApi';

interface IEditLeaveFlowDialogProps {
    open: boolean;
    handleClose: () => void;
    leaveFlow: LeaveFlow;
}

const EditLeaveFlowDialog = ({ open, handleClose, leaveFlow }: IEditLeaveFlowDialogProps) => {
    useEffect(() => {
        setValue('approver_1', leaveFlow.approver_1.toString());
        setValue('approver_2', leaveFlow.approver_2.toString());
        setValue('approver_3', leaveFlow.approver_3.toString());
        setSelectedDepartment(leaveFlow.department);
    }, [leaveFlow]);

    const cookies = new Cookies();
    const [updateLeaveFlow] = useUpdateLeaveFlowMutation();
    const initialFormData: LeaveFlowRequestBody = {
        approver_1: '',
        approver_2: '',
        approver_3: '',
        department_id: ''
    };
    const { handleSubmit, control, reset, setValue } = useForm({
        defaultValues: initialFormData,
        mode: 'onSubmit',
        resolver: yupResolver(
            yup
                .object({
                    department_id: yup.string().required(),
                    approver_1: yup.string().required(),
                    approver_2: yup.string().required(),
                    approver_3: yup.string().required()
                })
                .required()
        )
    });
    const onSubmit = async (data: LeaveFlowRequestBody) => {
        setLoading(true);
        await updateLeaveFlow({ data: data, id: leaveFlow.id ?? '' })
            .unwrap()
            .then((e) => {
                setLoading(false);
                withReactContent(Swal).fire('Sukses', 'Berhasil mengubah data!', 'success');
            })
            .catch((error) => {});
        reset.apply(initialFormData);
        setLoading(false);
        handleClosee();
    };

    const [isLoading, setLoading] = useState(false);
    const handleClosee = (event?: any, reason?: any) => {
        if (!isLoading) {
            handleClose();
        }
        if (reason && reason == 'backdropClick') return;
        handleClose();
    };

    const { isLoading: isGettingDepartment, error: errorDepartment, data: dataDepartments } = useGetDepartmentsQuery();
    const [selectedDepartment, setSelectedDepartment] = useState<Department | null>();
    useEffect(() => {
        setValue('department_id', selectedDepartment?.id);
    }, [selectedDepartment]);
    return (
        <Dialog open={open} onClose={handleClosee} fullWidth maxWidth={'md'}>
            <form onSubmit={handleSubmit(onSubmit)}>
                {isLoading ? (
                    <DialogContent>
                        <CircularProgress />
                    </DialogContent>
                ) : (
                    <div>
                        <DialogTitle variant="h3">Edit Sub Company</DialogTitle>
                        <DialogContent>
                            <Grid container spacing={20}>
                                <Grid item md={12}>
                                    <CustomSelect
                                        required
                                        label="Department"
                                        getOptionLabel={(e) => e.name ?? ''}
                                        getOptionValue={(e) => e.id ?? ''}
                                        options={dataDepartments?.data}
                                        value={selectedDepartment}
                                        menuPortalTarget={document.querySelector('body')}
                                        styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                                        onChange={(newValue, actionMeta) => {
                                            setSelectedDepartment(newValue);
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Divider></Divider>
                                </Grid>
                                <Grid item md={6}>
                                    <SelectEmployee
                                        control={control}
                                        label="Appprover 1"
                                        name="approver_1"
                                        initEmployee={leaveFlow?.approver1}
                                        onSelectedEmployee={(e) => {
                                            setValue('approver_1', e?.id?.toString());
                                        }}
                                        paginateRequest={{ per_page: 5, is_approver: '0' }}
                                    />
                                </Grid>
                                <Grid item md={6}>
                                    <SelectEmployee
                                        control={control}
                                        label="Appprover 2"
                                        name="approver_2"
                                        initEmployee={leaveFlow?.approver2}
                                        onSelectedEmployee={(e) => {
                                            setValue('approver_2', e?.id?.toString());
                                        }}
                                        paginateRequest={{ per_page: 5, is_approver: '0' }}
                                    />
                                </Grid>
                                <Grid item md={6}>
                                    <SelectEmployee
                                        control={control}
                                        label="Appprover 3"
                                        name="approver_3"
                                        initEmployee={leaveFlow?.approver3}
                                        onSelectedEmployee={(e) => {
                                            setValue('approver_3', e?.id?.toString());
                                        }}
                                        paginateRequest={{ per_page: 5, is_approver: '0' }}
                                    />
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button size="large" type="submit">
                                Update
                            </Button>
                        </DialogActions>
                    </div>
                )}
            </form>
        </Dialog>
    );
};

export default EditLeaveFlowDialog;
