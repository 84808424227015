import { useState, useEffect } from 'react';
import CardBoxUser from '../components/cardBoxUser';
import { useForm } from 'react-hook-form';
import { Button } from '@mui/material';
import userDefault from 'assets/images/users/user-round.svg';
import { useAvatarMutation } from 'lib/api/profileApi';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { Employee } from 'interfaces/employee';

// import { TenantType, daftarTenant, RoleType, daftarRole } from '@/_lib/data/viewHelper';

interface FormData {
    avatar: Blob; // Define the expected form data shape
}

const UserProfile = ({ userData }: { userData: Employee }) => {
    const dispatch = useAppDispatch();
    const [createAvatar] = useAvatarMutation();
    const [loading, setLoading] = useState(false);
    const {
        handleSubmit,
        control,
        reset,
        formState: { isDirty }
    } = useForm<FormData>();

    const avatarImage = userData.url_avatar ?? userDefault;

    const onSubmit = (data: { avatar: Blob }) => {
        setLoading(true);
        const formData = new FormData();
        if (data.avatar) {
            formData.append('avatar', data.avatar);
        }
        createAvatar(formData)
            .unwrap()
            .then((res) => {
                // handleSuccess('Successfully Added');
                // const refreshObj = getCookie('APP-SECURE');
                // if (res.data) {
                //     Cookies.remove('APP-USER');
                //     saveCookie('APP-USER', res.data, refreshObj.expires_in);
                //     dispatch(
                //         setUser({
                //             id: res.data?.id as unknown as string,
                //             name: res.data?.name,
                //             tenant: res.data?.tenant,
                //             email: res.data?.email,
                //             url_avatar: res.data?.url_avatar,
                //             role: res.data?.role
                //         })
                //     );
                // }
                reset();
                setLoading(false);
            })
            .catch(
                (error: {
                    data: {
                        message: string;
                    };
                    status: string;
                }) => {
                    if (error.data?.message) {
                        console.log(error.data.message);
                        // handleError(error.data.message);
                    } else {
                        // handleError(error.data.message);
                    }
                    setLoading(false);
                }
            );
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <CardBoxUser
                userName={userData.first_name as string}
                lastName={userData.last_name as string}
                role={userData?.title?.name as string}
                avatarImage={avatarImage}
                control={control}
                name="avatar"
            />
            {isDirty && (
                <Button
                    type="submit"
                    variant="contained"
                    color="primary" // You can change 'primary' to 'secondary', 'default', etc.
                    className="ml-5"
                    disabled={loading}
                >
                    {loading ? 'Loading...' : 'Submit'}
                </Button>
            )}
        </form>
    );
};

export default UserProfile;
