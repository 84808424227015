import dashboard from './dashboard';
import pages from './pages';
import utilities from './utilities';
import other from './other';
import features from './features';
import adminFeatures from '../user-menu-items/employementFeatures';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
    items: [dashboard, features]
};

export default menuItems;
