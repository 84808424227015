import { Link, Navigate, useLocation } from 'react-router-dom';

// material-ui
import { Box, Card, Divider, Grid } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { Layout } from 'components/layout';
import FormLogin from '../components/FormLogin';
// @ts-ignore
import Logo from 'assets/logos/hr-logo.png';
import Cookies from 'universal-cookie';
import config from 'config';
import UserFormLogin from '../components/UserFormLogin';

const UserLoginPage = () => {
    const theme = useTheme();
    const cookies = new Cookies();
    return cookies.get(config.cookieToken) ? (
        <Navigate to="/admin/" />
    ) : (
        <Layout pageTitle="Login">
            <Box sx={{ backgroundColor: theme.palette.primary.main, minHeight: '100vh', p: 10 }}>
                <Grid container direction="column" justifyContent="flex-end" sx={{ minHeight: '100vh' }}>
                    <Grid item xs={12}>
                        <Grid container justifyContent="center" alignItems="center" sx={{ minHeight: 'calc(100vh - 68px)' }}>
                            <Grid item sx={{ m: { xs: 1, sm: 3 }, mb: 0 }}>
                                <Card sx={{ maxWidth: { xs: 400, lg: 475 }, p: { xs: 20, sm: 30, md: 30 } }}>
                                    <Grid container spacing={2} alignItems="center" justifyContent="center">
                                        <Grid item sx={{ mb: 20 }}>
                                            <Link to="#">
                                                <img src={Logo} alt="Logo" style={{ width: 200, height: 50 }} />
                                            </Link>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <UserFormLogin />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Divider />
                                        </Grid>
                                    </Grid>
                                </Card>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </Layout>
    );
};

export default UserLoginPage;
