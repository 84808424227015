import { yupResolver } from '@hookform/resolvers/yup';
import { Grid, Card, CardContent, Box, Button, Typography, LinearProgress } from '@mui/material';
import { FormInput } from 'components/input/hook-form';
import { Layout } from 'components/layout';
import { useGetProfileQuery } from 'lib/api/profileApi';
import { decodeHashId } from 'lib/helpers/hasher';
import { useForm } from 'react-hook-form';
import Cookies from 'universal-cookie';
import * as yup from 'yup';
import config from '../../../config';
import { useEffect, useState } from 'react';
import { BaseSelectData } from 'interfaces/base-select-data';
import CustomSelect from 'components/input/CustomSelect';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { useNavigate } from 'react-router';
import { CountLeaveDate } from 'interfaces/count-leave-date';
import Loader from 'ui-component/Loader';
import { LeaveRequestBody, useCountLeaveDateMutation, useCreateLeaveMutation, useLazyGetCheckLeaveQuery } from 'lib/api/leaveApi';
import { dateToStringWIB } from 'lib/helpers/dateHelper';

const CreateLeavePage = () => {
    const [checkLeave] = useLazyGetCheckLeaveQuery();

    var listType: BaseSelectData[] = [
        {
            value: 'EL',
            label: 'Entiled Leaved (Hak  Cuti)'
        },
        {
            value: 'UL',
            label: 'Unpaid Leave (Cuti Diluar Tanggungan)'
        },
        {
            value: 'SL',
            label: 'cuti Khusus (Keluarga inti meninggal, pernikahan, kelahiran anak, khitan anak)'
        }
    ];
    // - TYPE
    const [selectedType, setSelectedType] = useState<BaseSelectData | null>(listType[0]);
    useEffect(() => {
        if (selectedType) {
            setValue('type', selectedType!.value);
        }
    }, [selectedType]);
    const cookies = new Cookies();
    const initialFormData: LeaveRequestBody = {
        reason: '',
        from_date: '',
        until_date: '',
        type: ''
    };
    const { handleSubmit, control, reset, setValue, watch } = useForm({
        defaultValues: initialFormData,
        mode: 'onSubmit',
        resolver: yupResolver(
            yup
                .object({
                    reason: yup.string().required(),
                    from_date: yup.string().required(),
                    until_date: yup.string().required(),
                    type: yup.string().required()
                })
                .required()
        )
    });
    const [createLeave] = useCreateLeaveMutation();
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const onSubmit = async (data: LeaveRequestBody) => {
        setIsLoading(true);
        // data.title_id = dataTitles?.data?.find((e) => e.name == selectedTitle)?.id;
        // data.department_id = dataDepartments?.data?.find((e) => e.name == selectedDepartment)?.id;
        // data.role = dataRoles?.data?.find((e) => e.name == selectedRole)?.id;
        // data.bank_id = dataBanks?.data?.find((e) => e.name == selectedBank)?.id;

        await createLeave(data)
            .unwrap()
            .then((e) => {
                setIsLoading(false);
                withReactContent(Swal)
                    .fire('Sukses', `Sukses menambahkan data!`, 'success')
                    .then(() => {
                        navigate(-1);
                        reset(initialFormData);
                    });
            })
            .catch((error) => {});
        // reset.apply(initialFormData);
        setIsLoading(false);
    };
    const { isLoading: profileIsLoading, data: profileData, isFetching: profileIsFetching } = useGetProfileQuery();
    const [countLeaveDate] = useCountLeaveDateMutation();

    const [startDate, setStartDate] = useState<Date>();
    const [untilDate, setUntilDate] = useState<Date>();

    const [countLeaveData, setCountLeaveData] = useState<CountLeaveDate[]>([]);
    const [countLeaveIsLoading, setCountLeaveIsLoading] = useState(false);

    const [remainingLeave, setRemainingLeave] = useState<number | undefined>();
    const [period, setPeriod] = useState<string | undefined>();
    const [toggleSeeMore, setToggleSeeMore] = useState(false);

    useEffect(() => {
        if (startDate) {
            setIsLoading(true);
            setRemainingLeave(undefined);
            setPeriod(undefined);
            checkLeave({
                from_date: startDate
            }).then((e) => {
                setRemainingLeave(e.data?.data?.remaining_leave_days);
                setPeriod(
                    dateToStringWIB({ date: e.data?.data?.start_period, customFormat: 'YYYY/MM/DD' }) +
                        ' - ' +
                        dateToStringWIB({ date: e.data?.data?.end_period, customFormat: 'YYYY/MM/DD' })
                );
                setIsLoading(false);
            });
        }
        if (startDate && untilDate) {
            setCountLeaveIsLoading(true);
            countLeaveDate({ from_date: startDate.toDateString(), until_date: untilDate.toDateString() })
                .unwrap()
                .then((e) => {
                    setCountLeaveData(e?.data ?? []);
                    setCountLeaveIsLoading(false);
                })
                .catch((e) => {
                    setCountLeaveData([]);
                    setUntilDate(undefined);
                    setValue('until_date', '');
                    setCountLeaveIsLoading(false);
                });
        }
    }, [startDate, untilDate]);

    useEffect(() => {
        if ((profileData?.data?.leave_balance ?? 0) <= 0) {
            setSelectedType({
                value: 'UL',
                label: 'Unpaid Leave (Cuti Diluar Tanggungan)'
            });
            listType = [
                {
                    value: 'EL',
                    label: 'Entiled Leaved (Hak  Cuti)',
                    isDisabled: true
                },
                {
                    value: 'UL',
                    label: 'Unpaid Leave (Cuti Diluar Tanggungan)'
                },
                {
                    value: 'SL',
                    label: 'cuti Khusus (Keluarga inti meninggal, pernikahan, kelahiran anak, khitan anak)'
                }
            ];
        } else {
            listType = [
                {
                    value: 'EL',
                    label: 'Entiled Leaved (Hak  Cuti)'
                },
                {
                    value: 'UL',
                    label: 'Unpaid Leave (Cuti Diluar Tanggungan)'
                },
                {
                    value: 'SL',
                    label: 'cuti Khusus (Keluarga inti meninggal, pernikahan, kelahiran anak, khitan anak)'
                }
            ];
        }
    }, [profileData]);

    return (
        <Layout headerTitle="Request Leave" pageTitle="Request Leave" breadcrumbMiddleItems={[{ name: 'Leave', url: '/leave' }]}>
            <Grid container direction={'column'} spacing={20}>
                <Grid item>
                    <Card>
                        <CardContent>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <Grid container spacing={20} alignItems={'start'}>
                                    <Grid item container xs={12} md={8} spacing={20}>
                                        <Grid item xs={12} md={6}>
                                            <FormInput
                                                isShrink
                                                required
                                                control={control}
                                                label="Start Date"
                                                type="date"
                                                name="from_date"
                                                onChange={(e) => {
                                                    setStartDate(new Date(e.target.value));
                                                    setValue('from_date', e.target.value);
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <FormInput
                                                isShrink
                                                required
                                                control={control}
                                                label="Until Date"
                                                type="date"
                                                name="until_date"
                                                onChange={(e) => {
                                                    setUntilDate(new Date(e.target.value));
                                                    setValue('until_date', e.target.value);
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <FormInput
                                                isShrink
                                                required
                                                control={control}
                                                label="Period"
                                                type="text"
                                                name="period"
                                                disabled
                                                value={period}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <FormInput
                                                isShrink
                                                required
                                                control={control}
                                                label="Remaining Leave"
                                                type="text"
                                                name="remaining_leave"
                                                disabled
                                                value={remainingLeave?.toString()}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6} paddingTop={0} marginTop={0}>
                                            <CustomSelect
                                                required
                                                label="Type"
                                                getOptionLabel={(e) => e.label ?? ''}
                                                getOptionValue={(e) => e.value ?? ''}
                                                options={listType}
                                                value={selectedType}
                                                menuPortalTarget={document.querySelector('body')}
                                                onChange={(newValue, actionMeta) => {
                                                    if (newValue?.value == 'EL' && (profileData?.data?.leave_balance ?? 0) <= 0) {
                                                        setSelectedType({
                                                            value: 'UL',
                                                            label: 'Unpaid Leave (Cuti Diluar Tanggungan)'
                                                        });
                                                        return;
                                                    }
                                                    setSelectedType(newValue);
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={12}>
                                            <FormInput
                                                control={control}
                                                required
                                                label="Reason"
                                                type="text"
                                                name="reason"
                                                multiline
                                                minRows={5}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid
                                        item
                                        container
                                        xs={12}
                                        md={4}
                                        direction={'column'}
                                        sx={{ flex: 1 }}
                                        justifyContent={'center'}
                                        alignSelf={'center'}
                                    >
                                        {countLeaveIsLoading ? (
                                            <LinearProgress color="primary" />
                                        ) : (
                                            <div>
                                                <Grid item>
                                                    <Typography fontWeight={'medium'}>Your Leave Details</Typography>
                                                </Grid>
                                                <Grid item container sx={{ paddingY: 24, paddingLeft: 10 }} spacing={20}>
                                                    {countLeaveData.length != 0 ? (
                                                        countLeaveData.length > 10 && toggleSeeMore ? (
                                                            countLeaveData.map((e) => (
                                                                <Grid item container justifyContent={'space-between'} spacing={20}>
                                                                    <Grid item>
                                                                        <Typography color={e.countable ? 'primary' : 'red'}>
                                                                            {'\t' + new Date(e.date).toDateString()}
                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid item>
                                                                        <Typography color={e.countable ? 'primary' : 'red'}>
                                                                            {e.countable ? 'Weekday' : 'Weekend'}
                                                                        </Typography>
                                                                    </Grid>
                                                                </Grid>
                                                            ))
                                                        ) : (
                                                            countLeaveData.slice(0, 10).map((e) => (
                                                                <Grid item container justifyContent={'space-between'} spacing={20}>
                                                                    <Grid item>
                                                                        <Typography color={e.countable ? 'primary' : 'red'}>
                                                                            {'\t' + new Date(e.date).toDateString()}
                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid item>
                                                                        <Typography color={e.countable ? 'primary' : 'red'}>
                                                                            {e.countable ? 'Weekday' : 'Weekend'}
                                                                        </Typography>
                                                                    </Grid>
                                                                </Grid>
                                                            ))
                                                        )
                                                    ) : (
                                                        <Grid item>
                                                            <Typography>{'-'}</Typography>
                                                        </Grid>
                                                    )}
                                                    {countLeaveData.length > 10 && (
                                                        <Grid item container direction={'column'} spacing={20}>
                                                            {!toggleSeeMore && (
                                                                <Grid item sx={{ paddingLeft: 10 }}>
                                                                    <Typography>. . .</Typography>
                                                                </Grid>
                                                            )}
                                                            <Grid item sx={{ paddingLeft: 10, textAlign: 'center' }}>
                                                                <Button
                                                                    onClick={() => {
                                                                        setToggleSeeMore(!toggleSeeMore);
                                                                    }}
                                                                >
                                                                    {!toggleSeeMore ? 'See More' : 'See Less'}
                                                                </Button>
                                                            </Grid>
                                                        </Grid>
                                                    )}
                                                </Grid>
                                                <Grid item container justifyContent={'space-between'} spacing={20}>
                                                    <Grid item>
                                                        <Typography fontWeight={'medium'}>Total Leave</Typography>
                                                    </Grid>
                                                    <Grid item>
                                                        <Typography fontWeight={'medium'}>
                                                            {countLeaveData.length != 0
                                                                ? countLeaveData.filter((e) => e.countable).length
                                                                : '-'}{' '}
                                                            days
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </div>
                                        )}
                                    </Grid>

                                    <Grid item xs={12} textAlign={'right'}>
                                        <Button
                                            size="large"
                                            type="submit"
                                            disabled={
                                                isLoading ||
                                                !startDate ||
                                                !untilDate ||
                                                (countLeaveData.length == 0 ? true : countLeaveData.filter((e) => e.countable).length == 0)
                                                    ? true
                                                    : watch().type != 'EL'
                                                    ? false
                                                    : remainingLeave == 0
                                                    ? true
                                                    : !(countLeaveData.filter((e) => e.countable).length <= (remainingLeave ?? 0))
                                            }
                                        >
                                            Create
                                        </Button>
                                    </Grid>
                                </Grid>
                            </form>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Layout>
    );
};

export default CreateLeavePage;
