import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import { visuallyHidden } from '@mui/utils';
import { Employee } from 'interfaces/employee';
import { HeadCell, Order } from 'interfaces/table';
// import { useDeleteSalesMenuMutation } from "lib/api/salesMenuApi";
import { destinationPath } from 'lib/functions';
import { getComparator, stableSort } from 'lib/functions/table-sort';
// import { useRouter } from "next/navigation";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { dateToStringWIB } from 'lib/helpers/dateHelper';
const SweetAlert = withReactContent(Swal);

// PLEASE EXTENDS TO YOUR CUSTOM INTERFACE TABLE DATA
interface Data extends Employee {}

interface EnhancedTableProps {
    numSelected: number;
    onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void;
    onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
    order: Order;
    orderBy: string;
    rowCount: number;
}

// CHANGE INTERFACE BELOW BASED ON YOUR FUNCTION
interface ITableProps {
    tableData: Employee[];
}

interface IRowProps {
    data: Data;
}

// SETTING HEAD CELL TABLE HERE
const headCells: readonly HeadCell<Data>[] = [
    {
        id: 'first_name',
        numeric: false,
        disablePadding: true,
        label: 'Nama Employee'
    },
    {
        id: 'status',
        numeric: false,
        disablePadding: true,
        label: 'Status'
    },
    {
        id: 'end_date',
        numeric: false,
        disablePadding: true,
        label: 'Tanggal Kontrak Berakhir'
    }
];

// DEFAULT TABLE HEAD COMPONENT FROM MATERIAL UI (DONT CHANGE IT)
const EnhancedTableHead = (props: EnhancedTableProps) => {
    const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
    const createSortHandler = (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
                {/* <TableCell align={"left"} padding={"normal"}>
          Action
        </TableCell> */}
            </TableRow>
        </TableHead>
    );
};

// SETTING TABLE ROW HERE
const Row = ({ data }: IRowProps) => {
    return (
        <TableRow hover>
            <TableCell align="left">
                {data?.first_name} {data?.last_name}
            </TableCell>
            <TableCell align="left">{data?.status}</TableCell>
            <TableCell align="left">{dateToStringWIB({ date: data.end_date, customFormat: 'DD MMM YYYY' })}</TableCell>
        </TableRow>
    );
};

// DONT FORGET TO CHANGE FUNCTION
export default function EmployeeContractTable({ tableData }: ITableProps) {
    const [order, setOrder] = React.useState<Order>('desc');
    const [orderBy, setOrderBy] = React.useState<keyof Data>('id');
    const [selected, setSelected] = React.useState<readonly number[]>([]);

    const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof Data) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            const newSelected = tableData.map((n) => n.id);
            setSelected(newSelected as unknown as number[]);
            return;
        }
        setSelected([]);
    };

    return (
        <TableContainer component={Paper} sx={{ padding: 20 }}>
            <Table sx={{ minWidth: 350 }}>
                <EnhancedTableHead
                    numSelected={selected.length}
                    order={order}
                    orderBy={orderBy}
                    onSelectAllClick={handleSelectAllClick}
                    onRequestSort={handleRequestSort}
                    rowCount={tableData.length}
                />
                <TableBody>
                    {stableSort<Data>(tableData, getComparator(order, orderBy)).map((rowData, index) => {
                        return <Row key={index} data={rowData} />;
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
