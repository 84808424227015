import { FormControl, FormHelperText, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import { FormInputProps, SelectOptionBackend } from 'interfaces/input';
import { useController } from 'react-hook-form';

interface SelectInputProps extends FormInputProps {
    options?: SelectOptionBackend[];
}

export const SelectInputBackend = ({ options, control, name, placeHolder, label }: SelectInputProps) => {
    const { field, fieldState } = useController({ name, control });
    const { error } = fieldState;
    return (
        <FormControl fullWidth error={Boolean(error)}>
            <Typography variant="caption" paddingTop={0} marginTop={0}>
                {label}
            </Typography>
            <Select {...(placeHolder && { placeholder: placeHolder })} {...field}>
                {options?.map((value, i) => {
                    return (
                        <MenuItem key={i} value={value.id}>
                            {value.name}
                        </MenuItem>
                    );
                })}
            </Select>
            {Boolean(error) && <FormHelperText sx={{ color: 'red', mx: 0 }}>{error?.message}</FormHelperText>}
        </FormControl>
    );
};
