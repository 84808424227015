import { useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Card, CardContent, CircularProgress, Grid, Tab, Tabs } from '@mui/material';
import { Layout } from 'components/layout';
import config from 'config';
import { EmployeeRequestBody, useCreateEmployeeMutation } from 'lib/api/admin/adminEmployeeApi';
import { decodeHashId } from 'lib/helpers/hasher';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import Cookies from 'universal-cookie';
import * as yup from 'yup';
import { TabPanel, a11yProps } from 'components/tabs/BaseTab';
import PersonalDataTab from '../components/personalDataTab';
import EmploymentDataTab from '../components/employmentDataTab';
import PayrollDataTab from '../components/payrollDataTab';
import KeluargaDataTab from '../components/keluargaDataTab';
import { useGetCreateEmploymentDataQuery } from 'lib/api/admin/adminSettingApi';

const cookies = new Cookies();

const AdminCreateEmployeePage = () => {
    const { isLoading: isLoadingData, data } = useGetCreateEmploymentDataQuery();
    const [selectedTab, setSelectedTab] = useState(0);
    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setSelectedTab(+newValue);
    };

    const initialFormData: EmployeeRequestBody = {
        first_name: '',
        last_name: '',
        email: '',
        // password: '',
        status: '',
        gender: '',
        company_id: +decodeHashId(cookies.get(config.cookieCompanyId)),
        marital_status: '',
        blood_type: '',
        religion: '',
        citizen: '',
        tax_status: '',
        bpjs_ks_cost: '',
        retired_type: '',
        tanggungan: [
            {
                tanggungan: '',
                name: '',
                birth_place: '',
                birth_date: '',
                last_education: ''
            }
        ]
    };

    const navigate = useNavigate();
    const [createEmployee] = useCreateEmployeeMutation();
    const [isLoading, setIsLoading] = useState(false);
    const { setValue, handleSubmit, control, reset } = useForm({
        defaultValues: initialFormData,
        mode: 'onSubmit',
        resolver: yupResolver(
            yup
                .object({
                    first_name: yup
                        .string()
                        .matches(/^[A-Za-z ]*$/, 'Please enter valid name')
                        .required('*first_name is required'),
                    last_name: yup
                        .string()
                        .matches(/^[A-Za-z ]*$/, 'Please enter valid name')
                        .required('*last_name is required'),
                    email: yup.string().email('Please enter valid email').required('*email is required'),
                    // password: yup.string().required('*password is required').min(8, 'password is too short - should be 8 chars minimum.'),
                    ktp_number: yup.string().matches(/^\d+$/, 'Only numbers').required('*ktp_number is required'),
                    phone_number: yup.string().matches(/^\d+$/, 'Only numbers').required('*phone_number is required'),
                    status: yup.string().required('*status is required'),
                    join_date: yup.date().required('*join_date is required'),
                    gender: yup.string().required('*gender is required')
                })
                .required()
        )
    });

    const onSubmit = async (data: EmployeeRequestBody) => {
        setIsLoading(true);

        await createEmployee(data)
            .unwrap()
            .then((e) => {
                setIsLoading(false);
                withReactContent(Swal)
                    .fire('Sukses', `Sukses menambahkan employee! Password employee dikirim ke ${data.email}`, 'success')
                    .then(() => {
                        navigate(-1);
                        reset(initialFormData);
                    });
            })
            .catch((error) => {});
        // reset.apply(initialFormData);
        setIsLoading(false);
    };

    return (
        <Layout
            headerTitle="Create Employee"
            pageTitle="Create Employee"
            breadcrumbMiddleItems={[{ name: 'Employees', url: '/admin/employees' }]}
        >
            <Card>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <CardContent>
                        <Grid container justifyContent="center">
                            {isLoading || isLoadingData ? (
                                <CircularProgress />
                            ) : (
                                <Grid
                                    item
                                    container
                                    spacing={20}
                                    xs={12}
                                    justifyContent="center"
                                    sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex' }}
                                >
                                    <Grid item xs={1.5}>
                                        <Tabs
                                            orientation="vertical"
                                            variant="scrollable"
                                            value={selectedTab}
                                            onChange={handleChange}
                                            aria-label="Vertical tabs example"
                                            sx={{ borderRight: 1, borderColor: 'divider' }}
                                        >
                                            <Tab label="Personal Data" {...a11yProps(0)} />
                                            <Tab label="Employment Data" {...a11yProps(1)} />
                                            <Tab label="Payroll Data" {...a11yProps(2)} />
                                            <Tab label="Data Keluarga" {...a11yProps(3)} />
                                        </Tabs>
                                    </Grid>
                                    {selectedTab == 0 && (
                                        <Grid item xs={10.5}>
                                            <TabPanel value={selectedTab} index={0}>
                                                <PersonalDataTab control={control} />
                                            </TabPanel>
                                        </Grid>
                                    )}
                                    {selectedTab == 1 && (
                                        <Grid item xs={10.5}>
                                            <TabPanel value={selectedTab} index={1}>
                                                <EmploymentDataTab control={control} data={data?.data} />
                                            </TabPanel>
                                        </Grid>
                                    )}
                                    {selectedTab == 2 && (
                                        <Grid item xs={10.5}>
                                            <TabPanel value={selectedTab} index={2}>
                                                <PayrollDataTab control={control} data={data?.data} />
                                            </TabPanel>
                                        </Grid>
                                    )}
                                    {selectedTab == 3 && (
                                        <Grid item xs={10.5}>
                                            <TabPanel value={selectedTab} index={3}>
                                                <KeluargaDataTab control={control} />
                                            </TabPanel>
                                        </Grid>
                                    )}
                                    <Grid item xs={12} textAlign={'right'}>
                                        {selectedTab < 3 && (
                                            <Button
                                                size="large"
                                                onClick={(e) => {
                                                    setSelectedTab(selectedTab + 1);
                                                }}
                                            >
                                                Next
                                            </Button>
                                        )}

                                        <Button size="large" type="submit">
                                            Create
                                        </Button>
                                    </Grid>
                                </Grid>
                            )}
                        </Grid>
                    </CardContent>
                </form>
            </Card>
        </Layout>
    );
};

export default AdminCreateEmployeePage;
