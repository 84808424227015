// ==============================|| REDUX - MAIN STORE ||============================== //

import { configureStore } from '@reduxjs/toolkit';
// import { baseApi } from 'lib/api/axios';
import userReducer from './userSlice';
import customizationReducer from './customizationReducer';
import { adminApi, baseApi } from 'lib/api/services/services';

// ==============================|| REDUX - MAIN STORE ||============================== //

const store = configureStore({
    reducer: {
        customization: customizationReducer,
        user: userReducer,
        [baseApi.reducerPath]: baseApi.reducer,
        [adminApi.reducerPath]: adminApi.reducer
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat([baseApi.middleware, adminApi.middleware])
});

type RootState = ReturnType<typeof store.getState>;
type AppDispatch = typeof store.dispatch;

export { store, RootState, AppDispatch };
