import { Dashboard } from 'interfaces/dashboard';
import { PaginateRequest } from 'interfaces/api-request';
import { Leave } from 'interfaces/leave';
import { CompanyId } from 'interfaces/company-id';
import { baseApi } from './services/services';
import { ApiResponseWithPagination, ApiResponse } from 'interfaces/api-response';
import { generateParamPaginate, setParam, getBaseHeaderApi } from './services/apiHelper';
import { CountLeaveDate } from 'interfaces/count-leave-date';
import { LeaveCheck } from 'interfaces/leave-check';

export interface LeaveRequestBody {
    reason?: string;
    from_date?: string;
    until_date?: string;
    type: string;
}
export interface CountLeaveRequestBody {
    from_date?: string;
    until_date?: string;
}

export interface GiveFeedbackApprovalRequestBody {
    type: string;
    note: string;
    approver_number: string;
}

export interface LeaveCheckRequestBody {
    from_date: Date;
}

export const leaveApi = baseApi.enhanceEndpoints({ addTagTypes: ['leaves'] }).injectEndpoints({
    endpoints(builder) {
        return {
            getLeaveSearch: builder.mutation<
                ApiResponseWithPagination<Leave[]>,
                PaginateRequest & { name?: string; title?: string; role?: string; status?: string; is_approver?: string }
            >({
                query: (request) => ({
                    url: `/leave?${generateParamPaginate(request)}${setParam('title', request.title)}${setParam(
                        'status',
                        request.status
                    )}${setParam('role', request.role)}${setParam('is_approver', request.is_approver)}`,
                    method: 'GET',
                    headers: getBaseHeaderApi()
                })
            }),
            getLeave: builder.query<
                ApiResponseWithPagination<Leave[]>,
                PaginateRequest & { name?: string; title?: string; role?: string; status?: string; is_approver?: string }
            >({
                query: (request) => ({
                    url: `/leave?${generateParamPaginate(request)}${setParam('title', request.title)}${setParam(
                        'status',
                        request.status
                    )}${setParam('role', request.role)}`,
                    method: 'GET',
                    headers: getBaseHeaderApi()
                }),
                providesTags: ['leaves']
            }),
            getLeaveAdmin: builder.query<
                ApiResponseWithPagination<Leave[]>,
                PaginateRequest & { name?: string; title?: string; role?: string; status?: string; is_approver?: string }
            >({
                query: (request) => ({
                    url: `/admin/leave?${generateParamPaginate(request)}${setParam('title', request.title)}${setParam(
                        'status',
                        request.status
                    )}${setParam('role', request.role)}`,
                    method: 'GET',
                    headers: getBaseHeaderApi()
                }),
                providesTags: ['leaves']
            }),
            getLeaveApproval: builder.query<
                ApiResponseWithPagination<Leave[]>,
                PaginateRequest & { name?: string; title?: string; role?: string; status?: string; is_approver?: string }
            >({
                query: (request) => ({
                    url: `/leave_approval?${generateParamPaginate(request)}${setParam('title', request.title)}${setParam(
                        'status',
                        request.status
                    )}${setParam('role', request.role)}`,
                    method: 'GET',
                    headers: getBaseHeaderApi()
                }),
                providesTags: ['leaves']
            }),
            getLeaveById: builder.query<ApiResponse<Leave>, { id: string }>({
                query: (request) => ({
                    url: `/leave/${request.id}`,
                    method: 'GET',
                    headers: getBaseHeaderApi()
                }),
                providesTags: ['leaves']
            }),
            createLeave: builder.mutation<any, LeaveRequestBody>({
                query: (data: LeaveRequestBody) => ({
                    url: '/leave',
                    method: 'POST',
                    headers: getBaseHeaderApi(),
                    data
                }),
                invalidatesTags: ['leaves']
            }),
            countLeaveDate: builder.mutation<ApiResponse<CountLeaveDate[]>, CountLeaveRequestBody>({
                query: (data) => ({
                    url: '/leave_count',
                    method: 'POST',
                    headers: getBaseHeaderApi(),
                    data
                }),
                invalidatesTags: ['leaves']
            }),
            getCheckLeave: builder.query<ApiResponse<LeaveCheck>, LeaveCheckRequestBody>({
                query: (data) => ({
                    url: `/leave_check`,
                    method: 'POST',
                    headers: getBaseHeaderApi(),
                    data
                }),
                providesTags: ['leaves']
            }),
            updateLeave: builder.mutation<any, { data: LeaveRequestBody; id: string | number }>({
                query: ({ data, id }) => ({
                    url: `/leave/${id}`,
                    method: 'PUT',
                    headers: getBaseHeaderApi(),
                    data
                }),
                invalidatesTags: ['leaves']
            }),
            giveFeedbackApproval: builder.mutation<any, { data: GiveFeedbackApprovalRequestBody; id: string | number }>({
                query: ({ data, id }) => ({
                    url: `/leave_approval/${id}`,
                    method: 'PUT',
                    headers: getBaseHeaderApi(),
                    data
                }),
                invalidatesTags: ['leaves']
            }),
            deleteLeave: builder.mutation<any, { id: string | number }>({
                query: ({ id }) => ({
                    url: `/leave/${id}`,
                    method: 'DELETE',
                    headers: getBaseHeaderApi()
                }),

                invalidatesTags: ['leaves']
            })
        };
    }
});

export const {
    useGetLeaveSearchMutation,
    useGetLeaveQuery,
    useGetLeaveAdminQuery,
    useGetLeaveApprovalQuery,
    useCreateLeaveMutation,
    useGetLeaveByIdQuery,
    useUpdateLeaveMutation,
    useGiveFeedbackApprovalMutation,
    useDeleteLeaveMutation,
    useCountLeaveDateMutation,
    useLazyGetCheckLeaveQuery
} = leaveApi;
