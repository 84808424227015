import { Button, Card, CardContent, CardHeader, FormControl, Grid, IconButton, TableCell, TableRow, Typography } from '@mui/material';
import { Layout } from 'components/layout';
import BaseTablePaginator from 'components/table/BaseTablePaginator';
import { PaginateRequest } from 'interfaces/api-request';
import { Order } from 'interfaces/table';
import { useDeleteWorkCategoryMutation, useGetWorkCategoriesPaginateQuery } from 'lib/api/admin/adminWorkCategoryApi';
import { dateToStringWIB } from 'lib/helpers/dateHelper';
import CardFilter from 'modules/admin/employee/components/CardFilter';
import React, { useEffect, useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { useGetWorkCategoryTypesQuery } from 'lib/api/admin/adminWorkCategoryType';
import { WorkCategoryType } from 'interfaces/work-category-type';
import Select from 'react-select';
import { useNavigate } from 'react-router';
import CreateWorkCategoryDialog from '../components/CreateWorkCategoryDialog';
import EditWorkCategoryDialog from '../components/EditWorkCategoryDialog';
import { WorkCategory } from 'interfaces/work-category';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import ChangeCircleOutlinedIcon from '@mui/icons-material/ChangeCircleOutlined';
import ReplaceWorkCategoryDialog from '../components/ReplaceWorkCategoryDialog';
import FilterSelectContent from 'modules/admin/employee/components/FilterSelectContent';

const MySwal = withReactContent(Swal);

const styles = {
    valueContainer: (base: any) => ({
        ...base,
        maxHeight: 80,
        overflowY: 'auto'
    }),
    multiValue: (base: any, state: any) => {
        return state.data.isFixed ? { ...base, backgroundColor: 'gray' } : base;
    },
    multiValueLabel: (base: any, state: any) => {
        return state.data.isFixed ? { ...base, fontWeight: 'bold', color: 'white', paddingRight: 6 } : base;
    },
    multiValueRemove: (base: any, state: any) => {
        return state.data.isFixed ? { ...base, display: 'none' } : base;
    }
};

const AdminWorkCategoryPage = () => {
    const navigate = useNavigate();
    const [orderBy, setOrderBy] = useState<string>();
    const [order, setOrder] = useState<Order>('asc');
    const [paginateRequest, setPaginateRequest] = useState<PaginateRequest & { work_category_type?: string }>({
        current_page: 0,
        per_page: 10,
        order: order
    });
    useEffect(() => {
        setPaginateRequest({ ...paginateRequest, order: order });
    }, [order]);

    useEffect(() => {
        setPaginateRequest({ ...paginateRequest, order_by: orderBy });
    }, [orderBy]);
    const { isLoading, error, data, isFetching } = useGetWorkCategoriesPaginateQuery({
        ...paginateRequest,
        current_page: +paginateRequest.current_page! + 1
    });
    const { isLoading: categoryTypeIsLoading, data: categoryTypeData } = useGetWorkCategoryTypesQuery();
    const [selectedCategoryType, setSelectedCategoryType] = useState<readonly WorkCategoryType[]>([]);
    // useEffect(() => {
    //     setSelectedCategoryType(categoryTypeData?.data ?? []);
    // }, [categoryTypeData]);
    // useEffect(() => {
    //     setPaginateRequest({
    //         ...paginateRequest,
    //         work_category_type: selectedCategoryType.map((val) => val.name).join(',')
    //     });
    // }, [selectedCategoryType]);

    const [openDialogCreateWorkCategory, setopenDialogCreateWorkCategory] = useState(false);
    const [openDialogEditWorkCategory, setopenDialogEditWorkCategory] = useState(false);
    const [openDialogSwitchWorkCategory, setopenDialogSwitchWorkCategory] = useState(false);
    // const handleClickCheckInOpen = () => {
    //     setopenDialogCreateWorkCategory(true);
    // };

    const handleCreateDialogClose = () => {
        setopenDialogCreateWorkCategory(false);
    };
    const handleEditDialogClose = () => {
        setopenDialogEditWorkCategory(false);
    };
    const handleSwitchDialogClose = () => {
        setopenDialogSwitchWorkCategory(false);
    };
    const [selectedWorkCategory, setselectedWorkCategory] = useState<WorkCategory>();

    const [deleteWorkCategory] = useDeleteWorkCategoryMutation();
    const handleDelete = (workCategory: WorkCategory) => {
        MySwal.fire({
            title: 'Are you sure?',
            text: 'You will delete this item!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete item!'
        }).then((result) => {
            if (result.isConfirmed) {
                deleteWorkCategory({ id: workCategory.id ?? '' })
                    .unwrap()
                    .then((res) => {
                        MySwal.fire('Sukses', 'Data berhasil dihapus!', 'success');
                    })
                    .catch((err) => {
                        console.error(err.message ? err.message : err.data.message);
                        MySwal.fire('Gagal', err.message ? err.message : err.data.message, 'error');
                    });
            }
        });
    };
    return (
        <Layout headerTitle="Work Categories" pageTitle="Work Categories" disableBreadcrumb>
            <Grid container direction={'column'} spacing={20}>
                <Grid item>
                    <CardFilter isLoading={categoryTypeIsLoading}>
                        <Grid container spacing={20}>
                            <Grid item xs={12}>
                                <FilterSelectContent
                                    label="Select Work Category Type"
                                    isMulti
                                    closeMenuOnSelect={false}
                                    getOptionLabel={(e) => e.name ?? ''}
                                    getOptionValue={(e) => e.id.toString() ?? ''}
                                    options={categoryTypeData?.data}
                                    value={selectedCategoryType}
                                    menuPortalTarget={document.querySelector('body')}
                                    styles={{ ...styles }}
                                    onChange={(newValue, actionMeta) => {
                                        setSelectedCategoryType(newValue);
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} textAlign={'right'}>
                                <Button
                                    variant="contained"
                                    onClick={() => {
                                        setPaginateRequest({
                                            ...paginateRequest,
                                            work_category_type: selectedCategoryType.map((val) => val.name).join(',')
                                        });
                                    }}
                                >
                                    Apply
                                </Button>
                            </Grid>
                        </Grid>
                    </CardFilter>
                </Grid>
                <Grid item>
                    <Card>
                        <CardContent>
                            <Grid container direction={'column'} spacing={20}>
                                <Grid item container justifyContent="flex-end" spacing={8}>
                                    <Grid item>
                                        <Button
                                            variant="outlined"
                                            onClick={(val) => {
                                                setopenDialogCreateWorkCategory(true);
                                            }}
                                        >
                                            Add Work Category
                                        </Button>
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <BaseTablePaginator
                                        isLoading={isLoading || isFetching || categoryTypeIsLoading}
                                        count={data?.meta.total ?? 0}
                                        rowsPerPage={+paginateRequest.per_page!}
                                        page={+paginateRequest.current_page!}
                                        onPageChange={function (
                                            event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
                                            newPage: number
                                        ): void {
                                            setPaginateRequest({ ...paginateRequest, current_page: newPage });
                                        }}
                                        onRowsPerPageChange={function (
                                            event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
                                        ): void {
                                            setPaginateRequest({
                                                ...paginateRequest,
                                                current_page: 0,
                                                per_page: parseInt(event.target.value, 10)
                                            });
                                        }}
                                        onSearchchange={function (e: string): void {
                                            setPaginateRequest({ ...paginateRequest, q: e, current_page: 0 });
                                        }}
                                        orderBy={orderBy}
                                        order={order}
                                        onRequestSort={(newOrderBy, newOrder) => {
                                            setOrderBy(newOrderBy);
                                            setOrder(newOrder);
                                            setPaginateRequest({ ...paginateRequest, current_page: 0 });
                                        }}
                                        headCells={[
                                            { id: 'name', label: 'Name', isSortable: true },
                                            { id: 'work_category_type', label: 'Type', isSortable: true },
                                            { id: 'action', label: 'Actions', isSortable: false }
                                        ]}
                                    >
                                        {data?.data?.map((row) => (
                                            <TableRow key={row.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                <TableCell component="th" scope="row">
                                                    {row.name}
                                                </TableCell>
                                                <TableCell>{row.work_category_type?.name ?? '-'}</TableCell>
                                                <TableCell>
                                                    <IconButton
                                                        color="primary"
                                                        onClick={() => {
                                                            setselectedWorkCategory(row);
                                                            setopenDialogSwitchWorkCategory(true);
                                                        }}
                                                    >
                                                        <ChangeCircleOutlinedIcon />
                                                    </IconButton>
                                                    <IconButton
                                                        color="primary"
                                                        onClick={() => {
                                                            setselectedWorkCategory(row);
                                                            setopenDialogEditWorkCategory(true);
                                                        }}
                                                    >
                                                        <EditIcon />
                                                    </IconButton>
                                                    <IconButton
                                                        color="error"
                                                        onClick={() => {
                                                            handleDelete(row);
                                                        }}
                                                    >
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </BaseTablePaginator>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
            {categoryTypeData?.data && (
                <>
                    <CreateWorkCategoryDialog
                        open={openDialogCreateWorkCategory}
                        handleClose={() => {
                            handleCreateDialogClose();
                            // initialData();
                        }}
                        workCategoryTypes={categoryTypeData?.data ?? []}
                    />
                    {selectedWorkCategory && (
                        <>
                            <ReplaceWorkCategoryDialog
                                open={openDialogSwitchWorkCategory}
                                handleClose={() => {
                                    handleSwitchDialogClose();
                                    // initialData();
                                }}
                                workCategory={selectedWorkCategory!}
                            />
                            <EditWorkCategoryDialog
                                open={openDialogEditWorkCategory}
                                handleClose={() => {
                                    handleEditDialogClose();
                                    // initialData();
                                }}
                                WorkCategory={selectedWorkCategory}
                                workCategoryTypes={categoryTypeData?.data ?? []}
                            />
                        </>
                    )}
                </>
            )}
        </Layout>
    );
};

export default AdminWorkCategoryPage;
