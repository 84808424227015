import { SyntheticEvent, useEffect, useState } from 'react';
import { Grid, Box, Card, CardContent, Avatar, Tab, Tabs, tabsClasses } from '@mui/material';
import { Layout } from 'components/layout';
import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoff';
import InfoIcon from '@mui/icons-material/Info';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CheckIcon from '@mui/icons-material/Check';

import { useGetDashboardQuery } from 'lib/api/dashboardApi';
import { useGetProfileQuery } from 'lib/api/profileApi';
import RoleEnum from 'enums/roleEnum';
import { useTheme } from '@mui/material/styles';
import { IconMenu2 } from '@tabler/icons';
import TableLoader from 'components/loader/TableLoader';
import UserProfile from './userProfile';
import { decodeHashId } from 'lib/helpers/hasher';
import config from 'config';
// import Cookies from 'universal-cookie';
import DataPersonal from '../components/dataPersonal';
import DataPekerjaan from '../components/dataPekerjaan';
import DataGaji from '../components/dataGaji';
import DataKeluarga from '../components/dataKeluarga';

const MyProfilePage = () => {
    const theme = useTheme();
    // const cookies = new Cookies();
    const userData = JSON.parse(decodeHashId(localStorage.getItem(config.cookieUser)));
    const [tabValue, setTabValue] = useState(0);

    const handleChange = (event: SyntheticEvent, newValue: number) => {
        setTabValue(newValue);
    };

    useEffect(() => {
        console.log('userData', userData);
    }, []);

    return (
        <Layout headerTitle="My Profile" pageTitle="My Profile">
            <Grid container direction="column" spacing={20}>
                <Grid item container spacing={20}>
                    <Grid item xs={12} md={5} lg={5}>
                        <Card>
                            <CardContent>
                                <UserProfile userData={userData} />
                                <DataPersonal userData={userData} />
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={7} lg={7}>
                        <Card sx={{ p: 5 }}>
                            <Tabs
                                value={tabValue}
                                onChange={handleChange}
                                variant="scrollable"
                                scrollButtons
                                aria-label="visible arrows tabs example"
                                sx={{
                                    [`& .${tabsClasses.scrollButtons}`]: {
                                        '&.Mui-disabled': { opacity: 0.3 }
                                    }
                                }}
                            >
                                <Tab label="Data Pekerjaan" />
                                <Tab label="Data Gaji" />
                                <Tab label="Data Keluarga" />
                            </Tabs>
                            {tabValue === 0 && <DataPekerjaan userData={userData} />}
                            {tabValue === 1 && <DataGaji userData={userData} />}
                            {tabValue === 2 && <DataKeluarga userData={userData} />}
                        </Card>
                    </Grid>
                </Grid>
            </Grid>
        </Layout>
    );
};

export default MyProfilePage;
