import { Box, FormControl, FormHelperText, FormLabel, InputLabel, InputProps, TextField, Typography } from '@mui/material';
import { FormInputProps } from 'interfaces/input';
import { ReactNode } from 'react';
import { useController } from 'react-hook-form';

interface FormSelectProps extends FormInputProps {
    type?: string;
    isShrink?: boolean;
    inputProps?: Partial<InputProps>;
    multiline?: boolean;
    minRows?: any;
    endAdornment?: ReactNode;
    startAdornment?: ReactNode;
    children?: ReactNode;
    select?: boolean;
    value?: any;
    defaultValue?: any;
    maxRows?: number;
    required?: boolean;
    onChange?: (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
}

export const FormInput = ({
    name,
    control,
    placeHolder,
    label,
    isShrink = false,
    type = 'text',
    inputProps,
    multiline,
    minRows,
    endAdornment,
    startAdornment,
    disabled,
    children,
    select = false,
    value = '', // Set a default value or use a non-null value
    defaultValue,
    onChange,
    maxRows,
    required
}: FormSelectProps) => {
    const { field, fieldState } = useController({ name, control });
    const { error } = fieldState;

    return (
        <FormControl fullWidth>
            <Typography variant="caption" sx={{ paddingTop: '0', marginTop: '0' }}>
                {label}
                {required && <span style={{ color: 'red' }}> *</span>}
            </Typography>
            <TextField
                select={select}
                InputProps={{ startAdornment: startAdornment, endAdornment: endAdornment }}
                {...(minRows && { minRows: minRows })}
                {...(multiline && { multiline: multiline })}
                type={type}
                // label={label}
                disabled={disabled}
                {...(maxRows && { maxRows: maxRows })}
                {...(placeHolder && { placeholder: placeHolder })}
                error={Boolean(error)}
                {...(isShrink && { InputLabelProps: { shrink: true } })}
                {...field}
                {...(inputProps && { InputProps: inputProps })}
                children={children}
                {...(value && { value: value })}
                {...(defaultValue && { defaultValue: defaultValue })}
                {...(onChange && { onChange: onChange })}
            />
            {Boolean(error) && <FormHelperText sx={{ color: 'red', mx: 0 }}>{error?.message}</FormHelperText>}
        </FormControl>
    );
};
