import {
    Button,
    Card,
    CardContent,
    Dialog,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    ImageList,
    ImageListItem,
    TableCell,
    TableRow
} from '@mui/material';
import { Layout } from 'components/layout';
import BaseTablePaginator from 'components/table/BaseTablePaginator';
import { dateToStringWIB } from 'lib/helpers/dateHelper';
import CardFilter from 'modules/admin/employee/components/CardFilter';
import FilterSelectContent from 'modules/admin/employee/components/FilterSelectContent';
import React, { useEffect, useState } from 'react';
import { useGetAttendanceQuery } from 'lib/api/attendanceApi';
import { PaginateRequest } from 'interfaces/api-request';
import { Order } from 'interfaces/table';
import AttachFileIcon from '@mui/icons-material/AttachFile';

const AttendanceHistoryPage = () => {
    const [orderBy, setOrderBy] = useState<string>();
    const [order, setOrder] = useState<Order>('asc');
    const [paginateRequest, setPaginateRequest] = useState<PaginateRequest & { title?: string; role?: string; status?: string }>({
        current_page: 0,
        per_page: 10,
        order: order
    });

    const { isLoading, isFetching, error, data } = useGetAttendanceQuery({
        ...paginateRequest,
        current_page: +paginateRequest.current_page! + 1
    });

    const [selectedListUrlImage, setselectedListUrlImage] = useState<string[]>([]);
    const [dialogImageOpen, setDialogImageOpen] = useState(false);
    const handleClickOpenDialogImage = (data: string[]) => {
        setselectedListUrlImage(data);
        setDialogImageOpen(true);
    };
    const handleCloseDialogImage = () => {
        setDialogImageOpen(false);
    };

    useEffect(() => {
        setPaginateRequest({ ...paginateRequest, order: order });
    }, [order]);

    useEffect(() => {
        setPaginateRequest({ ...paginateRequest, order_by: orderBy });
    }, [orderBy]);

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setPaginateRequest({ ...paginateRequest, current_page: newPage });
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setPaginateRequest({ ...paginateRequest, current_page: 0, per_page: parseInt(event.target.value, 10) });
    };

    return (
        <Layout
            headerTitle="Attendance History"
            pageTitle="Attendance History"
            breadcrumbMiddleItems={[{ name: 'Attendance', url: '/attendance' }]}
        >
            <Grid container direction={'column'} spacing={20}>
                {/* <Grid item>
                    <CardFilter isLoading={true}>
                        <Grid container spacing={20}>
                            <Grid item xs={12} md={4}>
                                <FilterSelectContent
                                    label="Select Title"
                                    onSelectAll={() => {
                                        setselectedTitle(titleData?.data ?? []);
                                    }}
                                    isMulti
                                    closeMenuOnSelect={false}
                                    getOptionLabel={(e) => e.name ?? ''}
                                    getOptionValue={(e) => e.id ?? ''}
                                    options={titleData?.data}
                                    value={selectedTitle}
                                    menuPortalTarget={document.querySelector('body')}
                                    onChange={(newValue, actionMeta) => {
                                        setselectedTitle(newValue);
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <FilterSelectContent
                                    label="Select Role"
                                    onSelectAll={() => {
                                        setselectedRole(roleData?.data ?? []);
                                    }}
                                    isMulti
                                    closeMenuOnSelect={false}
                                    getOptionLabel={(e) => e.name ?? ''}
                                    getOptionValue={(e) => e.id.toString() ?? ''}
                                    options={roleData?.data}
                                    value={selectedRole}
                                    menuPortalTarget={document.querySelector('body')}
                                    onChange={(newValue, actionMeta) => {
                                        setselectedRole(newValue);
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <FilterSelectContent
                                    label="Select Employment Status"
                                    onSelectAll={() => {
                                        setselectedEmploymentStatus(employmentStatusData);
                                    }}
                                    isMulti
                                    closeMenuOnSelect={false}
                                    getOptionLabel={(e) => e.name ?? ''}
                                    getOptionValue={(e) => e.value ?? ''}
                                    options={employmentStatusData}
                                    value={selectedEmploymentStatus}
                                    menuPortalTarget={document.querySelector('body')}
                                    onChange={(newValue, actionMeta) => {
                                        setselectedEmploymentStatus(newValue);
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} textAlign={'right'}>
                                <Button
                                    variant="contained"
                                    onClick={() => {
                                        setPaginateRequest({
                                            ...paginateRequest,
                                            title: selectedTitle.map((val) => val.name).join(','),
                                            status: selectedEmploymentStatus.map((val) => val.value).join(','),
                                            role: selectedRole.map((e) => e.name).join(',')
                                        });
                                    }}
                                >
                                    Apply
                                </Button>
                            </Grid>
                        </Grid>
                    </CardFilter>
                </Grid> */}
                <Grid item>
                    <Card>
                        <CardContent>
                            <Grid container direction="column" spacing={10}>
                                <Grid item>
                                    <BaseTablePaginator
                                        isLoading={isLoading || isFetching}
                                        count={data?.meta.total ?? 0}
                                        rowsPerPage={+paginateRequest.per_page!}
                                        page={+paginateRequest.current_page!}
                                        onPageChange={handleChangePage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                        headCells={[
                                            { id: 'date_time_check_in', label: 'Clock In', isSortable: true },
                                            { id: 'date_time_check_out', label: 'Clock Out', isSortable: true },
                                            { id: 'cost_center', label: 'Cost Center', isSortable: true },
                                            { id: 'lokasi', label: 'Lokasi', isSortable: true },
                                            { id: 'pekerjaan', label: 'Pekerjaan', isSortable: true },
                                            { id: 'work_category', label: 'Kategori Pekerjaan', isSortable: true }
                                        ]}
                                        onSearchchange={(v) => {
                                            setPaginateRequest({ ...paginateRequest, q: v, current_page: 0 });
                                        }}
                                        orderBy={orderBy}
                                        order={order}
                                        onRequestSort={(newOrderBy, newOrder) => {
                                            setOrderBy(newOrderBy);
                                            setOrder(newOrder);
                                            setPaginateRequest({ ...paginateRequest, current_page: 0 });
                                        }}
                                    >
                                        {data?.data?.map((row) => (
                                            <TableRow
                                                key={row.id + row.date_time_check_in}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                <TableCell>
                                                    {dateToStringWIB({ date: row.date_time_check_in })}
                                                    <IconButton size="small" onClick={() => handleClickOpenDialogImage(row.urlCI ?? [])}>
                                                        <AttachFileIcon color="primary" />
                                                    </IconButton>
                                                </TableCell>
                                                <TableCell>
                                                    {dateToStringWIB({ date: row.date_time_check_out })}
                                                    {(row.urlCO?.length ?? 0) >= 1 && (
                                                        <IconButton
                                                            size="small"
                                                            onClick={() => handleClickOpenDialogImage(row.urlCO ?? [])}
                                                        >
                                                            <AttachFileIcon color="primary" />
                                                        </IconButton>
                                                    )}
                                                </TableCell>
                                                <TableCell>{row.cost_center?.name}</TableCell>
                                                <TableCell>{row.lokasi}</TableCell>
                                                <TableCell>{row.pekerjaan ?? '-'}</TableCell>
                                                <TableCell>{row.work_category_name ?? '-'}</TableCell>
                                            </TableRow>
                                        ))}
                                    </BaseTablePaginator>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
            <Dialog scroll="body" fullWidth={true} maxWidth={'xl'} open={dialogImageOpen} onClose={handleCloseDialogImage}>
                <DialogTitle>List Image</DialogTitle>
                <DialogContent sx={{ justify: 'center', alignItems: 'center', textAlign: 'center' }}>
                    {/* <DialogContentText>
                        You can set my maximum width and whether to adapt or not.aw da wdaw daw daw daw daw daw daw da wd
                    </DialogContentText> */}
                    <ImageList
                        sx={{ alignItems: 'center', justify: 'center' }}
                        cols={selectedListUrlImage.length <= 2 ? 2 : 3}
                        // cols={3}
                        rowHeight={'auto'}
                    >
                        {selectedListUrlImage.map((item) => (
                            <ImageListItem key={item} sx={{ border: 1, borderColor: 'primary.main' }}>
                                <img
                                    src={`${item}`}
                                    srcSet={`${item}`}
                                    alt={item}
                                    // style={{ maxHeight: '200px', maxWidth: '100%' }}
                                    loading="lazy"
                                />
                            </ImageListItem>
                        ))}
                    </ImageList>
                </DialogContent>
            </Dialog>
        </Layout>
    );
};

export default AttendanceHistoryPage;
