import { lazy } from 'react';

// project imports
import Loadable from 'ui-component/Loadable';
import AttendancePage from 'modules/attendance/pages/AttendancePage';
import MyProfilePage from 'modules/my-profile/pages/myProfilePage';
import LoginPage from 'modules/auth/pages/LoginPage';
import EmployeeMainLayout from 'layout/EmployeeMainLayout';
import AttendanceHistoryPage from 'modules/attendance/pages/AttendanceHistoryPage';
import LeavePage from 'modules/leave/pages/LeavePage';
import CreateLeavePage from 'modules/leave/pages/CreateLeavePage';
import LeaveApprovalPage from 'modules/leave-approval/pages/LeaveApprovalPage';
import ChangePasswordPage from 'modules/change-password/pages/ChangePasswordPage';

// login option 3 routing
const DashboardPage = Loadable(lazy(() => import('modules/dashboard/pages/DashboardPage')));
const CompanyAssetPage = Loadable(lazy(() => import('modules/company-asset/pages/CompanyAssetPage')));
const LatePage = Loadable(lazy(() => import('modules/late/pages/LatePage')));
const CreateLatePage = Loadable(lazy(() => import('modules/late/pages/CreateLatePage')));
const DivisionPage = Loadable(lazy(() => import('modules/division/pages/DivisionPage')));
const BankPage = Loadable(lazy(() => import('modules/bank/pages/BankPage')));
const TitlePage = Loadable(lazy(() => import('modules/title/pages/TitlePage')));
// ==============================|| AUTHENTICATION ROUTING ||============================== //

const EmployeeRoutes = {
    path: '',
    element: <EmployeeMainLayout />,
    children: [
        {
            path: '',
            element: <DashboardPage />
        },
        {
            path: 'my-profile',
            children: [
                {
                    path: '',
                    element: <MyProfilePage />
                }
            ]
        },
        {
            path: 'attendance',
            children: [
                {
                    path: '',
                    element: <AttendancePage />
                },
                {
                    path: 'history',
                    element: <AttendanceHistoryPage />
                }
            ]
        },
        {
            path: 'leave',
            children: [
                {
                    path: '',
                    element: <LeavePage />
                },
                {
                    path: 'request',
                    element: <CreateLeavePage />
                }
            ]
        },
        {
            path: 'leave/approval',
            children: [
                {
                    path: '',
                    element: <LeaveApprovalPage />
                }
            ]
        },
        {
            path: 'change_password',
            children: [
                {
                    path: '',
                    element: <ChangePasswordPage />
                }
            ]
        }
    ]
};

export default EmployeeRoutes;
