import { Box } from '@mui/material';
import React from 'react';
import { Dna } from 'react-loader-spinner';

type Props = {};

const ButtonLoader = (props: Props) => {
    return (
        <Box sx={{ display: 'flex', width: '100%', height: '100%', justifyContent: 'center', alignItems: 'center' }}>
            <Dna visible={true} height="80" width="80" ariaLabel="dna-loading" wrapperStyle={{}} wrapperClass="dna-wrapper" />;
        </Box>
    );
};

export default ButtonLoader;
