import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Card, CardContent, Checkbox, CircularProgress, Divider, FormControlLabel, Grid, MenuItem, Tab, Tabs } from '@mui/material';
import { FormInput } from 'components/input/hook-form';
import { Layout } from 'components/layout';
import config from 'config';
import {
    EmployeeRequestBody,
    useCreateEmployeeMutation,
    useGetEmployeeByIdQuery,
    useUpdateEmployeeMutation
} from 'lib/api/admin/adminEmployeeApi';
import { useGetTitlesQuery } from 'lib/api/admin/adminTitleApi';
import { getMinOffsetFromJakartaToLocale, getMinOffsetFromUTCToLocale } from 'lib/helpers/dateHelper';
import { decodeHashId } from 'lib/helpers/hasher';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import Cookies from 'universal-cookie';
import * as yup from 'yup';
import SelectData from '../components/selectData';
import SelectEmployee from '../components/SelectEmployee';
import { convertEmptyStringToUndefined, nullsToUndefined } from 'lib/helpers/formHelper';
import { TabPanel, a11yProps } from 'components/tabs/BaseTab';
import PersonalDataTab from '../components/personalDataTab';
import EmploymentEditDataTab from '../components/employmentEditDataTab';
import PayrollDataTab from '../components/payrollDataTab';
import KeluargaDataTab from '../components/keluargaDataTab';
import { useGetCreateEmploymentDataQuery } from 'lib/api/admin/adminSettingApi';
import { Employee } from 'interfaces/employee';

const cookies = new Cookies();

const defaultValueDateSelect = (date?: Date) => {
    if (date == undefined) {
        return undefined;
    }
    const dateNow = moment(date).add(getMinOffsetFromUTCToLocale(), 'minutes').toDate(); // Creating a new date object with the current date and time
    const year = dateNow.getFullYear(); // Getting current year from the created Date object
    const monthWithOffset = dateNow.getUTCMonth() + 1; // January is 0 by default in JS. Offsetting +1 to fix date for calendar.
    const month = // Setting current Month number from current Date object
        monthWithOffset.toString().length < 2 // Checking if month is < 10 and pre-prending 0 to adjust for date input.
            ? `0${monthWithOffset}`
            : monthWithOffset;
    const strDate =
        dateNow.getUTCDate().toString().length < 2 // Checking if date is < 10 and pre-prending 0 if not to adjust for date input.
            ? `0${dateNow.getUTCDate()}`
            : dateNow.getUTCDate();

    return `${year}-${month}-${strDate}`; // combining to format for defaultValue or value attribute of material <TextField>
};

const AdminEditEmployeePageTab = ({ dataEmployee }: { dataEmployee?: Employee }) => {
    //Tab Needs
    const [selectedTab, setSelectedTab] = useState(0);
    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setSelectedTab(+newValue);
    };
    const { isLoading: isLoadingData, data } = useGetCreateEmploymentDataQuery();
    const navigate = useNavigate();
    const [updateEmployee] = useUpdateEmployeeMutation();

    const [isLoading, setIsLoading] = useState(false);

    const initialFormData: EmployeeRequestBody = {
        first_name: (dataEmployee?.first_name as string) ?? '',
        last_name: (dataEmployee?.last_name as string) ?? '',
        email: (dataEmployee?.email as string) ?? '',
        ktp_number: (dataEmployee?.ktp_number as string) ?? '',
        status: dataEmployee?.status as string,
        join_date: defaultValueDateSelect(dataEmployee?.join_date) ?? '',
        gender: (dataEmployee?.gender as string) ?? '',
        address: (dataEmployee?.address as string) ?? '',
        address_now: (dataEmployee?.address_now as string) ?? '',
        awards: (dataEmployee?.awards as string) ?? '',
        bank_account_name: dataEmployee?.bank_account_name ?? '',
        bank_account_number: dataEmployee?.bank_account_number ?? '',
        bank_id: dataEmployee?.bank_id ?? '',
        birth_date: defaultValueDateSelect(dataEmployee?.birth_date) ?? '',
        birth_place: dataEmployee?.birth_place ?? '',
        blood_type: dataEmployee?.blood_type ?? '',
        bpjs_ks_cost: dataEmployee?.bpjs_ks_cost ?? '',
        bpjs_ks_date: defaultValueDateSelect(dataEmployee?.bpjs_ks_date) ?? '',
        bpjs_ks_family_id: dataEmployee?.bpjs_ks_family_id ?? '',
        bpjs_ks_number: dataEmployee?.bpjs_ks_number ?? '',
        bpjs_ktn_date: defaultValueDateSelect(dataEmployee?.bpjs_ktn_date) ?? '',
        bpjs_ktn_number: dataEmployee?.bpjs_ktn_number ?? '',
        citizen: dataEmployee?.citizen ?? '',
        dicipline: dataEmployee?.dicipline ?? '',
        direct_spv_id: dataEmployee?.direct_spv_id ?? NaN,
        department_id: dataEmployee?.department_id ?? '',
        end_date: defaultValueDateSelect(dataEmployee?.end_date) ?? '',
        marital_status: dataEmployee?.marital_status ?? '',
        npwp: dataEmployee?.npwp ?? '',
        npwp_address: dataEmployee?.npwp_address ?? '',
        permanent_date: defaultValueDateSelect(dataEmployee?.permanent_date) ?? '',
        phone_number: dataEmployee?.phone_number ?? '',
        phone_number_address_now: dataEmployee?.phone_number_address_now ?? '',
        photo: dataEmployee?.photo ?? '',
        privilege_id: dataEmployee?.privilege_id ?? '',
        ptkp: dataEmployee?.ptkp ?? '',
        relative_name: dataEmployee?.relative_name ?? '',
        relative_phone_number: dataEmployee?.relative_phone_number ?? '',
        religion: dataEmployee?.religion ?? '',
        retired_date: defaultValueDateSelect(dataEmployee?.retired_date) ?? '',
        retired_type: dataEmployee?.retired_type ?? '',
        salary: dataEmployee?.salary ?? NaN,
        salary_type: dataEmployee?.salary_type ?? '',
        // spv_id: dataEmployee?.spv_id ?? NaN,
        spv_id: dataEmployee?.spv_id ?? NaN,
        tax_date: defaultValueDateSelect(dataEmployee?.tax_date) ?? '',
        tax_method: dataEmployee?.tax_method ?? '',
        tax_salary: dataEmployee?.tax_salary ?? '',
        tax_status: dataEmployee?.tax_status ?? '',
        terminate_date: defaultValueDateSelect(dataEmployee?.terminate_date) ?? '',
        terminate_reason: dataEmployee?.terminate_reason ?? '',
        leave_balance: dataEmployee?.leave_balance ?? NaN,
        title_id: dataEmployee?.title_id ?? '',
        company_id: +decodeHashId(cookies.get(config.cookieCompanyId)) ?? '',
        leave_flow_id: dataEmployee?.leave_flow_id ?? '',
        is_approver: dataEmployee?.is_approver ?? false,
        darurat_hubungan: (dataEmployee?.employee_detail?.darurat_hubungan as string) ?? '',
        darurat_name: (dataEmployee?.employee_detail?.darurat_name as string) ?? '',
        darurat_address: (dataEmployee?.employee_detail?.darurat_address as string) ?? '',
        darurat_phone_number: (dataEmployee?.employee_detail?.darurat_phone_number as string) ?? '',
        tanggungan:
            dataEmployee?.tanggungan && dataEmployee?.tanggungan?.length > 0
                ? dataEmployee.tanggungan.map((e) => {
                      return {
                          tanggungan: e.tanggungan,
                          name: e.name,
                          birth_place: e.birth_place,
                          birth_date: defaultValueDateSelect(e.birth_date as unknown as Date),
                          last_education: e.last_education
                      };
                  })
                : [
                      {
                          tanggungan: '',
                          name: '',
                          birth_place: '',
                          birth_date: '',
                          last_education: ''
                      }
                  ]
    };

    const {
        handleSubmit,
        control,
        reset,
        formState: { errors }
    } = useForm({
        defaultValues: initialFormData,
        mode: 'onSubmit',
        resolver: yupResolver(
            yup
                .object({
                    first_name: yup
                        .string()
                        .matches(/^[A-Za-z ]*$/, 'Please enter valid name')
                        .required('*first_name is required'),
                    last_name: yup
                        .string()
                        .matches(/^[A-Za-z ]*$/, 'Please enter valid name')
                        .required('*last_name is required'),
                    email: yup.string().email('Please enter valid email').required('*email is required'),
                    // password: yup.string().min(8, 'password is too short - should be 8 chars minimum.'),
                    ktp_number: yup.string().matches(/^\d+$/, 'Only numbers').required('*ktp_number is required'),
                    status: yup.string().required('*status is required'),
                    join_date: yup.date().required('*join_date is required'),
                    gender: yup.string().required('*gender is required')
                })
                .required()
        )
    });

    const onSubmit = async (data: EmployeeRequestBody) => {
        setIsLoading(true);

        data = nullsToUndefined(data);

        await updateEmployee({ data: data, id: dataEmployee?.id ?? '-1' })
            .unwrap()
            .then((e) => {
                setIsLoading(false);
                withReactContent(Swal)
                    .fire('Sukses', 'Update Employee Berhasil!', 'success')
                    .then(() => {
                        navigate(-1);
                        reset(initialFormData);
                    });
            })
            .catch((error) => {});
        reset.apply(initialFormData);
        setIsLoading(false);
    };

    return (
        <Card>
            <form onSubmit={handleSubmit(onSubmit)}>
                <CardContent>
                    <Grid container justifyContent="center">
                        {isLoading || isLoadingData ? (
                            <CircularProgress />
                        ) : (
                            <Grid
                                item
                                container
                                spacing={20}
                                xs={12}
                                justifyContent="center"
                                sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex' }}
                            >
                                <Grid item xs={1.5}>
                                    <Tabs
                                        orientation="vertical"
                                        variant="scrollable"
                                        value={selectedTab}
                                        onChange={handleChange}
                                        aria-label="Vertical tabs example"
                                        sx={{ borderRight: 1, borderColor: 'divider' }}
                                    >
                                        <Tab label="Personal Data" {...a11yProps(0)} />
                                        <Tab label="Employment Data" {...a11yProps(1)} />
                                        <Tab label="Payroll Data" {...a11yProps(2)} />
                                        <Tab label="Data Keluarga" {...a11yProps(3)} />
                                    </Tabs>
                                </Grid>
                                {selectedTab == 0 && (
                                    <Grid item xs={10.5}>
                                        <TabPanel value={selectedTab} index={0}>
                                            <PersonalDataTab control={control} />
                                        </TabPanel>
                                    </Grid>
                                )}
                                {selectedTab == 1 && (
                                    <Grid item xs={10.5}>
                                        <TabPanel value={selectedTab} index={1}>
                                            <EmploymentEditDataTab control={control} data={data?.data} dataEmployee={dataEmployee} />
                                        </TabPanel>
                                    </Grid>
                                )}
                                {selectedTab == 2 && (
                                    <Grid item xs={10.5}>
                                        <TabPanel value={selectedTab} index={2}>
                                            <PayrollDataTab control={control} data={data?.data} />
                                        </TabPanel>
                                    </Grid>
                                )}
                                {selectedTab == 3 && (
                                    <Grid item xs={10.5}>
                                        <TabPanel value={selectedTab} index={3}>
                                            <KeluargaDataTab control={control} />
                                        </TabPanel>
                                    </Grid>
                                )}
                                <Grid item xs={12} sx={{ textAlign: 'right' }}>
                                    {selectedTab < 2 && (
                                        <Button
                                            size="large"
                                            onClick={(e) => {
                                                setSelectedTab(selectedTab + 1);
                                            }}
                                        >
                                            Next
                                        </Button>
                                    )}

                                    <Button
                                        size="large"
                                        type="submit"
                                        onClick={() => {
                                            console.log(errors);
                                        }}
                                    >
                                        Update
                                    </Button>
                                </Grid>
                            </Grid>
                        )}
                    </Grid>
                </CardContent>
            </form>
        </Card>
    );
};

export default AdminEditEmployeePageTab;
