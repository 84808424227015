import { Grid } from '@mui/material';
import { FormInput, SelectInput } from 'components/input/hook-form';
import { useController, Control, useFormState } from 'react-hook-form';
import { listGender, listMaritalStatus, listBloodType, listReligion, listCitizenType } from 'lib/helpers/dataSelect';

const PersonalDataTab = ({ control }: { control: Control<any> }) => {
    return (
        <Grid container spacing={10} alignItems="flex-end" justifyContent="space-between">
            <Grid item xs={12} md={6} lg={4}>
                <FormInput required control={control} label="First Name" type="text" name="first_name" />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
                <FormInput control={control} label="Last Name" type="text" name="last_name" />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
                <FormInput required control={control} label="Email" type="email" name="email" />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
                <FormInput required control={control} label="KTP Number" type="number" name="ktp_number" />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
                <FormInput required control={control} label="Phone Number" type="number" name="phone_number" />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
                <FormInput control={control} label="Telephone Number" type="number" name="phone_number_address_now" />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
                <FormInput control={control} label="Address" type="text" name="address" />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
                <FormInput control={control} label="Address Now" type="text" name="address_now" />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
                <FormInput control={control} label="Birth Place" type="text" name="birth_place" />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
                <FormInput isShrink control={control} label="Birth Date" type="date" name="birth_date" />
            </Grid>
            <Grid item xs={12} md={6} lg={4} paddingTop={5} marginTop={0}>
                <SelectInput required name="gender" control={control} label="Gender" options={listGender} />
            </Grid>
            <Grid item xs={12} md={6} lg={4} paddingTop={5} marginTop={0}>
                <SelectInput name="marital_status" control={control} label="Marital Status" options={listMaritalStatus} />
            </Grid>
            <Grid item xs={12} md={6} lg={4} paddingTop={0} marginTop={0}>
                <SelectInput name="blood_type" control={control} label="Blood Type" options={listBloodType} />
            </Grid>
            <Grid item xs={12} md={6} lg={4} paddingTop={0} marginTop={0}>
                <SelectInput name="religion" control={control} label="Religion" options={listReligion} />
            </Grid>
            <Grid item xs={12} md={6} lg={4} paddingTop={0} marginTop={0}>
                <SelectInput name="citizen" control={control} label="Citizen" options={listCitizenType} />
            </Grid>
        </Grid>
    );
};

export default PersonalDataTab;
