import { Button, Card, CardContent, TableRow, TableCell, IconButton, Grid } from '@mui/material';
import { Layout } from 'components/layout';
import BaseTablePaginator from 'components/table/BaseTablePaginator';
import { PaginateRequest } from 'interfaces/api-request';
import { Order } from 'interfaces/table';
import { useDeleteCostCenterMutation, useGetCostCentersPaginateQuery } from 'lib/api/admin/adminCostCenterApi';
import CardFilter from 'modules/admin/employee/components/CardFilter';
import FilterSelectContent from 'modules/admin/employee/components/FilterSelectContent';
import React, { useEffect, useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ChangeCircleOutlinedIcon from '@mui/icons-material/ChangeCircleOutlined';
import { CostCenter } from 'interfaces/cost-center';
import CreateCostCenterDialog from '../components/CreateCostCenterDialog';
import EditCostCenterDialog from '../components/EditCostCenterDialog';
import { useGetDepartmentsQuery } from 'lib/api/admin/adminDepartmentApi';
import { useGetSubCompaniesQuery } from 'lib/api/admin/adminSubCompanyApi';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';

const AdminCostCenterPage = () => {
    const [order, setOrder] = useState<Order>('asc');
    const [orderBy, setOrderBy] = useState<string>();
    const { isLoading: departmentLoading, data: departmentData } = useGetDepartmentsQuery();
    const { isLoading: subCompanyLoading, data: subCompanyData } = useGetSubCompaniesQuery();
    useEffect(() => {
        setPaginateRequest({ ...paginateRequest, order: order });
    }, [order]);
    useEffect(() => {
        setPaginateRequest({ ...paginateRequest, order_by: orderBy });
    }, [orderBy]);
    const [paginateRequest, setPaginateRequest] = useState<PaginateRequest & { work_category_type?: string }>({
        current_page: 0,
        per_page: 10,
        order: order
    });
    const [openDialogCreateCostCenter, setOpenDialogCreateCostCenter] = useState(false);
    const [openDialogEditCostCenter, setOpenDialogEditCostCenter] = useState(false);
    const [selectedCostCenter, setSelectedCostCenter] = useState<CostCenter>();
    const { isLoading, error, data, isFetching } = useGetCostCentersPaginateQuery({
        ...paginateRequest,
        current_page: +paginateRequest.current_page! + 1
    });

    //delete data
    const [deleteCostCenter] = useDeleteCostCenterMutation();
    const handleDelete = (costCenter: CostCenter) => {
        const MySwal = withReactContent(Swal);
        MySwal.fire({
            title: 'Are you sure?',
            text: 'You will delete this item!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete item!'
        }).then((result) => {
            if (result.isConfirmed) {
                deleteCostCenter({ id: costCenter.id ?? '' })
                    .unwrap()
                    .then((res) => {
                        MySwal.fire('Sukses', 'Data berhasil dihapus!', 'success');
                    })
                    .catch((err) => {
                        console.error(err.message ? err.message : err.data.message);
                        MySwal.fire('Gagal', err.message ? err.message : err.data.message, 'error');
                    });
            }
        });
    };
    return (
        <Layout headerTitle="Cost Center" pageTitle="Cost Center" disableBreadcrumb>
            <Grid container direction={'column'} spacing={20}>
                <Grid item>
                    <CardFilter isLoading={false}>
                        <Grid container spacing={20}>
                            <Grid item xs={12}>
                                {/* <FilterSelectContent
                                    label="Select Work Category Type"
                                    isMulti
                                    closeMenuOnSelect={false}
                                    getOptionLabel={(e) => e.name ?? ''}
                                    getOptionValue={(e) => e.id.toString() ?? ''}
                                    options={categoryTypeData?.data}
                                    value={selectedCategoryType}
                                    menuPortalTarget={document.querySelector('body')}
                                    styles={{ ...styles }}
                                    onChange={(newValue, actionMeta) => {
                                        setSelectedCategoryType(newValue);
                                    }}
                                /> */}
                            </Grid>
                            <Grid item xs={12} textAlign={'right'}>
                                <Button
                                    variant="contained"
                                    onClick={() => {
                                        // setPaginateRequest({
                                        //     ...paginateRequest,
                                        //     work_category_type: selectedCategoryType.map((val) => val.name).join(',')
                                        // });
                                    }}
                                >
                                    Apply
                                </Button>
                            </Grid>
                        </Grid>
                    </CardFilter>
                </Grid>
                <Grid item>
                    <Card>
                        <CardContent>
                            <Grid container direction={'column'} spacing={20}>
                                <Grid item container justifyContent="flex-end" spacing={8}>
                                    <Grid item>
                                        <Button
                                            variant="outlined"
                                            onClick={(val) => {
                                                setOpenDialogCreateCostCenter(true);
                                            }}
                                        >
                                            Add Cost Center
                                        </Button>
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <BaseTablePaginator
                                        isLoading={isLoading || isFetching || false}
                                        count={data?.meta.total ?? 0}
                                        rowsPerPage={+paginateRequest.per_page!}
                                        page={+paginateRequest.current_page!}
                                        onPageChange={function (
                                            event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
                                            newPage: number
                                        ): void {
                                            setPaginateRequest({ ...paginateRequest, current_page: newPage });
                                        }}
                                        onRowsPerPageChange={function (
                                            event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
                                        ): void {
                                            setPaginateRequest({
                                                ...paginateRequest,
                                                current_page: 0,
                                                per_page: parseInt(event.target.value, 10)
                                            });
                                        }}
                                        onSearchchange={function (e: string): void {
                                            setPaginateRequest({ ...paginateRequest, q: e, current_page: 0 });
                                        }}
                                        orderBy={orderBy}
                                        order={order}
                                        onRequestSort={(newOrderBy, newOrder) => {
                                            setOrderBy(newOrderBy);
                                            setOrder(newOrder);
                                            setPaginateRequest({ ...paginateRequest, current_page: 0 });
                                        }}
                                        headCells={[
                                            { id: 'name', label: 'Name', isSortable: true },
                                            { id: 'description', label: 'Description', isSortable: true },
                                            { id: 'amount', label: 'Amount', isSortable: true },
                                            { id: 'department', label: 'Department', isSortable: true },
                                            { id: 'sub_company', label: 'Sub Company', isSortable: true },
                                            { id: 'action', label: 'Actions', isSortable: false }
                                        ]}
                                    >
                                        {data?.data?.map((row) => (
                                            <TableRow key={row.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                <TableCell component="th" scope="row">
                                                    {row.name}
                                                </TableCell>
                                                <TableCell>{row.description ?? '-'}</TableCell>
                                                <TableCell>{row.amount ?? '-'}</TableCell>
                                                <TableCell>{row.department?.name ?? '-'}</TableCell>
                                                <TableCell>{row.sub_company?.name ?? '-'}</TableCell>
                                                <TableCell>
                                                    <IconButton
                                                        color="primary"
                                                        onClick={() => {
                                                            setSelectedCostCenter(row);
                                                            setOpenDialogEditCostCenter(true);
                                                        }}
                                                    >
                                                        <EditIcon />
                                                    </IconButton>
                                                    <IconButton
                                                        color="error"
                                                        onClick={() => {
                                                            handleDelete(row);
                                                        }}
                                                    >
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </BaseTablePaginator>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
            {!isLoading && (
                <>
                    <CreateCostCenterDialog
                        open={openDialogCreateCostCenter}
                        handleClose={() => {
                            setOpenDialogCreateCostCenter(false);
                        }}
                    />
                    {selectedCostCenter && departmentData?.data && subCompanyData?.data && (
                        <EditCostCenterDialog
                            open={openDialogEditCostCenter}
                            handleClose={() => {
                                setOpenDialogEditCostCenter(false);
                            }}
                            costCenter={selectedCostCenter}
                            departments={departmentData!.data}
                            subCompanies={subCompanyData!.data}
                        />
                    )}
                </>
            )}
        </Layout>
    );
};

export default AdminCostCenterPage;
