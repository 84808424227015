// assets

import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined'; // constant
import WorkHistoryOutlinedIcon from '@mui/icons-material/WorkHistoryOutlined';
import WorkOutlineIcon from '@mui/icons-material/WorkOutline';
import AccountTreeOutlinedIcon from '@mui/icons-material/AccountTreeOutlined';
import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoff';
const icons = { PeopleAltOutlinedIcon, WorkHistoryOutlinedIcon, WorkOutlineIcon, FlightTakeoffIcon };
// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const employementFeatures = {
    id: 'adminFeatures',
    title: 'Employement',
    type: 'group',
    children: [
        {
            id: 'employees',
            title: 'Employee Information',
            type: 'item',
            url: '/admin/employees',
            icon: icons.PeopleAltOutlinedIcon,
            breadcrumbs: false
        },
        {
            id: 'employees-attendance',
            title: 'Attendance',
            type: 'item',
            url: '/admin/attendances',
            icon: icons.WorkHistoryOutlinedIcon,
            breadcrumbs: false
        },
        {
            id: 'leave',
            title: 'Leave Request',
            type: 'item',
            url: '/admin/leave',
            icon: icons.FlightTakeoffIcon,
            breadcrumbs: false
        }
    ]
};

export default employementFeatures;
