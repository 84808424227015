import { useTheme } from '@mui/material/styles';
export function leaveStatusToText(status?: string) {
    if (status == 'A') {
        return 'Approved';
    }
    if (status == 'R') {
        return 'Rejected';
    }

    if (status == 'P') {
        return 'Pending';
    }
    return '-';
}

export function leaveStatusToColor(status?: string) {
    if (status == 'A') {
        return 'green';
    }
    if (status == 'R') {
        return 'error';
    }

    if (status == 'P') {
        return 'darkOrange';
    }
    return 'grey';
}
