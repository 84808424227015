import { Dashboard } from 'interfaces/dashboard';
import { adminApi } from '../services/services';
import { ApiResponse } from 'interfaces/api-response';
import { getBaseHeaderApi } from '../services/apiHelper';

export const adminDashboardApi = adminApi.injectEndpoints({
    endpoints(builder) {
        return {
            getAdminDashboard: builder.query<ApiResponse<any>, void>({
                query: () => ({
                    url: '/admin/dashboard',
                    method: 'GET',
                    headers: getBaseHeaderApi()
                })
            }),
            getAdminDashboardCardClockIn: builder.query<ApiResponse<any>, void>({
                query: () => ({
                    url: '/admin/dashboardcard-clockin',
                    method: 'GET',
                    headers: getBaseHeaderApi()
                })
            })
        };
    }
});

export const { useGetAdminDashboardQuery, useGetAdminDashboardCardClockInQuery } = adminDashboardApi;
