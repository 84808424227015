import { Division } from 'interfaces/division';
import { Role } from 'interfaces/role';
import { adminApi } from '../services/services';
import { ApiResponse } from 'interfaces/api-response';
import { getBaseHeaderApi } from '../services/apiHelper';

export const adminRoleApi = adminApi.injectEndpoints({
    endpoints(builder) {
        return {
            getRoles: builder.query<ApiResponse<Role[]>, void>({
                query: () => ({
                    url: '/role',
                    method: 'GET',
                    headers: getBaseHeaderApi()
                })
            })
        };
    }
});

export const { useGetRolesQuery } = adminRoleApi;
