import { Card, CardContent, Button, Grid, IconButton, TableCell, TableRow, Typography } from '@mui/material';
import { Layout } from 'components/layout';
import BaseTablePaginator from 'components/table/BaseTablePaginator';
import { PaginateRequest } from 'interfaces/api-request';
import { Order } from 'interfaces/table';
import { useGetWorkCategoriesPaginateQuery } from 'lib/api/admin/adminWorkCategoryApi';
import { useDeleteLeaveMutation, useGetLeaveApprovalQuery, useGetLeaveQuery } from 'lib/api/leaveApi';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import ChangeCircleOutlinedIcon from '@mui/icons-material/ChangeCircleOutlined';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { Leave } from 'interfaces/leave';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import GiveFeedbackApprovalDialog from './GiveFeedbackApprovalDialog';
import { useGetProfileQuery } from 'lib/api/profileApi';
import { dateToStringWIB } from 'lib/helpers/dateHelper';
import { leaveStatusToColor, leaveStatusToText } from 'lib/helpers/leaveStatusHelper';
import { useDialog } from 'hooks/useDialog';
import SummarizeIcon from '@mui/icons-material/Summarize';
import DetailApprovalDialog from 'modules/leave/components/DetailApprovalDialog';

const MySwal = withReactContent(Swal);

const LeaveApprovalPage = () => {
    const navigate = useNavigate();

    const { isLoading: profileIsLoading, data: profileData, isFetching: profileIsFetching } = useGetProfileQuery();

    const [orderBy, setOrderBy] = useState<string>();
    const [order, setOrder] = useState<Order>('asc');
    const [paginateRequest, setPaginateRequest] = useState<PaginateRequest & { work_category_type?: string }>({
        current_page: 0,
        per_page: 10,
        order: order
    });
    useEffect(() => {
        setPaginateRequest({ ...paginateRequest, order: order });
    }, [order]);

    useEffect(() => {
        setPaginateRequest({ ...paginateRequest, order_by: orderBy });
    }, [orderBy]);
    const { isLoading, error, data, isFetching } = useGetLeaveApprovalQuery({
        ...paginateRequest,
        current_page: +paginateRequest.current_page! + 1
    });

    const [openDialogGiveFeedbackApproval, setopenDialogGiveFeedbackApproval] = useState(false);
    const handleGiveFeedbackDialogClose = () => {
        setopenDialogGiveFeedbackApproval(false);
    };

    const [selectedLeave, setSelectedLeave] = useState<Leave>();

    // Detail Leave Approval Needs
    const { open, handleClose, handleClickOpen } = useDialog();
    const [selectedApprover, setSelectedApprover] = useState<{ title: string; status: string; date: Date; note: string }>();

    const [selectedApproverNumber, setSelectedApproverNumber] = useState<number | undefined>();

    return (
        <Layout headerTitle="Leave Approval" pageTitle="Leave Approval" disableBreadcrumb>
            <Grid container direction={'column'} spacing={20}>
                <Grid item>
                    <Card>
                        <CardContent>
                            <Grid item container justifyContent="flex-end" spacing={8}>
                                {/* <Grid item>
                                    <Button
                                        variant="outlined"
                                        onClick={(val) => {
                                            navigate('/leave/request');
                                        }}
                                    >
                                        Request Leave
                                    </Button>
                                </Grid> */}
                                <Grid item>
                                    <BaseTablePaginator
                                        isLoading={isLoading || isFetching}
                                        count={data?.meta?.total ?? 0}
                                        rowsPerPage={+paginateRequest.per_page!}
                                        page={+paginateRequest.current_page!}
                                        onPageChange={function (
                                            event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
                                            newPage: number
                                        ): void {
                                            setPaginateRequest({ ...paginateRequest, current_page: newPage });
                                        }}
                                        onRowsPerPageChange={function (
                                            event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
                                        ): void {
                                            setPaginateRequest({
                                                ...paginateRequest,
                                                current_page: 0,
                                                per_page: parseInt(event.target.value, 10)
                                            });
                                        }}
                                        onSearchchange={function (e: string): void {
                                            setPaginateRequest({ ...paginateRequest, q: e, current_page: 0 });
                                        }}
                                        orderBy={orderBy}
                                        order={order}
                                        onRequestSort={(newOrderBy, newOrder) => {
                                            setOrderBy(newOrderBy);
                                            setOrder(newOrder);
                                            setPaginateRequest({ ...paginateRequest, current_page: 0 });
                                        }}
                                        headCells={[
                                            { id: 'name', label: 'Name', isSortable: true },
                                            { id: 'request_date', label: 'Requested Date', isSortable: true },
                                            { id: 'reason', label: 'Reason', isSortable: true },
                                            { id: 'from_date', label: 'Leave Date', isSortable: true },
                                            { id: 'approver_1', label: 'Approver 1', isSortable: true },
                                            { id: 'approver_2', label: 'Approver 2', isSortable: true },
                                            { id: 'approver_3', label: 'Approver 3', isSortable: true }
                                            // { id: 'work_category_type', label: 'Type', isSortable: true },
                                            // { id: 'action', label: 'Actions', isSortable: false }
                                        ]}
                                    >
                                        {data?.data?.map((row) => (
                                            <TableRow key={row.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                <TableCell component="th" scope="row">
                                                    {row.requester.first_name + ' ' + row.requester.last_name}
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                    {dateToStringWIB({ date: row.request_date, withTime: true })}
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                    {row.reason}
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                    <Typography>
                                                        {row.duration == 1
                                                            ? dateToStringWIB({
                                                                  date: row.from_date,
                                                                  customFormat: 'YYYY/MM/DD'
                                                              })
                                                            : `${dateToStringWIB({
                                                                  date: row.from_date,
                                                                  customFormat: 'YYYY/MM/DD'
                                                              })} - ${dateToStringWIB({
                                                                  date: row.until_date,
                                                                  customFormat: 'YYYY/MM/DD'
                                                              })}`}
                                                    </Typography>
                                                    <Typography>{`(${row.duration} ${row.duration == 1 ? 'day' : 'days'})`}</Typography>
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                    <Grid container direction={'row'} alignItems={'center'}>
                                                        <Grid
                                                            item
                                                            container
                                                            xs={row.approver_1_id == profileData?.data?.id ? 6 : 12}
                                                            direction={'row'}
                                                            alignItems={'center'}
                                                            alignContent={'center'}
                                                        >
                                                            <Grid item xs={12}>
                                                                <Typography color={leaveStatusToColor(row.approver_1_status)}>
                                                                    {leaveStatusToText(row.approver_1_status)}
                                                                    {row.approver_1_status != 'P' && (
                                                                        <IconButton
                                                                            color="primary"
                                                                            onClick={() => {
                                                                                setSelectedApprover({
                                                                                    title: `Approver 1 - ${row.approver1.first_name}`,
                                                                                    status: row.approver_1_status,
                                                                                    date: row.approver_1_updated,
                                                                                    note: row.approver_1_note
                                                                                });
                                                                                handleClickOpen();
                                                                            }}
                                                                        >
                                                                            <SummarizeIcon />
                                                                        </IconButton>
                                                                    )}
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                {row.approver1?.first_name} {row.approver1?.last_name}
                                                            </Grid>
                                                        </Grid>
                                                        {row.approver_1_id == profileData?.data?.id && (
                                                            <Grid xs={6}>
                                                                <Button
                                                                    color={
                                                                        row.approver_1_id == profileData?.data?.id &&
                                                                        row.approver_1_status != 'P'
                                                                            ? 'warning'
                                                                            : 'primary'
                                                                    }
                                                                    variant="contained"
                                                                    onClick={() => {
                                                                        setSelectedApproverNumber(1);
                                                                        setSelectedLeave(row);
                                                                        setopenDialogGiveFeedbackApproval(true);
                                                                    }}
                                                                >
                                                                    {row.approver_1_id == profileData?.data?.id &&
                                                                    row.approver_1_status != 'P'
                                                                        ? 'Change Feedback'
                                                                        : 'Give Feedback'}
                                                                </Button>
                                                            </Grid>
                                                        )}
                                                    </Grid>
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                    <Grid container direction={'row'} alignItems={'center'}>
                                                        <Grid
                                                            item
                                                            container
                                                            xs={row.approver_2_id == profileData?.data?.id ? 6 : 12}
                                                            direction={'row'}
                                                            alignItems={'center'}
                                                            alignContent={'center'}
                                                        >
                                                            <Grid item xs={12}>
                                                                <Typography color={leaveStatusToColor(row.approver_2_status)}>
                                                                    {leaveStatusToText(row.approver_2_status)}
                                                                    {row.approver_2_status != 'P' && (
                                                                        <IconButton
                                                                            color="primary"
                                                                            onClick={() => {
                                                                                setSelectedApprover({
                                                                                    title: `Approver 2 - ${row.approver2.first_name}`,
                                                                                    status: row.approver_2_status,
                                                                                    date: row.approver_2_updated,
                                                                                    note: row.approver_2_note
                                                                                });
                                                                                handleClickOpen();
                                                                            }}
                                                                        >
                                                                            <SummarizeIcon />
                                                                        </IconButton>
                                                                    )}
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                {row.approver2?.first_name} {row.approver2?.last_name}
                                                            </Grid>
                                                        </Grid>
                                                        {row.approver_2_id == profileData?.data?.id && (
                                                            <Grid xs={6}>
                                                                <Button
                                                                    color={
                                                                        row.approver_2_id == profileData?.data?.id &&
                                                                        row.approver_2_status != 'P'
                                                                            ? 'warning'
                                                                            : 'primary'
                                                                    }
                                                                    variant="contained"
                                                                    onClick={() => {
                                                                        setSelectedApproverNumber(2);
                                                                        setSelectedLeave(row);
                                                                        setopenDialogGiveFeedbackApproval(true);
                                                                    }}
                                                                >
                                                                    {row.approver_2_id == profileData?.data?.id &&
                                                                    row.approver_2_status != 'P'
                                                                        ? 'Change Feedback'
                                                                        : 'Give Feedback'}
                                                                </Button>
                                                            </Grid>
                                                        )}
                                                    </Grid>
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                    <Grid container direction={'row'} alignItems={'center'}>
                                                        <Grid
                                                            item
                                                            container
                                                            xs={row.approver_3_id == profileData?.data?.id ? 6 : 12}
                                                            direction={'row'}
                                                            alignItems={'center'}
                                                            alignContent={'center'}
                                                        >
                                                            <Grid item xs={12}>
                                                                <Typography color={leaveStatusToColor(row.approver_3_status)}>
                                                                    {leaveStatusToText(row.approver_3_status)}
                                                                    {row.approver_3_status != 'P' && (
                                                                        <IconButton
                                                                            color="primary"
                                                                            onClick={() => {
                                                                                setSelectedApprover({
                                                                                    title: `Approver 3 - ${row.approver3.first_name}`,
                                                                                    status: row.approver_3_status,
                                                                                    date: row.approver_3_updated,
                                                                                    note: row.approver_3_note
                                                                                });
                                                                                handleClickOpen();
                                                                            }}
                                                                        >
                                                                            <SummarizeIcon />
                                                                        </IconButton>
                                                                    )}
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                {row.approver3?.first_name} {row.approver3?.last_name}
                                                            </Grid>
                                                        </Grid>
                                                        {row.approver_3_id == profileData?.data?.id && (
                                                            <Grid xs={6}>
                                                                <Button
                                                                    color={
                                                                        row.approver_3_id == profileData?.data?.id &&
                                                                        row.approver_3_status != 'P'
                                                                            ? 'warning'
                                                                            : 'primary'
                                                                    }
                                                                    variant="contained"
                                                                    onClick={() => {
                                                                        setSelectedApproverNumber(3);
                                                                        setSelectedLeave(row);
                                                                        setopenDialogGiveFeedbackApproval(true);
                                                                    }}
                                                                >
                                                                    {row.approver_3_id == profileData?.data?.id &&
                                                                    row.approver_3_status != 'P'
                                                                        ? 'Change Feedback'
                                                                        : 'Give Feedback'}
                                                                </Button>
                                                            </Grid>
                                                        )}
                                                    </Grid>
                                                </TableCell>
                                                {/* <TableCell>{row.work_category_type?.name ?? '-'}</TableCell> */}
                                                {/* <TableCell>
                                                    <Button
                                                        color={
                                                            (row.approver_1_id == profileData?.data?.id && row.approver_1_status != 'P') ||
                                                            (row.approver_2_id == profileData?.data?.id && row.approver_2_status != 'P') ||
                                                            (row.approver_3_id == profileData?.data?.id && row.approver_3_status != 'P')
                                                                ? 'warning'
                                                                : 'primary'
                                                        }
                                                        variant="contained"
                                                        onClick={() => {
                                                            setSelectedLeave(row);
                                                            setopenDialogGiveFeedbackApproval(true);
                                                        }}
                                                    >
                                                        {(row.approver_1_id == profileData?.data?.id && row.approver_1_status != 'P') ||
                                                        (row.approver_2_id == profileData?.data?.id && row.approver_2_status != 'P') ||
                                                        (row.approver_3_id == profileData?.data?.id && row.approver_3_status != 'P')
                                                            ? 'Change Feedback'
                                                            : 'Give Feedback'}
                                                    </Button>
                                                </TableCell> */}
                                            </TableRow>
                                        ))}
                                    </BaseTablePaginator>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
            <DetailApprovalDialog
                open={open}
                handleClose={() => {
                    handleClose();
                }}
                approverData={selectedApprover}
            />
            {selectedLeave && selectedApproverNumber && (
                <>
                    <GiveFeedbackApprovalDialog
                        open={openDialogGiveFeedbackApproval}
                        handleClose={() => {
                            handleGiveFeedbackDialogClose();
                            // initialData();
                        }}
                        leave={selectedLeave}
                        approverNumber={selectedApproverNumber!}
                    />
                </>
            )}
        </Layout>
    );
};

export default LeaveApprovalPage;
