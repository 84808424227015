import dayjs from 'dayjs';
import moment from 'moment';

export function toDateOffset(date?: Date) {
    if (date == undefined) {
        return undefined;
    }
    // initialize
    let result = dayjs(date);

    // remove date offset
    result = result.subtract(dayjs().utcOffset(), 'minutes');

    // add current offset of today
    result = result.add(result.utcOffset(), 'minutes');

    return result.toDate();
}

/**
 * https://day.js.org/docs/en/manipulate/utc-offset
 * @param {Date} date
 */
export function toCurrentOffset(date?: Date) {
    if (date == undefined) {
        return undefined;
    }
    // initialize
    let result = dayjs(date);

    // remove date offset
    result = result.subtract(result.utcOffset(), 'minutes');

    // add current offset of today
    result = result.add(dayjs().utcOffset(), 'minutes');

    return result.toDate();
}

export function convertUTCToLocalDate(date?: Date) {
    if (!date) {
        return date;
    }
    date = new Date(date);
    date = new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate());
    return date;
}

export function convertLocalToUTCDate(date?: Date) {
    if (!date) {
        return date;
    }
    date = new Date(date);
    date = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
    return date;
}

export function getMinOffsetFromJakartaToLocale() {
    var now = moment();
    var localOffset = now.utcOffset();
    now.tz('Asia/Jakarta'); // your time zone, not necessarily the server's
    var centralOffset = now.utcOffset();
    return localOffset - centralOffset;
}

export function getMinOffsetFromUTCToLocale() {
    var now = moment();
    var localOffset = now.utcOffset();
    now.tz('UTC'); // your time zone, not necessarily the server's
    var centralOffset = now.utcOffset();
    return localOffset - centralOffset;
}

export function dateToString(date?: Date) {
    const result = moment(date);
    if (result.isValid()) {
        return result.format('YYYY/MM/DD kk:mm:ss');
    } else {
        return '-';
    }
}

interface IDateToStringProps {
    date: Date | undefined;
    withTime?: boolean;
    customFormat?: string;
}

export function dateToStringWIB({ date, withTime = false, customFormat }: IDateToStringProps) {
    const result = moment(date);
    console.log('with time', withTime);
    if (result.isValid()) {
        if (customFormat) {
            return result.tz('Asia/Jakarta').format(customFormat);
        }
        if (withTime) {
            return result.tz('Asia/Jakarta').format('YYYY/MM/DD HH:mm') + ' WIB';
        }
        return result.tz('Asia/Jakarta').format('YYYY/MM/DD');
    } else {
        return '-';
    }
}

export function dateToStringFullWIB({ date, withTime = false, customFormat }: IDateToStringProps) {
    const result = moment(date);
    console.log('with time', withTime);
    if (result.isValid()) {
        if (customFormat) {
            return result.tz('Asia/Jakarta').format(customFormat);
        }
        if (withTime) {
            return result.tz('Asia/Jakarta').format('YYYY/MM/DD HH:mm') + ' WIB';
        }
        return result.tz('Asia/Jakarta').format('DD MMM YYYY');
    } else {
        return '-';
    }
}
