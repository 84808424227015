import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Box, CircularProgress, TextField, FormControl } from '@mui/material';
import DynamicMultipleInputFileWithButton from './DynamicMultipleInputFile';
import * as yup from 'yup';
import { useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
// import { CheckOutRequestBody, apiCheckOut } from '../AttendanceService';
import { FormInput } from 'components/input/hook-form';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { Attendance } from 'interfaces/attendance';
import Cookies from 'universal-cookie';
import config from 'config';
import { decodeHashId } from 'lib/helpers/hasher';
import { WorkCategory } from 'interfaces/work-category';
import { CheckOutRequestBody, useCheckOutMutation } from 'lib/api/attendanceApi';
const cookies = new Cookies();

const initialFormData: CheckOutRequestBody = {
    longitude: '',
    latitude: '',
    photos: []
};

const schema = yup
    .object({
        // employeeId: yup.number().required('*Email is required'),
        // lokasi: yup.string().required('*Password is required'),
        // pekerjaan: yup.string().required('*Password is required')
        // longitudeCheckIn: yup.string().required('*Password is required'),
        // latitudeCheckIn: yup.string().required('*Password is required'),
        // companyId: yup.number().required('*Email is required')
    })
    .required();

interface IDialogCheckOutProps {
    open: boolean;
    handleClose: () => void;
    longitude: string;
    latitude: string;
    attendance?: Attendance;
}

const DialogCheckOut = ({ open, handleClose, latitude, longitude, attendance }: IDialogCheckOutProps) => {
    const [checkOut] = useCheckOutMutation();
    const [error, setError] = useState('');
    const [isLoading, setLoading] = useState(false);
    const [listFile, setListFile] = useState<any[]>([]);

    const { handleSubmit, control, reset } = useForm({
        defaultValues: initialFormData,
        mode: 'onSubmit',
        resolver: yupResolver(schema)
    });
    const handleClosee = (event?: any, reason?: any) => {
        if (!isLoading) {
            handleClose();
        }
        if (reason && reason == 'backdropClick') return;
        handleClose();
    };

    const onSubmit = async (data: CheckOutRequestBody) => {
        setLoading(true);
        data.photos = listFile;
        data.longitude = longitude;
        data.latitude = latitude;
        await checkOut({ data, id: attendance?.id ?? '-1' })
            .unwrap()
            .then((e) => {
                setLoading(false);
                withReactContent(Swal).fire('Sukses', 'Check Out Berhasil!', 'success');
            })
            .catch((error) => {});
        reset.apply(initialFormData);
        setLoading(false);
        handleClosee();
    };

    return (
        <Dialog open={open} onClose={handleClosee}>
            <form onSubmit={handleSubmit(onSubmit)}>
                {isLoading ? (
                    <DialogContent>
                        <CircularProgress />
                    </DialogContent>
                ) : (
                    <div>
                        <DialogTitle variant="h3">Clock Out</DialogTitle>
                        <DialogContent>
                            <Box sx={{ mb: 20, mt: 20 }}>
                                <FormControl fullWidth>
                                    <TextField
                                        disabled
                                        label="Location Description"
                                        type="text"
                                        name="lokasi"
                                        value={attendance?.lokasi ?? '-'}
                                    />
                                </FormControl>
                            </Box>
                            <Box sx={{ mb: 20 }}>
                                <FormControl fullWidth>
                                    <TextField
                                        disabled
                                        label="What are you going to do"
                                        type="text"
                                        name="pekerjaan"
                                        value={attendance?.pekerjaan ?? '-'}
                                    />
                                </FormControl>
                            </Box>
                            <Box sx={{ mb: 20 }}>
                                <FormControl fullWidth>
                                    <TextField
                                        disabled
                                        label="Work Category"
                                        type="text"
                                        name="work_category"
                                        value={attendance?.work_category?.name ?? '-'}
                                    />
                                </FormControl>
                            </Box>
                            <Box sx={{ mb: 20 }}>
                                <FormControl fullWidth>
                                    <TextField
                                        disabled
                                        label="Cost Center"
                                        type="text"
                                        name="cost_center_id"
                                        value={attendance?.cost_center?.name ?? '-'}
                                    />
                                </FormControl>
                            </Box>
                            <DynamicMultipleInputFileWithButton
                                onChange={(e) => {
                                    setListFile(e);
                                }}
                            />
                        </DialogContent>
                        <DialogActions>
                            {/* <Button onClick={handleClose}>Cancel</Button> */}
                            <Button size="large" type="submit">
                                Clock Out
                            </Button>
                        </DialogActions>
                    </div>
                )}
            </form>
        </Dialog>
    );
};

export default DialogCheckOut;
