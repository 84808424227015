import { Dialog, DialogContent, CircularProgress, DialogTitle, DialogActions, Button, Box, Grid } from '@mui/material';
import { FormInput } from 'components/input/hook-form';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { decodeHashId } from 'lib/helpers/hasher';
import Cookies from 'universal-cookie';
import config from 'config';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import { Department } from 'interfaces/department';
import { DepartmentRequestBody, useGetDepartmentsQuery, useUpdateDepartmentMutation } from 'lib/api/admin/adminDepartmentApi';
import Select from 'react-select';
import { CostCenterRequestBody, useUpdateCostCenterMutation } from 'lib/api/admin/adminCostCenterApi';
import { CostCenter } from 'interfaces/cost-center';
import { SubCompany } from 'interfaces/sub-company';
import { useGetSubCompaniesQuery } from 'lib/api/admin/adminSubCompanyApi';

interface IEditCostCenterDialogProps {
    open: boolean;
    handleClose: () => void;
    costCenter: CostCenter;
    departments: Department[];
    subCompanies: SubCompany[];
}

const EditCostCenterDialog = ({ open, handleClose, costCenter, departments, subCompanies }: IEditCostCenterDialogProps) => {
    useEffect(() => {
        setValue('name', costCenter.name);
        setValue('description', costCenter.description);
        setValue('amount', costCenter.amount);
        setSelectedDepartment(departments?.find((v) => v.id == costCenter.department_id));
        setSelectedSubCompany(subCompanies?.find((v) => v.id == costCenter.sub_company_id));
    }, [costCenter]);

    // dropdown data

    const [selectedDepartment, setSelectedDepartment] = useState<Department | null>();
    useEffect(() => {
        setValue('department_id', selectedDepartment?.id);
    }, [selectedDepartment]);
    const [selectedSubCompany, setSelectedSubCompany] = useState<SubCompany | null>();
    useEffect(() => {
        setValue('sub_company_id', selectedSubCompany?.id);
    }, [selectedSubCompany]);

    const cookies = new Cookies();
    const [updateCostCenter] = useUpdateCostCenterMutation();
    const initialFormData: CostCenterRequestBody = {
        name: '',
        description: '',
        amount: undefined,
        sub_company_id: undefined,
        department_id: undefined,
        company_id: +decodeHashId(cookies.get(config.cookieCompanyId))
    };
    const { handleSubmit, control, reset, setValue } = useForm({
        defaultValues: initialFormData,
        mode: 'onSubmit',
        resolver: yupResolver(
            yup
                .object({
                    name: yup.string().required('*Name is required')
                })
                .required()
        )
    });
    const onSubmit = async (data: DepartmentRequestBody) => {
        setLoading(true);
        await updateCostCenter({ data: data, id: costCenter.id ?? '' })
            .unwrap()
            .then((e) => {
                setLoading(false);
                withReactContent(Swal).fire('Sukses', 'Berhasil mengubah data!', 'success');
            })
            .catch((error) => {});
        reset.apply(initialFormData);
        setLoading(false);
        handleClosee();
    };

    const [isLoading, setLoading] = useState(false);
    const handleClosee = (event?: any, reason?: any) => {
        if (!isLoading) {
            handleClose();
        }
        if (reason && reason == 'backdropClick') return;
        handleClose();
    };
    return (
        <Dialog open={open} onClose={handleClosee}>
            <form onSubmit={handleSubmit(onSubmit)}>
                {isLoading ? (
                    <DialogContent>
                        <CircularProgress />
                    </DialogContent>
                ) : (
                    <div>
                        <DialogTitle variant="h3">Edit Cost Center</DialogTitle>
                        <DialogContent>
                            <Grid container spacing={20} direction={'column'}>
                                <Grid item>
                                    <Box sx={{ mt: 20 }}>
                                        <FormInput control={control} label="Name" type="text" name="name" />
                                    </Box>
                                </Grid>
                                <Grid item>
                                    <FormInput control={control} label="Description" type="text" name="description" />
                                </Grid>
                                <Grid item>
                                    <FormInput control={control} label="Amount" type="number" name="amount" />
                                </Grid>
                                <Grid item>
                                    <Select
                                        getOptionLabel={(e) => e.name ?? ''}
                                        getOptionValue={(e) => e.id.toString() ?? ''}
                                        options={departments}
                                        value={selectedDepartment}
                                        menuPortalTarget={document.querySelector('body')}
                                        styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                                        onChange={(newValue, actionMeta) => {
                                            setSelectedDepartment(newValue);
                                        }}
                                    />
                                </Grid>
                                <Grid item>
                                    <Select
                                        getOptionLabel={(e) => e.name ?? ''}
                                        getOptionValue={(e) => e.id.toString() ?? ''}
                                        options={subCompanies}
                                        value={selectedSubCompany}
                                        menuPortalTarget={document.querySelector('body')}
                                        styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                                        onChange={(newValue, actionMeta) => {
                                            setSelectedSubCompany(newValue);
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button size="large" type="submit">
                                Update
                            </Button>
                        </DialogActions>
                    </div>
                )}
            </form>
        </Dialog>
    );
};

export default EditCostCenterDialog;
