// assets
import {
    IconDashboard,
    IconUserCheck,
    IconHourglassEmpty,
    IconDoorExit,
    IconSettings2,
    IconAddressBook,
    IconClipboardCheck
} from '@tabler/icons';
import BadgeOutlinedIcon from '@mui/icons-material/BadgeOutlined';
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import HistoryIcon from '@mui/icons-material/History';
import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoff';

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const features = {
    id: 'features',
    title: 'Features',
    type: 'group',
    children: [
        {
            id: 'my-profile',
            title: 'My Profile',
            type: 'item',
            url: '/my-profile',
            icon: IconAddressBook,
            breadcrumbs: false
        },
        {
            id: 'attendance',
            title: 'Attendance',
            type: 'item',
            url: '/attendance',
            icon: IconUserCheck,
            breadcrumbs: false
        },
        {
            id: 'attendance-history',
            title: 'Attendance History',
            type: 'item',
            url: '/attendance/history',
            icon: HistoryIcon,
            breadcrumbs: false
        },
        {
            id: 'leave',
            title: 'Leave',
            type: 'item',
            url: '/leave',
            icon: FlightTakeoffIcon,
            breadcrumbs: false
        },
        {
            id: 'leave-approval',
            title: 'Leave Approval',
            type: 'item',
            url: '/leave/approval',
            icon: IconClipboardCheck,
            breadcrumbs: false
        }
    ]
};

export default features;
