import { AxiosError } from 'axios';
import { getToken, removeToken } from 'lib/helpers/tokenHandler';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { ApiResponse } from 'interfaces/api-response';
import { PaginateRequest } from 'interfaces/api-request';

const SweetAlert = withReactContent(Swal);
export const validateResponse = async (result: ApiResponse<any>, redirectUnauthorizedPath?: string) => {
    if (result.code == 401) {
        await SweetAlert.fire({ title: 'Unauthorized', html: '<i>Kembali ke Login Page!</i>', icon: 'error', timer: 1000 }).then(() => {
            removeToken();
            window.location.replace(redirectUnauthorizedPath ?? '/login');
            return { error: 'Unauthorized!' };
        });
    }
    if (result.code == 200 || result.code == 201) {
        return result;
    }
    return {
        error: {
            status: result.code,
            data: result.message
        }
    };
};

export const validateError = async (err: AxiosError, redirectUnauthorizedPath?: string) => {
    if (err.response?.status == 401) {
        await SweetAlert.fire({
            title: 'Unauthorized',
            html: '<i>Kembali ke Login Page!</i>',
            icon: 'error',
            timer: 1000,
            showConfirmButton: false
        }).then(() => {
            removeToken();
            window.location.replace(redirectUnauthorizedPath ?? '/login');
            return { error: 'Unauthorized!' };
        });
    } else {
        const error = err as any;
        SweetAlert.fire({
            title: 'Error',
            html: '<i>' + error.response?.data?.message || err.message || err.response?.data + '</i>',
            icon: 'error'
        });
        return {
            error: {
                status: err.response?.status,
                data: error.response?.data?.message || err.message || err.response?.data
            }
        };
    }
    return {
        error: {
            status: err.response?.status,
            data: err.response?.data || err.message
        }
    };
};

export const getBaseHeaderApi = (isNeedAuth: boolean = true) => {
    return {
        'Access-Control-Allow-Origin': '*',
        'Content-type': 'application/json',
        Authorization: isNeedAuth ? 'Bearer ' + getToken() : ''
    };
};

export const generateParamPaginate = (request: PaginateRequest) => {
    const param = [
        ...(request.q ? [`${request.q ? 'q=' + request.q : ''}`] : []),
        ...(request.current_page ? [`${request.current_page ? 'current_page=' + request.current_page : ''}`] : []),
        ...(request.per_page ? [`${request.per_page ? 'per_page=' + request.per_page : ''}`] : []),
        ...(request.order ? [`${request.order ? 'order=' + request.order : ''}`] : []),
        ...(request.order_by ? [`${request.order_by ? 'order_by=' + request.order_by : ''}`] : [])
    ];
    return param.join('&');
};

export const setParam = (label?: string, value?: any) => {
    return value != undefined && value != '' ? `&${label ?? value!}=` + value! : '';
};
