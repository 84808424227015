import { yupResolver } from '@hookform/resolvers/yup';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { Box, Button, Card, CardContent, CircularProgress, Grid, IconButton, InputAdornment } from '@mui/material';
import { FormInput } from 'components/input/hook-form';
import { Layout } from 'components/layout';
import { useChangePasswordMutation } from 'lib/api/profileApi';
import { ChangePasswordRequestBody } from 'lib/api/requests/loginRequest';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import withReactContent from 'sweetalert2-react-content';
import { useNavigate } from 'react-router';
import Swal from 'sweetalert2';

const ChangePasswordPage = () => {
    const initialFormData: ChangePasswordRequestBody = {
        old_password: '',
        new_password: ''
    };
    const { handleSubmit, control, reset, setValue } = useForm({
        defaultValues: initialFormData,
        mode: 'onSubmit',
        resolver: yupResolver(
            yup
                .object({
                    old_password: yup.string().required('*Old Password is required'),
                    new_password: yup.string().required('*New Password is required')
                })
                .required()
        )
    });

    const [showOldPassword, setShowOldPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const handleClickShowOldPassword = () => {
        setShowOldPassword(!showOldPassword);
    };
    const handleClickShowNewPassword = () => {
        setShowNewPassword(!showNewPassword);
    };

    const [isLoading, setIsLoading] = useState(false);
    const [changePassword] = useChangePasswordMutation();
    const navigate = useNavigate();

    const onSubmit = async (data: ChangePasswordRequestBody) => {
        setIsLoading(true);
        await changePassword(data)
            .unwrap()
            .then((e) => {
                setIsLoading(false);
                reset.apply(initialFormData);
                navigate(-1);
                withReactContent(Swal).fire('Sukses', 'Berhasil mengubah password!', 'success');
            })
            .catch((error) => {});
        setIsLoading(false);
    };

    return (
        <Layout headerTitle="Change Password" pageTitle="Change Password" disableBreadcrumb>
            <Grid container spacing={20} justifyContent={'center'}>
                <Grid item md={4} lg={4}>
                    <Card>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <CardContent>
                                {isLoading ? (
                                    <Grid item container spacing={20} justifyContent={'center'}>
                                        <Grid item>
                                            <CircularProgress />
                                        </Grid>
                                    </Grid>
                                ) : (
                                    <Grid item container spacing={20} justifyContent={'end'}>
                                        <Grid item xs={12}>
                                            <FormInput
                                                control={control}
                                                label="Old Password"
                                                name="old_password"
                                                type={showOldPassword ? 'text' : 'password'}
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={handleClickShowOldPassword}
                                                            edge="end"
                                                            size="large"
                                                        >
                                                            {showOldPassword ? <Visibility /> : <VisibilityOff />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                }
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <FormInput
                                                control={control}
                                                label="New Password"
                                                name="new_password"
                                                type={showNewPassword ? 'text' : 'password'}
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={handleClickShowNewPassword}
                                                            edge="end"
                                                            size="large"
                                                        >
                                                            {showOldPassword ? <Visibility /> : <VisibilityOff />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                }
                                            />
                                        </Grid>
                                        <Grid item>
                                            <Button size="large" type="submit">
                                                Change
                                            </Button>
                                        </Grid>
                                    </Grid>
                                )}
                            </CardContent>
                        </form>
                    </Card>
                </Grid>
            </Grid>
        </Layout>
    );
};

export default ChangePasswordPage;
