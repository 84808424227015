import {
    Dialog,
    DialogContent,
    CircularProgress,
    DialogTitle,
    Typography,
    DialogActions,
    Button,
    Box,
    TextField,
    Grid
} from '@mui/material';
import { FormInput } from 'components/input/hook-form';
import { WorkCategoryType } from 'interfaces/work-category-type';
import React, { useEffect, useState } from 'react';
import { BaseForm } from '../../../../components/input/hook-form/BaseForm';
import { ActionMeta, InputActionMeta } from 'react-select';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { decodeHashId } from 'lib/helpers/hasher';
import Cookies from 'universal-cookie';
import config from 'config';
import Select from 'react-select';
import {
    WorkCategoryReplaceRequestBody,
    WorkCategoryRequestBody,
    useCreateWorkCategoryMutation,
    useGetWorkCategoriesQuery,
    useReplaceWorkCategoryMutation
} from 'lib/api/admin/adminWorkCategoryApi';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import { WorkCategory } from 'interfaces/work-category';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';

interface IReplaceWorkCategoryDialogProps {
    open: boolean;
    handleClose: () => void;
    workCategory: WorkCategory;
}

const ReplaceWorkCategoryDialog = ({ open, handleClose, workCategory }: IReplaceWorkCategoryDialogProps) => {
    const cookies = new Cookies();
    const initialFormData: WorkCategoryReplaceRequestBody = {
        current_work_category_id: '',
        target_work_category_id: '',
        company_id: +decodeHashId(cookies.get(config.cookieCompanyId))
    };
    const { isLoading: workCategoriesIsLoading, error, data, isFetching } = useGetWorkCategoriesQuery();

    const [replaceWorkcategory] = useReplaceWorkCategoryMutation();

    const { handleSubmit, control, reset, setValue } = useForm({
        defaultValues: initialFormData,
        mode: 'onSubmit',
        resolver: yupResolver(
            yup
                .object({
                    // employeeId: yup.number().required('*Email is required'),
                    // name: yup.string().required('*Name is required'),
                    current_work_category_id: yup.string().required('*Current Work Category is required'),
                    target_work_category_id: yup.string().required('*Target Work Category is required')
                })
                .required()
        )
    });
    useEffect(() => {
        setValue('current_work_category_id', workCategory.id);
    }, []);
    const onSubmit = async (data: WorkCategoryReplaceRequestBody) => {
        setLoading(true);
        await replaceWorkcategory(data)
            .unwrap()
            .then((e) => {
                setLoading(false);
                withReactContent(Swal).fire('Sukses', 'Berhasil menyatukan data!', 'success');
            })
            .catch((error) => {});
        reset.apply(initialFormData);
        setLoading(false);
        handleClosee();
    };
    const [selectedWorkCategory, setSelectedWorkCategory] = useState<WorkCategory | null>();
    useEffect(() => {
        setValue('target_work_category_id', selectedWorkCategory?.id);
    }, [selectedWorkCategory]);
    useEffect(() => {
        setValue('current_work_category_id', workCategory.id);
    }, [workCategory]);

    const [isLoading, setLoading] = useState(false);
    const handleClosee = (event?: any, reason?: any) => {
        if (!isLoading) {
            handleClose();
        }
        if (reason && reason == 'backdropClick') return;
        handleClose();
    };
    return (
        <Dialog
            fullWidth={isLoading || workCategoriesIsLoading ? false : true}
            maxWidth={isLoading || workCategoriesIsLoading ? undefined : 'sm'}
            open={open}
            onClose={handleClosee}
        >
            <form onSubmit={handleSubmit(onSubmit)}>
                {isLoading || workCategoriesIsLoading ? (
                    <DialogContent>
                        <CircularProgress />
                    </DialogContent>
                ) : (
                    <div>
                        <DialogTitle variant="h3">Replace Work Category</DialogTitle>
                        <DialogContent>
                            <Grid container direction={'row'} sx={{ mb: 20, mt: 20 }} alignItems={'center'} justifyContent={'center'}>
                                <Grid item>
                                    <TextField
                                        fullWidth
                                        label="Current Work Category"
                                        type="text"
                                        value={`${workCategory.name} (${workCategory.work_category_type?.name})`}
                                        InputProps={{
                                            readOnly: true
                                        }}
                                    />
                                </Grid>
                                <Grid item sx={{ ml: 10, mr: 10 }}>
                                    <KeyboardDoubleArrowRightIcon />
                                </Grid>
                                <Grid item>
                                    <BaseForm name="category_type_id" control={control} label="tes">
                                        <Select
                                            placeholder={<Typography>Select Target Work Category</Typography>}
                                            getOptionLabel={(e) => `${e.name ?? ''} (${e.work_category_type?.name})`}
                                            getOptionValue={(e) => e.id?.toString() ?? ''}
                                            options={data?.data?.filter((val) => {
                                                return val.id != workCategory.id;
                                            })}
                                            value={selectedWorkCategory}
                                            menuPortalTarget={document.body}
                                            styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                                            onChange={(newValue, actionMeta) => {
                                                setSelectedWorkCategory(newValue);
                                                setValue('target_work_category_id', newValue?.id);
                                            }}
                                        />
                                    </BaseForm>
                                </Grid>
                            </Grid>
                            <Typography color={'red'} textAlign={'center'}>
                                Warning: Semua record attendance yang berelasi dengan Current Work Category akan dialihkan ke Target Work
                                Category dan tidak dapat dikembalikan
                            </Typography>
                        </DialogContent>
                        <DialogActions>
                            {/* <Button onClick={handleClose}>Cancel</Button> */}
                            <Button size="large" type="submit">
                                Replace
                            </Button>
                        </DialogActions>
                    </div>
                )}
            </form>
        </Dialog>
    );
};

export default ReplaceWorkCategoryDialog;
