import { Typography, Button, Grid } from '@mui/material';
import { omit } from 'lodash';
import Select, { GroupBase, Props } from 'react-select';

interface CustomSelectAsyncProps {
    label: string;
    onSelectAll?: Function;
}

type SelectProps<Option, IsMulti extends boolean = false, Group extends GroupBase<Option> = GroupBase<Option>> = Props<
    Option,
    IsMulti,
    Group
> &
    CustomSelectAsyncProps;

const FilterSelectContent = <Option, IsMulti extends boolean = false, Group extends GroupBase<Option> = GroupBase<Option>>(
    props: SelectProps<Option, IsMulti, Group>
) => {
    props = { ...props, placeholder: <Typography>{props.label}</Typography> };
    const reactSelectProps = omit(props, ['label', 'className']);
    return (
        <div>
            <Grid container alignItems={'center'}>
                <Grid item xs={6}>
                    <Typography>{props.label}</Typography>
                </Grid>
                <Grid item xs={6} textAlign={'right'}>
                    <Button
                        variant="text"
                        size="small"
                        onClick={() => {
                            if (props.onSelectAll) {
                                props.onSelectAll!();
                            }
                        }}
                    >
                        select all
                    </Button>
                </Grid>
                {/* {onSelectAll && (
                )} */}
            </Grid>
            <Select {...reactSelectProps} />
        </div>
    );
};

export default FilterSelectContent;
