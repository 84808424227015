import { ApiResponse, ApiResponseWithPagination } from 'interfaces/api-response';
import { generateParamPaginate, getBaseHeaderApi, setParam } from '../services/apiHelper';
import { adminApi, baseApi } from '../services/services';
import { WorkCategory } from 'interfaces/work-category';
import { PaginateRequest } from 'interfaces/api-request';
import { CompanyId } from 'interfaces/company-id';

export interface WorkCategoryRequestBody extends CompanyId {
    name?: string;
    category_type_id?: string;
}
export interface WorkCategoryReplaceRequestBody extends CompanyId {
    current_work_category_id?: string;
    target_work_category_id?: string;
}

export const AdminWorkCategoryApi = adminApi.enhanceEndpoints({ addTagTypes: ['workCategories'] }).injectEndpoints({
    endpoints(builder) {
        return {
            getWorkCategories: builder.query<ApiResponse<WorkCategory[]>, void>({
                query: () => ({
                    url: '/work_category',
                    method: 'GET',
                    headers: getBaseHeaderApi()
                }),
                providesTags: ['workCategories']
            }),
            getWorkCategoriesPaginate: builder.query<
                ApiResponseWithPagination<WorkCategory[]>,
                PaginateRequest & { work_category_type?: string }
            >({
                query: (request) => ({
                    url: `/work_category_paginate?${generateParamPaginate(request)}${setParam(
                        'work_category_type',
                        request.work_category_type
                    )}`,
                    method: 'GET',
                    headers: getBaseHeaderApi()
                }),
                providesTags: ['workCategories']
            }),
            createWorkCategory: builder.mutation({
                query: (data: WorkCategoryRequestBody) => ({
                    url: '/work_category',
                    method: 'POST',
                    headers: getBaseHeaderApi(),
                    data
                }),
                invalidatesTags: ['workCategories']
            }),
            updateWorkCategory: builder.mutation<any, { data: WorkCategoryRequestBody; id: string }>({
                query: ({ data, id }) => ({
                    url: `/work_category/${id}`,
                    method: 'PUT',
                    headers: getBaseHeaderApi(),
                    data
                }),
                invalidatesTags: ['workCategories']
            }),
            deleteWorkCategory: builder.mutation<any, { id: string }>({
                query: ({ id }) => ({
                    url: `/work_category/${id}`,
                    method: 'DELETE',
                    headers: getBaseHeaderApi()
                }),
                invalidatesTags: ['workCategories']
            }),
            replaceWorkCategory: builder.mutation<any, WorkCategoryReplaceRequestBody>({
                query: (data) => ({
                    url: `/work_category_replace`,
                    method: 'POST',
                    headers: getBaseHeaderApi(),
                    data
                }),
                invalidatesTags: ['workCategories']
            })
        };
    }
});

export const {
    useGetWorkCategoriesQuery,
    useGetWorkCategoriesPaginateQuery,
    useCreateWorkCategoryMutation,
    useUpdateWorkCategoryMutation,
    useDeleteWorkCategoryMutation,
    useReplaceWorkCategoryMutation
} = AdminWorkCategoryApi;
