import { ApiResponse, ApiResponseWithPagination } from 'interfaces/api-response';
import { generateParamPaginate, getBaseHeaderApi, setParam } from '../services/apiHelper';
import { adminApi } from '../services/services';
import { PaginateRequest } from 'interfaces/api-request';
import { CompanyId } from 'interfaces/company-id';
import { CostCenter } from 'interfaces/cost-center';

export interface CostCenterRequestBody extends CompanyId {
    name?: string;
    description?: string;
    department_id?: string;
    sub_company_id?: string;
    amount?: number;
}

export const AdminCostCenterApi = adminApi.enhanceEndpoints({ addTagTypes: ['costCenters'] }).injectEndpoints({
    endpoints(builder) {
        return {
            getCostCenters: builder.query<ApiResponse<CostCenter[]>, void>({
                query: () => ({
                    url: '/cost_center',
                    method: 'GET',
                    headers: getBaseHeaderApi()
                }),
                providesTags: ['costCenters']
            }),
            getCostCentersPaginate: builder.query<ApiResponseWithPagination<CostCenter[]>, PaginateRequest>({
                query: (request) => ({
                    url: `/cost_center_paginate?${generateParamPaginate(request)}`,
                    method: 'GET',
                    headers: getBaseHeaderApi()
                }),
                providesTags: ['costCenters']
            }),
            createCostCenter: builder.mutation({
                query: (data: CostCenterRequestBody) => ({
                    url: '/cost_center',
                    method: 'POST',
                    headers: getBaseHeaderApi(),
                    data
                }),
                invalidatesTags: ['costCenters']
            }),
            updateCostCenter: builder.mutation<any, { data: CostCenterRequestBody; id: string }>({
                query: ({ data, id }) => ({
                    url: `/cost_center/${id}`,
                    method: 'PUT',
                    headers: getBaseHeaderApi(),
                    data
                }),
                invalidatesTags: ['costCenters']
            }),
            deleteCostCenter: builder.mutation<any, { id: string }>({
                query: ({ id }) => ({
                    url: `/cost_center/${id}`,
                    method: 'DELETE',
                    headers: getBaseHeaderApi()
                }),
                invalidatesTags: ['costCenters']
            })
        };
    }
});

export const {
    useGetCostCentersQuery,
    useGetCostCentersPaginateQuery,
    useCreateCostCenterMutation,
    useUpdateCostCenterMutation,
    useDeleteCostCenterMutation
} = AdminCostCenterApi;
