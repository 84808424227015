import { Box, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import React, { useState, useRef, ChangeEvent, useEffect } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';

interface IDynamicMultipleInputFileProps {
    onChange: (files: any[]) => void;
}

// interface FileInput {
//     file?: File | null;
// }

const DynamicMultiplaInputFile = ({ onChange }: IDynamicMultipleInputFileProps) => {
    const [fileList, setFileList] = useState<(File | null | undefined)[]>([]);
    const [fileListConverted, setFileListConverted] = useState<any[]>([]);
    const [numInputs, setNumInputs] = useState<number>(1);
    const inputRefs = useRef<HTMLInputElement[]>([]);

    useEffect(() => {
        onChange(fileListConverted);
    }, [fileListConverted]);

    const handleFileInputChange = (event: ChangeEvent<HTMLInputElement>, index: number) => {
        const file = event.target.files![0];
        if (file) {
            const newFileList = [...fileList];
            handleFileRead(file);
            newFileList[index] = file;
            setFileList(newFileList);
        }
    };

    const handleRemoveFile = (index: number) => {
        const newFileList = [...fileList];
        newFileList.splice(index, 1);
        setFileList(newFileList);
        setNumInputs(numInputs - 1);
    };

    const handleAddFile = () => {
        setFileList([...fileList, null]);
        setNumInputs(numInputs + 1);
    };

    const handleInputRef = (input: HTMLInputElement, index: number) => {
        inputRefs.current[index] = input;
    };

    const handleFileRead = async (file: File) => {
        var base64: string | null;
        await convertBase64(file).then((base64) => {
            const newFileListConverted = [...fileListConverted, base64];
            setFileListConverted(newFileListConverted);
        });
    };

    const convertBase64 = (file: File) => {
        return new Promise<any>((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);
            fileReader.onload = () => {
                resolve(fileReader.result);
            };
            fileReader.onerror = (error) => {
                reject(error);
            };
        });
    };

    const renderFileInputs = () => {
        const inputs = [];
        for (let i = 1; i < numInputs; i++) {
            inputs.push(
                <TableRow key={i}>
                    <TableCell>
                        <Button variant="contained" size="small" component="label">
                            Upload File
                            <input
                                required
                                type="file"
                                onChange={(e) => handleFileInputChange(e, i)}
                                ref={(input) => handleInputRef(input!, i)}
                                hidden
                                name="photos[]"
                            />
                        </Button>
                    </TableCell>
                    <TableCell>
                        {fileList[i] && (
                            <div>
                                <Typography lineHeight={1}>{fileList[i]!.name}</Typography>
                            </div>
                        )}
                    </TableCell>
                    <TableCell>
                        <Button onClick={() => handleRemoveFile(i)}>
                            <DeleteIcon sx={{ color: 'error.main' }} />
                        </Button>
                    </TableCell>
                </TableRow>
            );
        }
        return inputs;
    };

    return (
        <div>
            <TableContainer sx={{ paddingTop: 20 }}>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>File Input</TableCell>
                            <TableCell>Selected Files</TableCell>
                            <TableCell>Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow key={0}>
                            <TableCell>
                                <Button variant="contained" size="small" component="label">
                                    Upload File
                                    <input
                                        type="file"
                                        onChange={(e) => handleFileInputChange(e, 0)}
                                        ref={(input) => handleInputRef(input!, 0)}
                                        hidden
                                        name="photos[]"
                                    />
                                </Button>
                            </TableCell>
                            <TableCell>
                                {fileList[0] && (
                                    <div>
                                        <Typography lineHeight={1}>{fileList[0]!.name}</Typography>
                                    </div>
                                )}
                            </TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                        {renderFileInputs()}
                    </TableBody>
                </Table>
            </TableContainer>
            <Box sx={{ paddingTop: 5 }}>
                {numInputs < 3 && (
                    <Button onClick={handleAddFile}>
                        <AddIcon />
                        Add Attachment
                    </Button>
                )}
            </Box>
        </div>
    );
};

export default DynamicMultiplaInputFile;
