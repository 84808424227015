import { Navigate, useRoutes } from 'react-router-dom';

// routes
// import RedirectRoutes from './rediretRoutes';
import AdminRoutes from './AdminRoutes';
import EmployeeRoutes from './EmployeeRoutes';
import AuthenticationRoutes from './AuthenticationRoutes';

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
    return useRoutes([EmployeeRoutes, AuthenticationRoutes, AdminRoutes]);
}
