import { Grid } from '@mui/material';
import { FormInput, SelectInput, SelectInputBackend } from 'components/input/hook-form';
import { Control } from 'react-hook-form';
import {
    listSalaryType,
    listPtkpStatus,
    listTaxMethod,
    listTaxSalary,
    listTaxStatus,
    listBpjsKsCost,
    listRetiredType
} from 'lib/helpers/dataSelect';
import { CreateEmploymentData } from 'interfaces/adminSetting';

const PayrollDataTab = ({ control, data }: { control: Control<any>; data?: CreateEmploymentData }) => {
    return (
        <Grid container md={12} lg={12} spacing={10} rowSpacing={14}>
            <Grid item xs={12} md={6} lg={4}>
                <FormInput control={control} label="Salary" type="number" name="salary" />
            </Grid>
            <Grid item xs={12} md={6} lg={4} paddingTop={0} marginTop={0}>
                <SelectInput control={control} name="salary_type" label="Salary Type" options={listSalaryType} />
            </Grid>
            <Grid item xs={12} md={6} lg={4} paddingTop={0} marginTop={0}>
                <SelectInputBackend control={control} name="bank_id" label="Bank" options={data?.banks} />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
                <FormInput control={control} label="Bank Account Number" type="number" name="bank_account_number" />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
                <FormInput control={control} label="Bank Account Name" type="text" name="bank_account_name" />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
                <FormInput control={control} label="NPWP Number" type="number" name="npwp" />
            </Grid>
            <Grid item xs={12} md={6} lg={4} paddingTop={0} marginTop={0}>
                <SelectInput name="ptkp" control={control} label="PTKP Status" options={listPtkpStatus} />
            </Grid>
            <Grid item xs={12} md={6} lg={4} paddingTop={0} marginTop={0}>
                <SelectInput name="tax_method" control={control} label="Tax Method" options={listTaxMethod} />
            </Grid>
            <Grid item xs={12} md={6} lg={4} paddingTop={0} marginTop={0}>
                <SelectInput name="tax_salary" control={control} label="Tax Salary" options={listTaxSalary} />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
                <FormInput isShrink control={control} label="Taxable Date" type="date" name="tax_date" />
            </Grid>
            <Grid item xs={12} md={6} lg={4} paddingTop={0} marginTop={0}>
                <SelectInput name="tax_status" control={control} label="Employment Tax Status" options={listTaxStatus} />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
                <FormInput control={control} label="BPJS Ketenagakerjaan Number" type="number" name="bpjs_ktn_number" />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
                <FormInput isShrink control={control} label="BPJS Ketenagakerjaan Date" type="date" name="bpjs_ktn_date" />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
                <FormInput control={control} label="BPJS Kesehatan Number" type="number" name="bpjs_ks_number" />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
                <FormInput isShrink control={control} label="BPJS Kesehatan Date" type="date" name="bpjs_ks_date" />
            </Grid>
            <Grid item xs={12} md={6} lg={4} paddingTop={0} marginTop={0}>
                <SelectInput name="bpjs_ks_cost" control={control} label="BPJS Kesehatan Cost" options={listBpjsKsCost} />
            </Grid>
            <Grid item xs={12} md={6} lg={4} paddingTop={0} marginTop={0}>
                <SelectInput name="retired_type" control={control} label="Retired Guarantee Cost" options={listRetiredType} />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
                <FormInput isShrink control={control} label="Guarantee Retired Date" type="date" name="retired_date" />
            </Grid>
        </Grid>
    );
};

export default PayrollDataTab;
