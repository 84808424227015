import { ApiResponse, ApiResponseWithPagination } from 'interfaces/api-response';
import { generateParamPaginate, getBaseHeaderApi, setParam } from '../services/apiHelper';
import { adminApi, baseApi } from '../services/services';
import { WorkCategory } from 'interfaces/work-category';
import { PaginateRequest } from 'interfaces/api-request';
import { CompanyId } from 'interfaces/company-id';
import { SubCompany } from 'interfaces/sub-company';

export interface SubCompanyRequestBody extends CompanyId {
    name?: string;
}

export const AdminSubCompanyApi = adminApi.enhanceEndpoints({ addTagTypes: ['subCompanies'] }).injectEndpoints({
    endpoints(builder) {
        return {
            getSubCompanies: builder.query<ApiResponse<SubCompany[]>, void>({
                query: () => ({
                    url: '/sub_company',
                    method: 'GET',
                    headers: getBaseHeaderApi()
                }),
                providesTags: ['subCompanies']
            }),
            getSubCompaniesPaginate: builder.query<ApiResponseWithPagination<SubCompany[]>, PaginateRequest>({
                query: (request) => ({
                    url: `/sub_company_paginate?${generateParamPaginate(request)}`,
                    method: 'GET',
                    headers: getBaseHeaderApi()
                }),
                providesTags: ['subCompanies']
            }),
            createSubCompany: builder.mutation({
                query: (data: SubCompanyRequestBody) => ({
                    url: '/sub_company',
                    method: 'POST',
                    headers: getBaseHeaderApi(),
                    data
                }),
                invalidatesTags: ['subCompanies']
            }),
            updateSubCompany: builder.mutation<any, { data: SubCompanyRequestBody; id: string }>({
                query: ({ data, id }) => ({
                    url: `/sub_company/${id}`,
                    method: 'PUT',
                    headers: getBaseHeaderApi(),
                    data
                }),
                invalidatesTags: ['subCompanies']
            }),
            deleteSubCompany: builder.mutation<any, { id: string }>({
                query: ({ id }) => ({
                    url: `/sub_company/${id}`,
                    method: 'DELETE',
                    headers: getBaseHeaderApi()
                }),
                invalidatesTags: ['subCompanies']
            })
        };
    }
});

export const {
    useGetSubCompaniesQuery,
    useGetSubCompaniesPaginateQuery,
    useCreateSubCompanyMutation,
    useUpdateSubCompanyMutation,
    useDeleteSubCompanyMutation
} = AdminSubCompanyApi;
