import type { BaseQueryApi, BaseQueryFn } from '@reduxjs/toolkit/query';
import type { AxiosError, AxiosRequestConfig, AxiosRequestHeaders } from 'axios';
import axios from './axios';
import Axios from 'axios';
import { API_BASE_URL } from 'configs/apiUrl';
import { validateError } from './apiHelper';
import { ApiResponse } from 'interfaces/api-response';

export interface AxiosBaseQueryArgs<Meta, Response = ApiResponse<any>> {
    meta?: Meta;
    prepareHeaders?: (headers: AxiosRequestHeaders, api: BaseQueryApi) => AxiosRequestHeaders;
    transformResponse?: (response: Response) => unknown;
    redirectUnauthorizedPath?: string;
}

export interface ServiceExtraOptions {
    authRequired?: boolean;
}

// const getRequestConfig = (args: string | AxiosRequestConfig) => {
//     if (typeof args === 'string') {
//         return { url: args };
//     }

//     return args;
// };

const axiosBaseQuery = <
    Args extends AxiosRequestConfig | string = AxiosRequestConfig,
    Result = unknown,
    DefinitionExtraOptions extends ServiceExtraOptions = Record<string, unknown>,
    Meta = Record<string, unknown>
>({ prepareHeaders, meta, transformResponse, redirectUnauthorizedPath }: AxiosBaseQueryArgs<Meta> = {}): BaseQueryFn<
    {
        url: string;
        method: AxiosRequestConfig['method'];
        data?: AxiosRequestConfig['data'];
        headers?: any;
        params?: AxiosRequestConfig['params'];
    },
    Result,
    unknown,
    DefinitionExtraOptions,
    Meta
> => {
    return async (args, api, extraOptions) => {
        try {
            // const requestConfig = getRequestConfig(args);
            const result = await axios({
                method: args.method,
                data: args.data,
                headers: args.headers,
                params: args.params,
                url: API_BASE_URL + args.url,
                signal: api.signal,
                ...extraOptions
            });

            return {
                data: transformResponse ? transformResponse(result.data) : result.data
            };
        } catch (err) {
            if (!Axios.isAxiosError(err)) {
                return {
                    error: err,
                    meta
                };
            }
            return validateError(err as AxiosError, redirectUnauthorizedPath);
        }
    };
};

export default axiosBaseQuery;
