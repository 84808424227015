import { ApiResponse, ApiResponseWithPagination } from 'interfaces/api-response';
import { generateParamPaginate, getBaseHeaderApi } from '../services/apiHelper';
import { adminApi, baseApi } from '../services/services';
import { WorkCategory } from 'interfaces/work-category';
import { PaginateRequest } from 'interfaces/api-request';
import { WorkCategoryType } from 'interfaces/work-category-type';

export const WorkCategoryTypeApi = adminApi.injectEndpoints({
    endpoints(builder) {
        return {
            getWorkCategoryTypes: builder.query<ApiResponse<WorkCategoryType[]>, void>({
                query: () => ({
                    url: '/work_category_type',
                    method: 'GET',
                    headers: getBaseHeaderApi()
                })
            })
        };
    }
});

export const { useGetWorkCategoryTypesQuery } = WorkCategoryTypeApi;
