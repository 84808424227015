export const listStatus = [
    {
        value: 'C',
        label: 'Contract'
    },
    {
        value: 'P',
        label: 'Permanent'
    }
];
export const listMaritalStatus = [
    {
        value: 'S',
        label: 'Single'
    },
    {
        value: 'M',
        label: 'Married'
    }
];
export const listSalaryType = [
    {
        value: 'M',
        label: 'Monthly'
    },
    {
        value: 'D',
        label: 'Daily'
    }
];
export const listTaxSalary = [
    {
        value: 'Taxable',
        label: 'Taxable'
    },
    {
        value: 'Non-Taxable',
        label: 'Non-Taxable'
    }
];
export const listTaxMethod = [
    {
        value: 'G',
        label: 'Gross'
    },
    {
        value: 'U',
        label: 'Gross Up'
    },
    {
        value: 'N',
        label: 'Netto'
    }
];
export const listBloodType = [
    {
        value: 'A',
        label: 'A'
    },
    {
        value: 'B',
        label: 'B'
    },
    {
        value: 'O',
        label: 'O'
    },
    {
        value: 'AB',
        label: 'AB'
    }
];
export const listGender = [
    {
        value: 'M',
        label: 'Male'
    },
    {
        value: 'F',
        label: 'Female'
    }
];
export const listPtkpStatus = [
    {
        value: 'TK/0',
        label: 'TK/0'
    },
    {
        value: 'TK/1',
        label: 'TK/1'
    },
    {
        value: 'TK/2',
        label: 'TK/2'
    },
    {
        value: 'TK/3',
        label: 'TK/3'
    },
    {
        value: 'K/0',
        label: 'K/0'
    },
    {
        value: 'K/1',
        label: 'K/1'
    },
    {
        value: 'K/2',
        label: 'K/2'
    },
    {
        value: 'K/3',
        label: 'K/3'
    }
];
export const listCitizenType = [
    {
        value: 'WNI',
        label: 'WNI'
    },
    {
        value: 'WNA',
        label: 'WNA'
    }
];
export const listBpjsKsCost = [
    {
        value: 'BE',
        label: 'By Employee'
    },
    {
        value: 'BC',
        label: 'By Company'
    }
];
export const listRetiredType = [
    {
        value: 'NP',
        label: 'Not Paid'
    },
    {
        value: 'BE',
        label: 'By Employee'
    },
    {
        value: 'BC',
        label: 'By Company'
    }
];
export const listTaxStatus = [
    {
        value: 'Pegawai Tetap',
        label: 'Pegawai Tetap'
    },
    {
        value: 'Pegawai Tidak Tetap',
        label: 'Pegawai Tidak Tetap'
    }
];
export const listReligion = [
    {
        value: 'Buddha',
        label: 'Buddha'
    },
    {
        value: 'Katholik',
        label: 'Katholik'
    },
    {
        value: 'Islam',
        label: 'Islam'
    },
    {
        value: 'Kristen',
        label: 'Kristen'
    },
    {
        value: 'Hindu',
        label: 'Hindu'
    },
    {
        value: 'Konghucu',
        label: 'Konghucu'
    },
    {
        value: 'Aliran Kepercayaan',
        label: 'Aliran Kepercayaan'
    }
];

export const listHubungan = [
    {
        value: 'Istri',
        label: 'Istri'
    },
    {
        value: 'Suami',
        label: 'Suami'
    },
    {
        value: 'Orang Tua',
        label: 'Orang Tua'
    },
    {
        value: 'Anak',
        label: 'Anak'
    },
    {
        value: 'Saudara',
        label: 'Saudara'
    }
];

export const listTanggungan = [
    {
        value: 'Istri',
        label: 'Istri'
    },
    {
        value: 'Suami',
        label: 'Suami'
    },
    {
        value: 'Orang Tua',
        label: 'Orang Tua'
    },
    {
        value: 'Anak',
        label: 'Anak'
    }
];
