import { Grid, FormControlLabel, Checkbox, CircularProgress } from '@mui/material';
import { FormInput, SelectInput, SelectInputBackend } from 'components/input/hook-form';
import { Control, Controller } from 'react-hook-form';
import { listStatus } from 'lib/helpers/dataSelect';
import SelectEmployeeCreate from './SelectEmployeeCreate';
import SelectEmployeeEdit from './SelectEmployeeEdit';
import { EditEmploymentData } from 'interfaces/adminSetting';
import { Employee } from 'interfaces/employee';
import { useEffect } from 'react';

const EmploymentDataTab = ({
    control,
    data,
    dataEmployee
}: {
    control: Control<any>;
    data?: EditEmploymentData;
    dataEmployee?: Employee;
}) => {
    useEffect(() => {
        console.log('data', dataEmployee);
    });
    return (
        <Grid container spacing={10} rowSpacing={14}>
            <Grid item xs={12} md={6} lg={4}>
                <FormInput control={control} label="NIK (Nomor Induk Karyawan)" type="text" name="nik" />
            </Grid>
            <Grid item xs={12} md={6} lg={4} paddingTop={0} marginTop={0}>
                <SelectInput required control={control} name="status" label="Employment Status" options={listStatus} />
            </Grid>
            <Grid item xs={12} md={6} lg={4} paddingTop={0} marginTop={0}>
                <SelectInputBackend control={control} name="title_id" label="Title" options={data?.titles} />
            </Grid>
            <Grid item xs={12} md={6} lg={4} paddingTop={0} marginTop={0}>
                <SelectInputBackend control={control} name="department_id" label="Department" options={data?.departments} />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
                <SelectEmployeeCreate control={control} label="Supervisor" name="spv_id" />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
                <SelectEmployeeCreate control={control} label="Direct Supervisor" name="direct_spv_id" />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
                <FormInput isShrink required control={control} label="Join Date" type="date" name="join_date" />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
                <FormInput isShrink control={control} label="End Date" type="date" name="end_date" />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
                <FormInput isShrink control={control} label="Permanent Date" type="date" name="permanent_date" />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
                <FormInput isShrink control={control} label="Terminate Date" type="date" name="terminate_date" />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
                <FormInput
                    multiline
                    maxRows={4}
                    minRows={2}
                    control={control}
                    label="Terminate Reason"
                    type="text"
                    name="terminate_reason"
                />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
                <FormInput control={control} label="Leave Balance" type="number" name="leave_balance" />
            </Grid>
            <Grid item xs={12} md={6} lg={4} paddingTop={0} marginTop={0} alignSelf={'center'} justifyContent={'center'}>
                <SelectInputBackend control={control} name="leave_flow_id" label="Leave Flow" options={data?.leave_flows} />
            </Grid>
            <Grid item xs={12} md={6} lg={4} alignSelf={'center'} justifyContent={'center'}>
                <Controller
                    name="is_approver"
                    control={control}
                    defaultValue={false} // Set the default value as needed
                    render={({ field }) => <FormControlLabel control={<Checkbox {...field} />} label="Is Approver" />}
                />
            </Grid>
        </Grid>
    );
};

export default EmploymentDataTab;
