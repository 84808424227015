import { ApiResponse } from 'interfaces/api-response';
import { getBaseHeaderApi } from './services/apiHelper';
import { adminApi, baseApi } from './services/services';
import { Employee } from 'interfaces/employee';
import { AuthorizeRequestBody, ChangePasswordRequestBody, LoginRequestBody } from './requests/loginRequest';

export const authApi = baseApi.enhanceEndpoints({ addTagTypes: ['myprofile'] }).injectEndpoints({
    endpoints(builder) {
        return {
            getProfile: builder.query<ApiResponse<Employee>, void>({
                query: () => ({
                    url: '/my_profile',
                    method: 'GET',
                    headers: getBaseHeaderApi()
                }),
                providesTags: ['myprofile']
            }),
            changePassword: builder.mutation({
                query: (data: ChangePasswordRequestBody) => ({
                    url: '/change_password',
                    method: 'POST',
                    headers: getBaseHeaderApi(),
                    data
                }),
                invalidatesTags: ['myprofile']
            }),
            avatar: builder.mutation<{ message: string }, FormData>({
                query: (data) => ({
                    url: '/avatar',
                    method: 'POST',
                    data: data
                }),
                invalidatesTags: ['myprofile']
            })
        };
    }
});

export const { useGetProfileQuery, useChangePasswordMutation, useAvatarMutation } = authApi;
