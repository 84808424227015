import { TextFields, TextFieldsSharp } from '@mui/icons-material';
import { TextField } from '@mui/material';
import React, { ReactNode } from 'react';

type Variant = 'filled' | 'outlined' | 'standard';

interface ISearchInputProps {
    label: string;
    placeHolder: string;
    onChange: (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
    startAdornment: ReactNode;
    variant: Variant;
}

const SearchInput = ({ label, placeHolder, onChange, startAdornment, variant }: Partial<ISearchInputProps>) => {
    return (
        <TextField
            size="small"
            sx={{ display: 'flex' }}
            onChange={onChange}
            label={label}
            placeholder={placeHolder}
            variant={variant}
            InputProps={{ startAdornment: startAdornment }}
            fullWidth
        />
    );
};

export default SearchInput;
