import { FormControl, FormHelperText, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import { FormInputProps, SelectOption } from 'interfaces/input';
import { useController } from 'react-hook-form';

interface SelectInputProps extends FormInputProps {
    options?: SelectOption[];
    required?: boolean;
}

export const SelectInput = ({ options, control, name, placeHolder, label, required }: SelectInputProps) => {
    const { field, fieldState } = useController({ name, control });
    const { error } = fieldState;
    return (
        <FormControl fullWidth error={Boolean(error)}>
            <Typography variant="caption" sx={{ paddingTop: '0', marginTop: '0' }}>
                {label}
                {required && <span style={{ color: 'red' }}> *</span>}
            </Typography>
            {/* <InputLabel id="demo-simple-select-label">{label}</InputLabel> */}
            <Select {...(placeHolder && { placeholder: placeHolder })} {...field}>
                {options?.map((value, i) => {
                    return (
                        <MenuItem key={i} value={value.value}>
                            {value.label}
                        </MenuItem>
                    );
                })}
            </Select>
            {Boolean(error) && <FormHelperText sx={{ color: 'red', mx: 0 }}>{error?.message}</FormHelperText>}
        </FormControl>
    );
};
