import { ApiResponse, ApiResponseWithPagination } from 'interfaces/api-response';
import { PaginateRequest } from 'interfaces/api-request';
import { CompanyId } from 'interfaces/company-id';
import { Department } from 'interfaces/department';
import { adminApi } from '../services/services';
import { getBaseHeaderApi, generateParamPaginate } from '../services/apiHelper';

export interface DepartmentRequestBody extends CompanyId {
    name?: string;
}

export const AdminDepartmentApi = adminApi.enhanceEndpoints({ addTagTypes: ['departments'] }).injectEndpoints({
    endpoints(builder) {
        return {
            getDepartments: builder.query<ApiResponse<Department[]>, void>({
                query: () => ({
                    url: '/department',
                    method: 'GET',
                    headers: getBaseHeaderApi()
                }),
                providesTags: ['departments']
            }),
            getDepartmentsPaginate: builder.query<ApiResponseWithPagination<Department[]>, PaginateRequest>({
                query: (request) => ({
                    url: `/department_paginate?${generateParamPaginate(request)}`,
                    method: 'GET',
                    headers: getBaseHeaderApi()
                }),
                providesTags: ['departments']
            }),
            createDepartment: builder.mutation({
                query: (data: DepartmentRequestBody) => ({
                    url: '/department',
                    method: 'POST',
                    headers: getBaseHeaderApi(),
                    data
                }),
                invalidatesTags: ['departments']
            }),
            updateDepartment: builder.mutation<any, { data: DepartmentRequestBody; id: string }>({
                query: ({ data, id }) => ({
                    url: `/department/${id}`,
                    method: 'PUT',
                    headers: getBaseHeaderApi(),
                    data
                }),
                invalidatesTags: ['departments']
            }),
            deleteDepartment: builder.mutation<any, { id: string }>({
                query: ({ id }) => ({
                    url: `/department/${id}`,
                    method: 'DELETE',
                    headers: getBaseHeaderApi()
                }),
                invalidatesTags: ['departments']
            })
        };
    }
});

export const {
    useGetDepartmentsQuery,
    useGetDepartmentsPaginateQuery,
    useCreateDepartmentMutation,
    useUpdateDepartmentMutation,
    useDeleteDepartmentMutation
} = AdminDepartmentApi;
