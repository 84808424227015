import { Search } from '@mui/icons-material';
import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TablePagination,
    TableRow,
    Typography,
    TableHead,
    TableSortLabel
} from '@mui/material';
import TablePaginationActions from '@mui/material/TablePagination/TablePaginationActions';
import { Box } from '@mui/system';
import SearchInput from 'components/input/SearchInput';
import SortFilterInput from 'components/input/SortFilterInput';
import TableLoader from 'components/loader/TableLoader';
import { SelectOption } from 'interfaces/input';
import { ReactNode, useState } from 'react';
import { visuallyHidden } from '@mui/utils';
import { Order } from 'interfaces/table';

interface HeadCell {
    id: string;
    label: string;
    isSortable: boolean;
}
interface IBaseTablePaginator {
    isLoading: boolean;
    // body: ReactNode;
    count: number;
    // countColumn: number;
    rowsPerPage: number;
    page: number;
    onPageChange: (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => void;
    onRowsPerPageChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    filterOption?: SelectOption[];
    onFilterChange?: (e: any) => void | undefined;
    onSearchchange: (e: string) => void;
    filterValue?: string;
    children?: ReactNode;
    totalData?: string | number;
    withTotalData?: boolean | false;
    searchPlaceHolder?: string;
    searchWidth?: number;

    headCells?: HeadCell[];
    order?: Order;
    orderBy?: string;
    onRequestSort: (id: string, order: Order) => void;
}

const BaseTablePaginator = ({
    // body,
    count,
    // countColumn,
    isLoading,
    rowsPerPage,
    page,
    onPageChange,
    onRowsPerPageChange,

    children,
    onSearchchange,
    searchPlaceHolder,
    searchWidth,
    headCells,
    order,
    orderBy,
    onRequestSort
}: IBaseTablePaginator) => {
    const createSortHandler = (property: string) => (event: React.MouseEvent<unknown>) => {
        if (orderBy == property) {
            onRequestSort(property, order == 'asc' ? 'desc' : 'asc');
        } else {
            onRequestSort(property, 'asc');
        }
    };
    return (
        <>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Box sx={{ width: searchWidth }}>
                    <SearchInput
                        placeHolder={searchPlaceHolder ? searchPlaceHolder : 'Search'}
                        startAdornment={<Search />}
                        onChange={(e) => {
                            onSearchchange(e.target.value);
                        }}
                    />
                </Box>
            </Box>
            <TableContainer component={Paper}>
                <Box sx={{ overflow: 'auto' }}>
                    <Box sx={{ width: '100%', display: 'table', tableLayout: 'fixed' }}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    {headCells!.map((headCell) => {
                                        return headCell.isSortable ? (
                                            <TableCell
                                                key={headCell.id}
                                                align={'left'}
                                                padding={'normal'}
                                                sortDirection={orderBy === headCell.id ? order : false}
                                            >
                                                <TableSortLabel
                                                    active={orderBy === headCell.id}
                                                    direction={orderBy === headCell.id ? order : 'asc'}
                                                    onClick={createSortHandler(headCell.id)}
                                                >
                                                    {headCell.label}
                                                    {orderBy === headCell.id ? (
                                                        <Box component="span" sx={visuallyHidden}>
                                                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                                        </Box>
                                                    ) : null}
                                                </TableSortLabel>
                                            </TableCell>
                                        ) : (
                                            <TableCell key={headCell.id}>{headCell.label} </TableCell>
                                        );
                                    })}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {isLoading ? (
                                    <TableRow key={'loading'} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell colSpan={headCells?.length} align="center">
                                            <TableLoader />
                                        </TableCell>
                                    </TableRow>
                                ) : (
                                    children
                                )}
                            </TableBody>
                            <TableFooter>
                                <TableRow>
                                    <TablePagination
                                        rowsPerPageOptions={[5, 10, 15, 25]}
                                        colSpan={headCells?.length}
                                        count={count}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        SelectProps={{
                                            inputProps: {
                                                'aria-label': 'rows per page'
                                            },
                                            native: true
                                        }}
                                        onPageChange={onPageChange}
                                        onRowsPerPageChange={onRowsPerPageChange}
                                        ActionsComponent={TablePaginationActions}
                                    />
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </Box>
                </Box>
            </TableContainer>
        </>
    );
};

export default BaseTablePaginator;
