import { CircularProgress } from '@mui/material';
import { Layout } from 'components/layout';
import { useGetEmployeeByIdQuery } from 'lib/api/admin/adminEmployeeApi';
import { useParams } from 'react-router';
import Cookies from 'universal-cookie';
import AdminEditEmployeePageTab from './AdminEditEmployeePageTab';

const cookies = new Cookies();

const AdminEditEmployeePage = () => {
    const { id } = useParams();
    const {
        isLoading: isGettingEmployee,
        error,
        data,
        isFetching
    } = useGetEmployeeByIdQuery({
        id: id ?? '-'
    });

    return (
        <Layout
            headerTitle="Edit Employee"
            pageTitle="Edit Employee"
            breadcrumbMiddleItems={[{ name: 'Employees', url: '/admin/employees' }]}
        >
            {isGettingEmployee ? <CircularProgress /> : <AdminEditEmployeePageTab dataEmployee={data?.data} />}
        </Layout>
    );
};

export default AdminEditEmployeePage;
