import { Employee } from 'interfaces/employee';
import { Grid, Typography, Card } from '@mui/material';
import { dateToStringFullWIB } from 'lib/helpers/dateHelper';

const DataKeluarga = ({ userData }: { userData: Employee }) => {
    return (
        <Card sx={{ padding: 10, marginTop: 10 }}>
            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                Kontak Darurat
            </Typography>
            <Grid container spacing={5} alignItems="flex-end" justifyContent="space-between">
                <Grid item xs={11} md={4} lg={4}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                        Hubungan
                    </Typography>
                </Grid>
                <Grid item xs={1} md={1} lg={1}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                        :
                    </Typography>
                </Grid>
                <Grid item xs={12} md={7} lg={7}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                        {userData.employee_detail?.darurat_hubungan}
                    </Typography>
                </Grid>
            </Grid>
            <Grid container spacing={5} alignItems="flex-end" justifyContent="space-between">
                <Grid item xs={11} md={4} lg={4}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                        Nama
                    </Typography>
                </Grid>
                <Grid item xs={1} md={1} lg={1}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                        :
                    </Typography>
                </Grid>
                <Grid item xs={12} md={7} lg={7}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                        {userData.employee_detail?.darurat_name}
                    </Typography>
                </Grid>
            </Grid>
            <Grid container spacing={5} alignItems="flex-end" justifyContent="space-between">
                <Grid item xs={11} md={4} lg={4}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                        Alamat
                    </Typography>
                </Grid>
                <Grid item xs={1} md={1} lg={1}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                        :
                    </Typography>
                </Grid>
                <Grid item xs={12} md={7} lg={7}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                        {userData.employee_detail?.darurat_address}
                    </Typography>
                </Grid>
            </Grid>
            <Grid container spacing={5} alignItems="flex-end" justifyContent="space-between">
                <Grid item xs={11} md={4} lg={4}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                        No Telepon/HP
                    </Typography>
                </Grid>
                <Grid item xs={1} md={1} lg={1}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                        :
                    </Typography>
                </Grid>
                <Grid item xs={12} md={7} lg={7}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                        {userData.employee_detail?.darurat_phone_number}
                    </Typography>
                </Grid>
            </Grid>
            {userData.tanggungan?.map((item, index) => {
                return (
                    <div key={index}>
                        <Typography variant="h6" sx={{ fontWeight: 'bold', marginTop: 10 }}>
                            Tanggungan Ke {index + 1}
                        </Typography>
                        <Grid container spacing={5} alignItems="flex-end" justifyContent="space-between">
                            <Grid item xs={11} md={4} lg={4}>
                                <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                                    Status Tanggungan
                                </Typography>
                            </Grid>
                            <Grid item xs={1} md={1} lg={1}>
                                <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                                    :
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={7} lg={7}>
                                <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                                    {item.tanggungan}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container spacing={5} alignItems="flex-end" justifyContent="space-between">
                            <Grid item xs={11} md={4} lg={4}>
                                <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                                    Nama
                                </Typography>
                            </Grid>
                            <Grid item xs={1} md={1} lg={1}>
                                <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                                    :
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={7} lg={7}>
                                <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                                    {item.name}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container spacing={5} alignItems="flex-end" justifyContent="space-between">
                            <Grid item xs={11} md={4} lg={4}>
                                <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                                    Tempat Lahir
                                </Typography>
                            </Grid>
                            <Grid item xs={1} md={1} lg={1}>
                                <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                                    :
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={7} lg={7}>
                                <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                                    {item.birth_place}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container spacing={5} alignItems="flex-end" justifyContent="space-between">
                            <Grid item xs={11} md={4} lg={4}>
                                <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                                    Tanggal Lahir
                                </Typography>
                            </Grid>
                            <Grid item xs={1} md={1} lg={1}>
                                <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                                    :
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={7} lg={7}>
                                <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                                    {dateToStringFullWIB({ date: item.birth_date as unknown as Date })}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container spacing={5} alignItems="flex-end" justifyContent="space-between">
                            <Grid item xs={11} md={4} lg={4}>
                                <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                                    Pendidikan Terakhir
                                </Typography>
                            </Grid>
                            <Grid item xs={1} md={1} lg={1}>
                                <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                                    :
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={7} lg={7}>
                                <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                                    {item.last_education}
                                </Typography>
                            </Grid>
                        </Grid>
                    </div>
                );
            })}
        </Card>
    );
};

export default DataKeluarga;
