import { Role } from 'interfaces/role';
import { CreateEmploymentData } from 'interfaces/adminSetting';
import { adminApi } from '../services/services';
import { ApiResponse } from 'interfaces/api-response';
import { getBaseHeaderApi } from '../services/apiHelper';

export const adminSettingApi = adminApi.injectEndpoints({
    endpoints(builder) {
        return {
            getCreateEmploymentData: builder.query<ApiResponse<CreateEmploymentData>, void>({
                query: () => ({
                    url: '/setting/createEmploymentData',
                    method: 'GET',
                    headers: getBaseHeaderApi()
                })
            })
        };
    }
});

export const { useGetCreateEmploymentDataQuery } = adminSettingApi;
