import * as React from 'react';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import { Close } from '@mui/icons-material';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useGetAdminDashboardCardClockInQuery } from 'lib/api/admin/adminDashboardApi';
import TableLoader from 'components/loader/TableLoader';
import { dateToStringWIB, getMinOffsetFromJakartaToLocale } from 'lib/helpers/dateHelper';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4
};

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2)
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1)
    }
}));

function BootstrapDialogTitle(props: any) {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 10 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500]
                    }}
                >
                    <Close />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
}

const ModalClockIn = ({ handleClose, open }: any) => {
    const { isLoading, data: dataCard } = useGetAdminDashboardCardClockInQuery();
    return (
        <div>
            <BootstrapDialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} fullWidth={true} maxWidth={'md'}>
                <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
                    <h3>Daftar Employee Clock In </h3>
                </BootstrapDialogTitle>
                <DialogContent dividers>
                    {isLoading ? (
                        <TableLoader />
                    ) : (
                        <TableContainer>
                            <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Name</TableCell>
                                        <TableCell>Jam ClockIn</TableCell>
                                        <TableCell>Lokasi</TableCell>
                                        <TableCell>Pekerjaan</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {dataCard &&
                                        dataCard.data.map((row: any, index: number) => (
                                            <TableRow
                                                key={index}
                                                sx={{
                                                    '&:last-child td, &:last-child th': { border: 0 }
                                                }}
                                            >
                                                <TableCell component="th" scope="row">
                                                    {row.name}
                                                </TableCell>
                                                <TableCell> {dateToStringWIB({ date: row.date_time_check_in, withTime: true })}</TableCell>
                                                <TableCell>{row.lokasi}</TableCell>
                                                <TableCell>{row.pekerjaan}</TableCell>
                                            </TableRow>
                                        ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    )}
                </DialogContent>
            </BootstrapDialog>
        </div>
    );
};

export default ModalClockIn;
