import { Dialog, DialogContent, CircularProgress, DialogTitle, DialogActions, Button, Box } from '@mui/material';
import { FormInput } from 'components/input/hook-form';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { decodeHashId } from 'lib/helpers/hasher';
import Cookies from 'universal-cookie';
import config from 'config';
import { WorkCategoryRequestBody } from 'lib/api/admin/adminWorkCategoryApi';
import { SubCompany } from 'interfaces/sub-company';
import { SubCompanyRequestBody, useUpdateSubCompanyMutation } from 'lib/api/admin/adminSubCompanyApi';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';

interface IEditSubCompanyDialogProps {
    open: boolean;
    handleClose: () => void;
    subCompany: SubCompany;
}

const EditSubCompanyDialog = ({ open, handleClose, subCompany }: IEditSubCompanyDialogProps) => {
    useEffect(() => {
        setValue('name', subCompany.name);
    }, [subCompany]);

    const cookies = new Cookies();
    const [updateSubCompany] = useUpdateSubCompanyMutation();
    const initialFormData: SubCompanyRequestBody = {
        name: '',
        company_id: +decodeHashId(cookies.get(config.cookieCompanyId))
    };
    const { handleSubmit, control, reset, setValue } = useForm({
        defaultValues: initialFormData,
        mode: 'onSubmit',
        resolver: yupResolver(
            yup
                .object({
                    name: yup.string().required('*Name is required')
                })
                .required()
        )
    });
    const onSubmit = async (data: WorkCategoryRequestBody) => {
        setLoading(true);
        await updateSubCompany({ data: data, id: subCompany.id ?? '' })
            .unwrap()
            .then((e) => {
                setLoading(false);
                withReactContent(Swal).fire('Sukses', 'Berhasil mengubah data!', 'success');
            })
            .catch((error) => {});
        reset.apply(initialFormData);
        setLoading(false);
        handleClosee();
    };

    const [isLoading, setLoading] = useState(false);
    const handleClosee = (event?: any, reason?: any) => {
        if (!isLoading) {
            handleClose();
        }
        if (reason && reason == 'backdropClick') return;
        handleClose();
    };
    return (
        <Dialog open={open} onClose={handleClosee}>
            <form onSubmit={handleSubmit(onSubmit)}>
                {isLoading ? (
                    <DialogContent>
                        <CircularProgress />
                    </DialogContent>
                ) : (
                    <div>
                        <DialogTitle variant="h3">Edit Sub Company</DialogTitle>
                        <DialogContent>
                            <Box sx={{ mt: 20 }}>
                                <FormInput control={control} label="Name" type="text" name="name" />
                            </Box>
                        </DialogContent>
                        <DialogActions>
                            <Button size="large" type="submit">
                                Update
                            </Button>
                        </DialogActions>
                    </div>
                )}
            </form>
        </Dialog>
    );
};

export default EditSubCompanyDialog;
