import { Dialog, DialogContent, CircularProgress, DialogTitle, Typography, DialogActions, Button, Box } from '@mui/material';
import { FormInput } from 'components/input/hook-form';
import { WorkCategoryType } from 'interfaces/work-category-type';
import React, { useEffect, useState } from 'react';
import { ActionMeta, InputActionMeta } from 'react-select';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { decodeHashId } from 'lib/helpers/hasher';
import Cookies from 'universal-cookie';
import config from 'config';
import Select from 'react-select';
import { useCreateWorkCategoryMutation, useUpdateWorkCategoryMutation } from 'lib/api/admin/adminWorkCategoryApi';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import { WorkCategory } from 'interfaces/work-category';
import { useUpdateEmployeeMutation } from 'lib/api/admin/adminEmployeeApi';
import { BaseForm } from 'components/input/hook-form/BaseForm';
import { Leave } from 'interfaces/leave';
import { GiveFeedbackApprovalRequestBody, LeaveRequestBody, useGiveFeedbackApprovalMutation } from 'lib/api/leaveApi';
import { useTheme } from '@mui/material/styles';

interface IGiveFeedbackApprovalDialogProps {
    open: boolean;
    handleClose: () => void;
    leave: Leave;
    approverNumber: number;
}

const GiveFeedbackApprovalDialog = ({ open, handleClose, leave, approverNumber }: IGiveFeedbackApprovalDialogProps) => {
    useEffect(() => {
        setValue('approver_number', approverNumber.toString());
    }, [leave]);

    const cookies = new Cookies();
    const [giveFeedback] = useGiveFeedbackApprovalMutation();
    const initialFormData: GiveFeedbackApprovalRequestBody = {
        note: '',
        type: '',
        approver_number: ''
    };
    const { handleSubmit, control, reset, setValue } = useForm({
        defaultValues: initialFormData,
        mode: 'onSubmit',
        resolver: yupResolver(
            yup
                .object({
                    // employeeId: yup.number().required('*Email is required'),
                    note: yup.string().required(),
                    type: yup.string().required(),
                    approver_number: yup.string().required()
                })
                .required()
        )
    });
    const onSubmit = async (data: GiveFeedbackApprovalRequestBody) => {
        setLoading(true);
        await giveFeedback({ data: data, id: leave.id ?? '' })
            .unwrap()
            .then((e) => {
                setLoading(false);
                withReactContent(Swal).fire('Sukses', 'Berhasil mengubah data!', 'success');
            })
            .catch((error) => {});
        reset.apply(initialFormData);
        setLoading(false);
        handleClosee();
    };
    const theme = useTheme();

    const [isLoading, setLoading] = useState(false);
    const handleClosee = (event?: any, reason?: any) => {
        if (!isLoading) {
            handleClose();
        }
        if (reason && reason == 'backdropClick') return;
        handleClose();
    };
    return (
        <Dialog open={open} onClose={handleClosee} fullWidth maxWidth={'md'}>
            <form onSubmit={handleSubmit(onSubmit)}>
                {isLoading ? (
                    <DialogContent>
                        <CircularProgress />
                    </DialogContent>
                ) : (
                    <div>
                        <DialogTitle variant="h3">Give Feedback Approval</DialogTitle>
                        <DialogContent>
                            <Box sx={{ mb: 20, mt: 20 }}>
                                <FormInput
                                    required
                                    minRows={4}
                                    control={control}
                                    label="Note"
                                    type="text"
                                    name="note"
                                    multiline
                                    maxRows={3}
                                />
                            </Box>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleClose} style={{ color: 'GrayText' }}>
                                Cancel
                            </Button>
                            <Button
                                size="large"
                                type="submit"
                                color="error"
                                onClick={() => {
                                    setValue('type', 'R');
                                }}
                            >
                                Reject
                            </Button>
                            <Button
                                size="large"
                                type="submit"
                                color="success"
                                onClick={() => {
                                    setValue('type', 'A');
                                }}
                            >
                                Approve
                            </Button>
                        </DialogActions>
                    </div>
                )}
            </form>
        </Dialog>
    );
};

export default GiveFeedbackApprovalDialog;
