import dashboard from './dashboard';
import pages from './pages';
import utilities from './utilities';
import other from './other';
import employementFeatures from './employementFeatures';
import settingFeatures from './settingFeatures';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
    items: [dashboard, employementFeatures, settingFeatures]
};

export default menuItems;
