import { Box } from '@mui/material';
import React from 'react';
import { MagnifyingGlass } from 'react-loader-spinner';

type Props = {};

const TableLoader = (props: Props) => {
    return (
        <Box sx={{ display: 'flex', width: '100%', height: '100%', justifyContent: 'center', alignItems: 'center' }}>
            <MagnifyingGlass
                visible={true}
                height="100"
                width="100"
                ariaLabel="MagnifyingGlass-loading"
                wrapperStyle={{}}
                wrapperClass="MagnifyingGlass-wrapper"
                glassColor="#c0efff"
                color="#e15b64"
            />
        </Box>
    );
};

export default TableLoader;
