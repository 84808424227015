import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// material-ui
import { ButtonBase } from '@mui/material';

// project imports
import config from 'config';
// @ts-ignore
import logo from 'assets/logos/hr-logo.png';

// ==============================|| MAIN LOGO ||============================== //

const LogoSection = () => {
    return (
        <ButtonBase disableRipple component={Link} to={config.defaultPath}>
            <img src={logo} alt="Logo" style={{ paddingLeft: 20, width: 170 }} />
        </ButtonBase>
    );
};

export default LogoSection;
