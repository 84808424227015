import { createApi, setupListeners } from '@reduxjs/toolkit/query/react';
import axiosBaseQuery from './axiosBaseQuery';
import { validateResponse } from './apiHelper';
import { configureStore } from '@reduxjs/toolkit';

export const baseApi = createApi({
    reducerPath: 'api',
    baseQuery: axiosBaseQuery({
        transformResponse: (response) => {
            validateResponse(response);
            return response;
        }
    }),
    endpoints: (builder) => ({})
});

export const adminApi = createApi({
    reducerPath: 'api-admin',
    baseQuery: axiosBaseQuery({
        transformResponse: (response) => {
            validateResponse(response, '/admin/login');
            return response;
        },
        redirectUnauthorizedPath: '/admin/login'
    }),
    endpoints: (builder) => ({})
});
