import { yupResolver } from '@hookform/resolvers/yup';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { Alert, Box, Button, Divider, Grid, IconButton, InputAdornment, Snackbar, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useState } from 'react';
// @ts-ignore
import Google from 'assets/images/icons/social-google.svg';
import { FormInput } from 'components/input/hook-form';
import ButtonLoader from 'components/loader/ButtonLoader';
import { dataCrypt, verifier } from 'lib/helpers/createRandomString';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router';
import Cookies from 'universal-cookie';
import * as yup from 'yup';
import { createHashId } from 'lib/helpers/hasher';
import config from 'config';
import { useAuthorizeMutation, useLoginMutation } from 'lib/api/authApi';
import { setToken } from 'lib/helpers/tokenHandler';
import { User } from '../../../interfaces/user';
import { useAdminAuthorizeMutation, useAdminLoginMutation } from 'lib/api/admin/adminAuthApi';

const defaultValues = {
    email: '',
    password: ''
};

const schema = yup
    .object({
        email: yup.string().email().required('*Email is required'),
        password: yup.string().required('*Password is required')
    })
    .required();

const UserFormLogin = () => {
    const fetchData = async () => {};
    // const { refetch } = useGetProfileQuery((function () {})(), { skip: true });
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const { handleSubmit, control } = useForm({
        defaultValues,
        mode: 'onSubmit',
        resolver: yupResolver(schema)
    });
    // const [checked, setChecked] = useState(true);
    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleCloseSnackBar = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setError('');
    };

    const cookies = new Cookies();
    const navigate = useNavigate();
    const location = useLocation();

    const [login] = useAdminLoginMutation();
    const [authorize] = useAdminAuthorizeMutation();
    const onSubmit = (data: any) => {
        data.state = dataCrypt.state;
        data.challenge = dataCrypt.challenge;
        setLoading(true);
        login(data)
            .unwrap()
            .then(async (res) => {
                if (data.state === res.data.state) {
                    const dataAuth = {
                        verifier: verifier,
                        code: res.data.code
                    };
                    authorize(dataAuth)
                        .unwrap()
                        .then((data: any) => {
                            setToken(
                                data.data.access_token,
                                data.data.user.company_id.toString(),
                                data.data.user.name,
                                data.data.user.id.toString(),
                                data.data.expires_in,
                                JSON.stringify(data.data.user)
                            );
                            if (location.state) {
                                navigate(location.state);
                            } else {
                                navigate('/admin/');
                            }
                            setLoading(false);
                        })
                        .catch((error) => {
                            console.log(error);
                            if (error.data) {
                                console.log(error);
                                setError(`Code ${error.status} : ${error}`);
                            } else {
                                console.log(error.data);
                                setError(error.data);
                            }
                            setLoading(false);
                        });
                }
            })
            .catch((error) => {
                console.log(error);
                if (error) {
                    console.log(error);
                    setError(`Code ${error.status} : ${error}`);
                } else {
                    console.log(error.data);
                    setError(error.data);
                }
                setLoading(false);
            });
    };
    // const onSubmit = async (data: any) => {
    //     data.state = dataCrypt.state;
    //     data.challenge = dataCrypt.challenge;
    //     setLoading(true);
    //     try {
    //         await apiLogin(data).then(async (res) => {
    //             if (data.state === res.data.state) {
    //                 const dataAuth = {
    //                     verifier: verifier,
    //                     code: res.data.code
    //                 };
    //                 apiAuthorize(dataAuth)
    //                     .then((data) => {
    //                         cookies.set(config.cookieToken, createHashId(data.data.access_token), {
    //                             maxAge: data.data.expires_in,
    //                             secure: true,
    //                             sameSite: 'none',
    //                             path: '/'
    //                         });
    //                         cookies.set(config.cookieCompanyId, createHashId(data.data.user.company_id.toString()), {
    //                             maxAge: data.data.expires_in,
    //                             secure: true,
    //                             sameSite: 'none',
    //                             path: '/'
    //                         });
    //                         cookies.set(config.cookieUserName, createHashId(data.data.user.first_name), {
    //                             maxAge: data.data.expires_in,
    //                             secure: true,
    //                             sameSite: 'none',
    //                             path: '/'
    //                         });
    //                         cookies.set(config.cookieUserID, createHashId(data.data.user.id.toString()), {
    //                             maxAge: data.data.expires_in,
    //                             secure: true,
    //                             sameSite: 'none',
    //                             path: '/'
    //                         });
    //                         // setLoading(false);
    //                         if (location.state) {
    //                             navigate(location.state);
    //                         } else {
    //                             navigate('/admin');
    //                         }
    //                         setLoading(false);
    //                     })
    //                     .catch((error) => {
    //                         if (error.data) {
    //                             setError(`Code ${error.status} : ${error.data.message}`);
    //                         } else {
    //                             setError(error.data);
    //                         }
    //                         setLoading(false);
    //                     });
    //             }
    //         });
    //     } catch (error) {
    //         setLoading(false);
    //     } finally {
    //         // setLoading(false);
    //     }
    // };

    return (
        <>
            <Grid container direction="column" justifyContent="center" spacing={2}>
                {/* <Grid item xs={12} sx={{ mb: 20 }}>
                    <Button
                        disableElevation
                        fullWidth
                        size="large"
                        variant="outlined"
                        sx={{
                            color: 'grey.700',
                            backgroundColor: theme.palette.grey[50],
                            borderColor: theme.palette.grey[100]
                        }}
                    >
                        <Box sx={{ mr: { xs: 10, sm: 20, width: 20 } }}>
                            <img src={Google} alt="google" width={16} height={16} style={{ marginRight: 8 }} />
                        </Box>
                        Sign in with Google
                    </Button>
                </Grid> */}
                {/* <Grid item xs={12}>
                    <Box
                        sx={{
                            alignItems: 'center',
                            display: 'flex'
                        }}
                    >
                        <Divider sx={{ flexGrow: 1 }} orientation="horizontal" />

                        <Button
                            variant="outlined"
                            sx={{
                                cursor: 'unset',
                                m: 2,
                                py: 0.5,
                                px: 7,
                                borderColor: `${theme.palette.grey[100]} !important`,
                                color: `${theme.palette.grey[900]}!important`,
                                fontWeight: 500
                            }}
                            disableRipple
                            disabled
                        >
                            OR
                        </Button>

                        <Divider sx={{ flexGrow: 1 }} orientation="horizontal" />
                    </Box>
                </Grid> */}
                <Grid item xs={12} container alignItems="center" justifyContent="center">
                    <Box>
                        <Typography variant="h3">Admin Side</Typography>
                    </Box>
                </Grid>
                <Grid item xs={12} container alignItems="center" justifyContent="center">
                    <Box sx={{ mb: 20 }}>
                        <Typography variant="subtitle1">Masuk dengan Alamat Email</Typography>
                    </Box>
                </Grid>
            </Grid>

            <form onSubmit={handleSubmit(onSubmit)}>
                <Box sx={{ mb: 20 }}>
                    <FormInput control={control} label="Email" name="email" />
                </Box>
                <Box sx={{ mb: 20 }}>
                    <FormInput
                        control={control}
                        label="Password"
                        name="password"
                        type={showPassword ? 'text' : 'password'}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    edge="end"
                                    size="large"
                                >
                                    {showPassword ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                </Box>

                <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1}>
                    {/* <FormControlLabel
                        control={
                            <Checkbox
                                checked={checked}
                                onChange={(event) => setChecked(event.target.checked)}
                                name="checked"
                                color="primary"
                            />
                        }
                        label="Remember me"
                    /> */}
                    <Typography variant="subtitle1" color="secondary" sx={{ textDecoration: 'none', cursor: 'pointer' }}>
                        Lupa Password?
                    </Typography>
                </Stack>

                <Box sx={{ mt: 2 }}>
                    {loading ? (
                        <ButtonLoader />
                    ) : (
                        <Button disableElevation fullWidth size="large" type="submit" variant="contained" color="secondary">
                            Masuk
                        </Button>
                    )}
                </Box>
            </form>
            <Snackbar open={error !== ''} autoHideDuration={3000} onClose={handleCloseSnackBar}>
                <Alert onClose={handleCloseSnackBar} severity="error" sx={{ width: '100%' }}>
                    {error ? error : 'Terjadi Kesalahan'}
                </Alert>
            </Snackbar>
        </>
    );
};

export default UserFormLogin;
