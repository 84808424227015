import { lazy } from 'react';

// project imports
import Loadable from 'ui-component/Loadable';
import AttendanceAdminPage from 'modules/admin/attendance/pages/AttendanceAdminPage';
import AdminWorkCategoryPage from 'modules/admin/work_category/pages/AdminWorkCategoryPage';
import AdminCostCenterPage from 'modules/admin/cost_center/pages/AdminCostCenterPage';
import AdminSubCompanyPage from 'modules/admin/sub_company/pages/AdminSubCompanyPage';
import AdminDepartmentPage from 'modules/admin/department/pages/AdminDepartmentPage';
import AdminDashboardPage from 'modules/admin/dashboard/pages/adminDashboardPage';
import LoginPage from 'modules/auth/pages/LoginPage';
import UserLoginPage from 'modules/auth/pages/UserLoginPage';
import UserMainLayout from 'layout/UserMainLayout';
import AdminEmployeePage from 'modules/admin/employee/pages/AdminEmployeePage';
import AdminCreateEmployeePage from 'modules/admin/employee/pages/AdminCreateEmployeePage';
import AdminEditEmployeePage from 'modules/admin/employee/pages/AdminEditEmployeePage';
import AdminLeaveFlow from 'modules/admin/leave_flow/pages/AdminLeaveFlowPage';
import AdminLeavePage from 'modules/admin/leave/pages/AdminLeavePage';

// login option 3 routing
const DashboardPage = Loadable(lazy(() => import('modules/dashboard/pages/DashboardPage')));
const CompanyAssetPage = Loadable(lazy(() => import('modules/company-asset/pages/CompanyAssetPage')));
const LatePage = Loadable(lazy(() => import('modules/late/pages/LatePage')));
const CreateLatePage = Loadable(lazy(() => import('modules/late/pages/CreateLatePage')));
const DivisionPage = Loadable(lazy(() => import('modules/division/pages/DivisionPage')));
const BankPage = Loadable(lazy(() => import('modules/bank/pages/BankPage')));
const TitlePage = Loadable(lazy(() => import('modules/title/pages/TitlePage')));
// ==============================|| AUTHENTICATION ROUTING ||============================== //

const AdminRoutes = {
    path: 'admin',
    element: <UserMainLayout />,
    children: [
        {
            path: '',
            element: <AdminDashboardPage />
        },
        {
            path: 'employees',
            children: [
                {
                    path: '',
                    element: <AdminEmployeePage />
                },
                {
                    path: 'create',
                    element: <AdminCreateEmployeePage />
                },
                {
                    path: 'edit/:id',
                    element: <AdminEditEmployeePage />
                }
            ]
        },
        {
            path: 'company-asset',
            element: <CompanyAssetPage />
        },
        {
            path: 'attendances',
            element: <AttendanceAdminPage />
        },
        {
            path: 'work_categories',
            element: <AdminWorkCategoryPage />
        },
        {
            path: 'cost_center',
            element: <AdminCostCenterPage />
        },
        {
            path: 'sub_company',
            element: <AdminSubCompanyPage />
        },
        {
            path: 'department',
            element: <AdminDepartmentPage />
        },
        {
            path: 'late',
            element: <LatePage />
        },
        {
            path: 'late/create',
            element: <CreateLatePage />
        },
        {
            path: 'division',
            element: <DivisionPage />
        },
        {
            path: 'bank',
            element: <BankPage />
        },
        {
            path: 'title',
            element: <TitlePage />
        },
        {
            path: 'leave_flow',
            children: [
                {
                    path: '',
                    element: <AdminLeaveFlow />
                }
            ]
        },
        {
            path: 'leave',
            children: [
                {
                    path: '',
                    element: <AdminLeavePage />
                }
            ]
        }
    ]
};

export default AdminRoutes;
