import { Employee } from 'interfaces/employee';
import { Grid, Typography, Card } from '@mui/material';
import { dateToStringFullWIB } from 'lib/helpers/dateHelper';

const DataGaji = ({ userData }: { userData: Employee }) => {
    return (
        <Card sx={{ padding: 10, marginTop: 10 }}>
            <Grid container spacing={5} alignItems="flex-end" justifyContent="space-between">
                <Grid item xs={11} md={4} lg={4}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                        Salary
                    </Typography>
                </Grid>
                <Grid item xs={1} md={1} lg={1}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                        :
                    </Typography>
                </Grid>
                <Grid item xs={12} md={7} lg={7}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                        {userData.salary}
                    </Typography>
                </Grid>
            </Grid>
            <Grid container spacing={5} alignItems="flex-end" justifyContent="space-between">
                <Grid item xs={11} md={4} lg={4}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                        Salary Type
                    </Typography>
                </Grid>
                <Grid item xs={1} md={1} lg={1}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                        :
                    </Typography>
                </Grid>
                <Grid item xs={12} md={7} lg={7}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                        {userData.salary_type}
                    </Typography>
                </Grid>
            </Grid>
            <Grid container spacing={5} alignItems="flex-end" justifyContent="space-between">
                <Grid item xs={11} md={4} lg={4}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                        Bank
                    </Typography>
                </Grid>
                <Grid item xs={1} md={1} lg={1}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                        :
                    </Typography>
                </Grid>
                <Grid item xs={12} md={7} lg={7}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                        {userData.bank?.name}
                    </Typography>
                </Grid>
            </Grid>
            <Grid container spacing={5} alignItems="flex-end" justifyContent="space-between">
                <Grid item xs={11} md={4} lg={4}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                        Bank Account Number
                    </Typography>
                </Grid>
                <Grid item xs={1} md={1} lg={1}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                        :
                    </Typography>
                </Grid>
                <Grid item xs={12} md={7} lg={7}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                        {userData.bank_account_number}
                    </Typography>
                </Grid>
            </Grid>
            <Grid container spacing={5} alignItems="flex-end" justifyContent="space-between">
                <Grid item xs={11} md={4} lg={4}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                        Bank Account Name
                    </Typography>
                </Grid>
                <Grid item xs={1} md={1} lg={1}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                        :
                    </Typography>
                </Grid>
                <Grid item xs={12} md={7} lg={7}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                        {userData.bank_account_name}
                    </Typography>
                </Grid>
            </Grid>
            <Grid container spacing={5} alignItems="flex-end" justifyContent="space-between">
                <Grid item xs={11} md={4} lg={4}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                        NPWP Number
                    </Typography>
                </Grid>
                <Grid item xs={1} md={1} lg={1}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                        :
                    </Typography>
                </Grid>
                <Grid item xs={12} md={7} lg={7}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                        {userData.npwp}
                    </Typography>
                </Grid>
            </Grid>
            <Grid container spacing={5} alignItems="flex-end" justifyContent="space-between">
                <Grid item xs={11} md={4} lg={4}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                        PTKP Status
                    </Typography>
                </Grid>
                <Grid item xs={1} md={1} lg={1}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                        :
                    </Typography>
                </Grid>
                <Grid item xs={12} md={7} lg={7}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                        {userData.ptkp}
                    </Typography>
                </Grid>
            </Grid>
            <Grid container spacing={5} alignItems="flex-end" justifyContent="space-between">
                <Grid item xs={11} md={4} lg={4}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                        Tax Method
                    </Typography>
                </Grid>
                <Grid item xs={1} md={1} lg={1}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                        :
                    </Typography>
                </Grid>
                <Grid item xs={12} md={7} lg={7}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                        {userData.tax_method}
                    </Typography>
                </Grid>
            </Grid>
            <Grid container spacing={5} alignItems="flex-end" justifyContent="space-between">
                <Grid item xs={11} md={4} lg={4}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                        Tax Salary
                    </Typography>
                </Grid>
                <Grid item xs={1} md={1} lg={1}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                        :
                    </Typography>
                </Grid>
                <Grid item xs={12} md={7} lg={7}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                        {userData.tax_salary}
                    </Typography>
                </Grid>
            </Grid>
            <Grid container spacing={5} alignItems="flex-end" justifyContent="space-between">
                <Grid item xs={11} md={4} lg={4}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                        Taxable Date
                    </Typography>
                </Grid>
                <Grid item xs={1} md={1} lg={1}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                        :
                    </Typography>
                </Grid>
                <Grid item xs={12} md={7} lg={7}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                        {dateToStringFullWIB({ date: userData.tax_date })}
                    </Typography>
                </Grid>
            </Grid>
            <Grid container spacing={5} alignItems="flex-end" justifyContent="space-between">
                <Grid item xs={11} md={4} lg={4}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                        Employment Tax Status
                    </Typography>
                </Grid>
                <Grid item xs={1} md={1} lg={1}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                        :
                    </Typography>
                </Grid>
                <Grid item xs={12} md={7} lg={7}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                        {userData.tax_status}
                    </Typography>
                </Grid>
            </Grid>
            <Grid container spacing={5} alignItems="flex-end" justifyContent="space-between">
                <Grid item xs={11} md={4} lg={4}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                        BPJS Ketenagakerjaan Number
                    </Typography>
                </Grid>
                <Grid item xs={1} md={1} lg={1}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                        :
                    </Typography>
                </Grid>
                <Grid item xs={12} md={7} lg={7}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                        {userData.bpjs_ktn_number}
                    </Typography>
                </Grid>
            </Grid>
            <Grid container spacing={5} alignItems="flex-end" justifyContent="space-between">
                <Grid item xs={11} md={4} lg={4}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                        BPJS Ketenagakerjaan Date
                    </Typography>
                </Grid>
                <Grid item xs={1} md={1} lg={1}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                        :
                    </Typography>
                </Grid>
                <Grid item xs={12} md={7} lg={7}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                        {dateToStringFullWIB({ date: userData.bpjs_ktn_date })}
                    </Typography>
                </Grid>
            </Grid>
            <Grid container spacing={5} alignItems="flex-end" justifyContent="space-between">
                <Grid item xs={11} md={4} lg={4}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                        BPJS Kesehatan Number
                    </Typography>
                </Grid>
                <Grid item xs={1} md={1} lg={1}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                        :
                    </Typography>
                </Grid>
                <Grid item xs={12} md={7} lg={7}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                        {userData.bpjs_ks_number}
                    </Typography>
                </Grid>
            </Grid>
            <Grid container spacing={5} alignItems="flex-end" justifyContent="space-between">
                <Grid item xs={11} md={4} lg={4}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                        BPJS Kesehatan Date
                    </Typography>
                </Grid>
                <Grid item xs={1} md={1} lg={1}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                        :
                    </Typography>
                </Grid>
                <Grid item xs={12} md={7} lg={7}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                        {dateToStringFullWIB({ date: userData.bpjs_ks_date })}
                    </Typography>
                </Grid>
            </Grid>
            <Grid container spacing={5} alignItems="flex-end" justifyContent="space-between">
                <Grid item xs={11} md={4} lg={4}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                        BPJS Kesehatan Cost
                    </Typography>
                </Grid>
                <Grid item xs={1} md={1} lg={1}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                        :
                    </Typography>
                </Grid>
                <Grid item xs={12} md={7} lg={7}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                        {userData.bpjs_ks_cost}
                    </Typography>
                </Grid>
            </Grid>
            <Grid container spacing={5} alignItems="flex-end" justifyContent="space-between">
                <Grid item xs={11} md={4} lg={4}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                        Retired Guarantee Cost
                    </Typography>
                </Grid>
                <Grid item xs={1} md={1} lg={1}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                        :
                    </Typography>
                </Grid>
                <Grid item xs={12} md={7} lg={7}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                        {userData.retired_type}
                    </Typography>
                </Grid>
            </Grid>
            <Grid container spacing={5} alignItems="flex-end" justifyContent="space-between">
                <Grid item xs={11} md={4} lg={4}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                        Guarantee Retired Date
                    </Typography>
                </Grid>
                <Grid item xs={1} md={1} lg={1}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                        :
                    </Typography>
                </Grid>
                <Grid item xs={12} md={7} lg={7}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                        {dateToStringFullWIB({ date: userData.retired_date })}
                    </Typography>
                </Grid>
            </Grid>
        </Card>
    );
};

export default DataGaji;
