import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Grid, Typography } from '@mui/material';
import { dateToStringWIB } from 'lib/helpers/dateHelper';
import { leaveStatusToColor, leaveStatusToText } from 'lib/helpers/leaveStatusHelper';

interface IDetailApprovalDialogProps {
    approverData?: { title: string; status: string; date: Date; note: string };
    handleClose: () => void;
    open: boolean;
}

const DetailApprovalDialog = ({ approverData, handleClose, open }: IDetailApprovalDialogProps) => {
    const handleClosee = (event?: any, reason?: any) => {
        handleClose();
    };

    return (
        <Dialog open={open} onClose={handleClosee}>
            <DialogTitle variant="h3">{approverData?.title}</DialogTitle>
            <DialogContent>
                <Grid container>
                    <Grid item xs={3}>
                        <Typography>Status: </Typography>
                    </Grid>
                    <Grid item xs={9}>
                        <Typography color={leaveStatusToColor(approverData?.status)}>{leaveStatusToText(approverData?.status)}</Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography>Note: </Typography>
                    </Grid>
                    <Grid item xs={9}>
                        <Typography>{approverData?.note}</Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography>Updated At: </Typography>
                    </Grid>
                    <Grid item xs={9}>
                        <Typography>{dateToStringWIB({ date: approverData?.date, withTime: true })}</Typography>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Close</Button>
                {/* <Button size="large" type="submit">
                    Clock Out
                </Button> */}
            </DialogActions>
        </Dialog>
    );
};

export default DetailApprovalDialog;
