import { ChangeEvent, useEffect, useState } from 'react';
import { Card, Chip, Avatar, Grid } from '@mui/material';
import { IconCheck, IconUpload } from '@tabler/icons';
import { useAppSelector } from 'store/hooks';
import { Control, useController } from 'react-hook-form';
import userDefault from '@/_assets/abstract-user.svg';

type Props = {
    name: string;
    control: Control<any>;
    avatarImage: string;
    userName: string;
    role: string;
    lastName: string;
};

const CardBoxUser = ({ control, name, avatarImage, userName, role, lastName }: Props) => {
    const [imageFile, setImageFile] = useState<File | null>(null);
    const [fileDataURL, setFileDataURL] = useState<string | null>(null);
    const {
        field: { onChange }
    } = useController({ name, control });

    const imageTypeRegex = /image\/(png|jpg|jpeg)/gm;

    const changeHandler = (e: ChangeEvent<HTMLInputElement>) => {
        const target = e.target;
        if (!target.files) return;
        const file = target.files[0];

        if (file.type.match(imageTypeRegex)) {
            setImageFile(file);
            onChange(file);
            return;
        }
        alert('Selected images are not of valid type!');
    };

    useEffect(() => {
        let fileReader: FileReader,
            isCancel = false;
        if (imageFile) {
            fileReader = new FileReader();
            fileReader.onload = (e) => {
                const target = e.target as FileReader;
                const { result } = target;
                if (result && typeof result === 'string' && !isCancel) {
                    setFileDataURL(result);
                }
            };
            fileReader.readAsDataURL(imageFile);
        }
        return () => {
            isCancel = true;
            if (fileReader && fileReader.readyState === 1) {
                fileReader.abort();
            }
        };
    }, [imageFile]);

    return (
        <Card>
            <Grid container direction="row" spacing={2} sx={{ paddingBottom: 10 }}>
                <Grid item>
                    <Avatar src={fileDataURL ? fileDataURL : avatarImage} alt={userName} sx={{ width: 70, height: 70 }} />
                </Grid>
                <Grid item>
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', height: '100%', marginLeft: 5 }}>
                        <h3 style={{ margin: '0', padding: '0' }}>
                            Hallo,{' '}
                            <b>
                                {userName} {lastName}
                            </b>
                        </h3>
                        <p style={{ margin: '0', padding: '0', lineHeight: '1' }}>{role}</p>
                    </div>
                </Grid>
            </Grid>
            <div style={{ marginTop: 10, marginBottom: 10 }}>
                <input type="file" id="file" onChange={changeHandler} />
            </div>
        </Card>
    );
};

export default CardBoxUser;
