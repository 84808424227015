import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import {
    Button,
    Card,
    CardContent,
    Checkbox,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    ListItemText,
    MenuItem,
    OutlinedInput,
    Select as SelectMui,
    SelectChangeEvent,
    TableCell,
    TableRow,
    Typography
} from '@mui/material';
import { Layout } from 'components/layout';
import BaseTablePaginator from 'components/table/BaseTablePaginator';
import { PaginateRequest } from 'interfaces/api-request';
import { Employee } from 'interfaces/employee';
import { Order } from 'interfaces/table';
import { Title } from 'interfaces/title';
import { useDeleteEmployeeMutation, useGetEmployeeQuery } from 'lib/api/admin/adminEmployeeApi';
import { EmployeeStatus } from 'lib/api/employeeStatus';
import { useGetTitlesQuery } from 'lib/api/admin/adminTitleApi';
import { dateToStringWIB } from 'lib/helpers/dateHelper';
import MenuProps from 'lib/helpers/filterHelper';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import CardFilter from '../components/CardFilter';
import Select from 'react-select';
import { Role } from 'interfaces/role';
import FilterSelectContent from '../components/FilterSelectContent';
import { useGetRolesQuery } from 'lib/api/admin/adminRoleApi';
import ResetPasswordDialog from '../components/ResetPasswordDialog';

const MySwal = withReactContent(Swal);

// const EmployeeOrder = ['title', 'remaining_leave', 'employment_status', 'join_date', 'email', 'role'];

const AdminEmployeePage = () => {
    const [orderBy, setOrderBy] = useState<string>();
    const [order, setOrder] = useState<Order>('desc');
    const [paginateRequest, setPaginateRequest] = useState<PaginateRequest & { title?: string; role?: string; status?: string }>({
        current_page: 0,
        per_page: 10,
        order: order
    });
    //title needs
    const { isLoading: titleIsLoading, error: titleError, data: titleData } = useGetTitlesQuery();
    const [selectedTitle, setselectedTitle] = useState<readonly Title[]>([]);
    // useEffect(() => {
    //     setselectedTitle(titleData?.data ?? []);
    // }, [titleData]);
    // const handleTitleChange = (event: SelectChangeEvent<typeof selectedTitle>) => {
    //     const {
    //         target: { value }
    //     } = event;
    //     setselectedTitle(
    //         // On autofill we get a stringified value.
    //         typeof value === 'string'
    //             ? value.split(',').map<Title>((val) => {
    //                   return { name: val };
    //               })
    //             : value
    //     );
    //     console.log(selectedTitle);
    // };

    //role needs
    const { isLoading: roleIsLoading, error: roleError, data: roleData } = useGetRolesQuery();
    const [selectedRole, setselectedRole] = useState<readonly Role[]>([]);
    // useEffect(() => {
    //     setselectedRole(roleData?.data ?? []);
    // }, [roleData]);
    // const handleRoleChange = (event: SelectChangeEvent<typeof selectedRole>) => {
    //     const {
    //         target: { value }
    //     } = event;
    //     setselectedRole(
    //         // On autofill we get a stringified value.
    //         typeof value === 'string' ? value.split(',') : value
    //     );
    // };

    //Employment Status needs
    const employmentStatusData: EmployeeStatus[] = [
        { name: 'Permanent', value: 'P' },
        { name: 'Contract', value: 'C' }
    ];
    const [selectedEmploymentStatus, setselectedEmploymentStatus] = useState<readonly EmployeeStatus[]>([]);
    // useEffect(() => {
    //     setselectedEmploymentStatus(employmentStatusData);
    // }, []);
    // const handleEmploymentStatusChange = (event: SelectChangeEvent<typeof selectedEmploymentStatus>) => {
    //     const {
    //         target: { value }
    //     } = event;
    //     setselectedEmploymentStatus(
    //         // On autofill we get a stringified value.
    //         typeof value === 'string'
    //             ? value.split(',').map<EmployeeStatus>((val) => {
    //                   return {
    //                       name: val,
    //                       value: val
    //                   };
    //               })
    //             : value
    //     );
    //     console.log(selectedEmploymentStatus);
    // };

    // useEffect(() => {
    //     setPaginateRequest({
    //         ...paginateRequest,
    //         title: selectedTitle.map((val) => val.name).join(','),
    //         status: selectedEmploymentStatus.map((val) => val.value).join(','),
    //         role: selectedRole.map((e) => e.name).join(',')
    //     });
    // }, [selectedTitle]);
    // useEffect(() => {
    //     setPaginateRequest({
    //         ...paginateRequest,
    //         title: selectedTitle.map((val) => val.name).join(','),
    //         status: selectedEmploymentStatus.map((val) => val.value).join(','),
    //         role: selectedRole.map((e) => e.name).join(',')
    //     });
    // }, [selectedEmploymentStatus]);
    // useEffect(() => {
    //     setPaginateRequest({
    //         ...paginateRequest,
    //         title: selectedTitle.map((val) => val.name).join(','),
    //         status: selectedEmploymentStatus.map((val) => val.value).join(','),
    //         role: selectedRole.map((e) => e.name).join(',')
    //     });
    // }, [selectedRole]);

    const navigate = useNavigate();
    const [deleteEmployee] = useDeleteEmployeeMutation();
    const { isLoading, error, data, isFetching } = useGetEmployeeQuery({
        ...paginateRequest,
        current_page: +paginateRequest.current_page! + 1
    });

    useEffect(() => {
        setPaginateRequest({ ...paginateRequest, order: order });
    }, [order]);

    useEffect(() => {
        setPaginateRequest({ ...paginateRequest, order_by: orderBy });
    }, [orderBy]);

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setPaginateRequest({ ...paginateRequest, current_page: newPage });
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setPaginateRequest({ ...paginateRequest, current_page: 0, per_page: parseInt(event.target.value, 10) });
    };

    const [openResetPasswordDialog, setOpenResetPasswordDialog] = useState(false);

    const handleDelete = (employee: Employee) => {
        MySwal.fire({
            title: 'Are you sure?',
            text: 'You will delete this item!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete item!'
        }).then((result) => {
            if (result.isConfirmed) {
                deleteEmployee({ id: employee.id ?? '' })
                    .unwrap()
                    .then((res) => {
                        MySwal.fire('Sukses', 'Data berhasil dihapus!', 'success');
                    })
                    .catch((err) => {
                        console.error(err.message ? err.message : err.data.message);
                        MySwal.fire('Gagal', err.message ? err.message : err.data.message, 'error');
                    });
            }
        });
    };

    return (
        <Layout headerTitle="Employees" pageTitle="Employees" disableBreadcrumb>
            <Grid container direction={'column'} spacing={20}>
                <Grid item>
                    <CardFilter isLoading={titleIsLoading || roleIsLoading}>
                        <Grid container spacing={20}>
                            <Grid item xs={12} md={4}>
                                <FilterSelectContent
                                    label="Select Title"
                                    onSelectAll={() => {
                                        setselectedTitle(titleData?.data ?? []);
                                    }}
                                    isMulti
                                    closeMenuOnSelect={false}
                                    getOptionLabel={(e) => e.name ?? ''}
                                    getOptionValue={(e) => e.id ?? ''}
                                    options={titleData?.data}
                                    value={selectedTitle}
                                    menuPortalTarget={document.querySelector('body')}
                                    onChange={(newValue, actionMeta) => {
                                        setselectedTitle(newValue);
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <FilterSelectContent
                                    label="Select Role"
                                    onSelectAll={() => {
                                        setselectedRole(roleData?.data ?? []);
                                    }}
                                    isMulti
                                    closeMenuOnSelect={false}
                                    getOptionLabel={(e) => e.name ?? ''}
                                    getOptionValue={(e) => e.id.toString() ?? ''}
                                    options={roleData?.data}
                                    value={selectedRole}
                                    menuPortalTarget={document.querySelector('body')}
                                    onChange={(newValue, actionMeta) => {
                                        setselectedRole(newValue);
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <FilterSelectContent
                                    label="Select Employment Status"
                                    onSelectAll={() => {
                                        setselectedEmploymentStatus(employmentStatusData);
                                    }}
                                    isMulti
                                    closeMenuOnSelect={false}
                                    getOptionLabel={(e) => e.name ?? ''}
                                    getOptionValue={(e) => e.value ?? ''}
                                    options={employmentStatusData}
                                    value={selectedEmploymentStatus}
                                    menuPortalTarget={document.querySelector('body')}
                                    onChange={(newValue, actionMeta) => {
                                        setselectedEmploymentStatus(newValue);
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} textAlign={'right'}>
                                <Button
                                    variant="contained"
                                    onClick={() => {
                                        setPaginateRequest({
                                            ...paginateRequest,
                                            title: selectedTitle.map((val) => val.name).join(','),
                                            status: selectedEmploymentStatus.map((val) => val.value).join(','),
                                            role: selectedRole.map((e) => e.name).join(',')
                                        });
                                    }}
                                >
                                    Apply
                                </Button>
                            </Grid>
                        </Grid>
                    </CardFilter>
                </Grid>
                <Grid item>
                    <Card>
                        <CardContent>
                            <Grid container direction="column" spacing={10}>
                                <Grid item container justifyContent="flex-end" spacing={8}>
                                    <Grid item>
                                        <Button
                                            variant="outlined"
                                            onClick={(val) => {
                                                navigate('/admin/employees/create');
                                            }}
                                        >
                                            Add Employee
                                        </Button>
                                    </Grid>
                                    <Grid item>
                                        <Button
                                            variant="outlined"
                                            onClick={(val) => {
                                                setOpenResetPasswordDialog(true);
                                            }}
                                        >
                                            Reset Password
                                        </Button>
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <BaseTablePaginator
                                        isLoading={isLoading || isFetching || titleIsLoading || roleIsLoading}
                                        count={data?.meta.total ?? 0}
                                        rowsPerPage={+paginateRequest.per_page!}
                                        page={+paginateRequest.current_page!}
                                        onPageChange={handleChangePage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                        onSearchchange={(v) => {
                                            setPaginateRequest({ ...paginateRequest, q: v, current_page: 0 });
                                        }}
                                        headCells={[
                                            { id: 'name', label: 'Name', isSortable: true },
                                            { id: 'title', label: 'Title', isSortable: true },
                                            { id: 'leave_balance', label: 'Leave Balance', isSortable: true },
                                            { id: 'remainin_leave', label: 'Remaining Leave(current period)', isSortable: true },
                                            { id: 'status', label: 'Employment Status', isSortable: true },
                                            { id: 'join_date', label: 'Join Date', isSortable: true },
                                            { id: 'email', label: 'Email', isSortable: true },
                                            { id: 'role', label: 'Role', isSortable: true },
                                            { id: 'action', label: 'Actions', isSortable: false }
                                        ]}
                                        orderBy={orderBy}
                                        order={order}
                                        onRequestSort={(newOrderBy, newOrder) => {
                                            setOrderBy(newOrderBy);
                                            setOrder(newOrder);
                                            setPaginateRequest({ ...paginateRequest, current_page: 0 });
                                        }}
                                    >
                                        {data?.data?.map((row) => (
                                            <TableRow key={row.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                <TableCell component="th" scope="row">
                                                    {`${row.first_name} ${row.last_name}`}
                                                    <br />
                                                    {row.terminate_date != null ? (
                                                        <Typography variant="caption" color="error">
                                                            Tidak Aktif
                                                        </Typography>
                                                    ) : (
                                                        ''
                                                    )}
                                                </TableCell>
                                                <TableCell>{row.title?.name ?? '-'}</TableCell>
                                                <TableCell>{row.leave_balance}</TableCell>
                                                <TableCell>{row.leave_period?.remaining_leave_days}</TableCell>
                                                <TableCell>{row.status}</TableCell>
                                                <TableCell>{dateToStringWIB({ date: row.join_date })}</TableCell>
                                                <TableCell>{row.email}</TableCell>
                                                <TableCell>{row.roles}</TableCell>
                                                <TableCell>
                                                    <IconButton
                                                        color="primary"
                                                        onClick={() => {
                                                            navigate(`/admin/employees/edit/${row.id}`);
                                                        }}
                                                    >
                                                        <EditIcon />
                                                    </IconButton>
                                                    <IconButton
                                                        color="error"
                                                        onClick={() => {
                                                            // navigate(`/employees/edit/${row.id}`);
                                                            handleDelete(row);
                                                        }}
                                                    >
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </BaseTablePaginator>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
            {!isLoading && (
                <ResetPasswordDialog
                    open={openResetPasswordDialog}
                    handleClose={() => {
                        setOpenResetPasswordDialog(false);
                    }}
                />
            )}
        </Layout>
    );
};

export default AdminEmployeePage;
