import { Employee } from 'interfaces/employee';
import { Grid, Typography } from '@mui/material';
import { dateToStringFullWIB } from 'lib/helpers/dateHelper';

const DataPersonal = ({ userData }: { userData: Employee }) => {
    return (
        <>
            <Grid container spacing={5} alignItems="flex-end" justifyContent="space-between">
                <Grid item xs={11} md={4} lg={4}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                        First Name
                    </Typography>
                </Grid>
                <Grid item xs={1} md={1} lg={1}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                        :
                    </Typography>
                </Grid>
                <Grid item xs={12} md={7} lg={7}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                        {userData.first_name}
                    </Typography>
                </Grid>
            </Grid>
            <Grid container spacing={5} alignItems="flex-end" justifyContent="space-between">
                <Grid item xs={11} md={4} lg={4}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                        Last Name
                    </Typography>
                </Grid>
                <Grid item xs={1} md={1} lg={1}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                        :
                    </Typography>
                </Grid>
                <Grid item xs={12} md={7} lg={7}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                        {userData.last_name}
                    </Typography>
                </Grid>
            </Grid>
            <Grid container spacing={5} alignItems="flex-end" justifyContent="space-between">
                <Grid item xs={11} md={4} lg={4}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                        Email
                    </Typography>
                </Grid>
                <Grid item xs={1} md={1} lg={1}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                        :
                    </Typography>
                </Grid>
                <Grid item xs={12} md={7} lg={7}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                        {userData.email}
                    </Typography>
                </Grid>
            </Grid>
            <Grid container spacing={5} alignItems="flex-end" justifyContent="space-between">
                <Grid item xs={11} md={4} lg={4}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                        KTP Number
                    </Typography>
                </Grid>
                <Grid item xs={1} md={1} lg={1}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                        :
                    </Typography>
                </Grid>
                <Grid item xs={12} md={7} lg={7}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                        {userData.ktp_number}
                    </Typography>
                </Grid>
            </Grid>
            <Grid container spacing={5} alignItems="flex-end" justifyContent="space-between">
                <Grid item xs={11} md={4} lg={4}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                        Phone Number
                    </Typography>
                </Grid>
                <Grid item xs={1} md={1} lg={1}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                        :
                    </Typography>
                </Grid>
                <Grid item xs={12} md={7} lg={7}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                        {userData.phone_number}
                    </Typography>
                </Grid>
            </Grid>
            <Grid container spacing={5} alignItems="flex-end" justifyContent="space-between">
                <Grid item xs={11} md={4} lg={4}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                        Telephone Number
                    </Typography>
                </Grid>
                <Grid item xs={1} md={1} lg={1}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                        :
                    </Typography>
                </Grid>
                <Grid item xs={12} md={7} lg={7}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                        {userData.phone_number_address_now}
                    </Typography>
                </Grid>
            </Grid>
            <Grid container spacing={5} alignItems="flex-end" justifyContent="space-between">
                <Grid item xs={11} md={4} lg={4}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                        Address
                    </Typography>
                </Grid>
                <Grid item xs={1} md={1} lg={1}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                        :
                    </Typography>
                </Grid>
                <Grid item xs={12} md={7} lg={7}>
                    {/* <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                    </Typography> */}
                </Grid>
            </Grid>
            <Grid container spacing={5} alignItems="flex-end" justifyContent="space-between">
                <Grid item xs={12} md={12} lg={12}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                        {userData.address}
                    </Typography>
                </Grid>
            </Grid>
            <Grid container spacing={5} alignItems="flex-end" justifyContent="space-between">
                <Grid item xs={11} md={4} lg={4}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                        Address Now
                    </Typography>
                </Grid>
                <Grid item xs={1} md={1} lg={1}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                        :
                    </Typography>
                </Grid>
                <Grid item xs={12} md={7} lg={7}></Grid>
            </Grid>
            <Grid container spacing={5} alignItems="flex-end" justifyContent="space-between">
                <Grid item xs={12} md={12} lg={12}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                        {userData.address_now}
                    </Typography>
                </Grid>
            </Grid>
            <br></br>
            <Grid container spacing={5} alignItems="flex-end" justifyContent="space-between">
                <Grid item xs={11} md={4} lg={4}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                        Birth Place
                    </Typography>
                </Grid>
                <Grid item xs={1} md={1} lg={1}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                        :
                    </Typography>
                </Grid>
                <Grid item xs={12} md={7} lg={7}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                        {userData.birth_place}
                    </Typography>
                </Grid>
            </Grid>
            <Grid container spacing={5} alignItems="flex-end" justifyContent="space-between">
                <Grid item xs={11} md={4} lg={4}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                        Birth Date
                    </Typography>
                </Grid>
                <Grid item xs={1} md={1} lg={1}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                        :
                    </Typography>
                </Grid>
                <Grid item xs={12} md={7} lg={7}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                        {dateToStringFullWIB({ date: userData.birth_date })}
                    </Typography>
                </Grid>
            </Grid>
            <Grid container spacing={5} alignItems="flex-end" justifyContent="space-between">
                <Grid item xs={11} md={4} lg={4}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                        Gender
                    </Typography>
                </Grid>
                <Grid item xs={1} md={1} lg={1}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                        :
                    </Typography>
                </Grid>
                <Grid item xs={12} md={7} lg={7}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                        {userData.gender}
                    </Typography>
                </Grid>
            </Grid>
            <Grid container spacing={5} alignItems="flex-end" justifyContent="space-between">
                <Grid item xs={11} md={4} lg={4}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                        Marital Status
                    </Typography>
                </Grid>
                <Grid item xs={1} md={1} lg={1}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                        :
                    </Typography>
                </Grid>
                <Grid item xs={12} md={7} lg={7}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                        {userData.marital_status}
                    </Typography>
                </Grid>
            </Grid>
            <Grid container spacing={5} alignItems="flex-end" justifyContent="space-between">
                <Grid item xs={11} md={4} lg={4}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                        Blood Type
                    </Typography>
                </Grid>
                <Grid item xs={1} md={1} lg={1}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                        :
                    </Typography>
                </Grid>
                <Grid item xs={12} md={7} lg={7}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                        {userData.blood_type}
                    </Typography>
                </Grid>
            </Grid>
            <Grid container spacing={5} alignItems="flex-end" justifyContent="space-between">
                <Grid item xs={11} md={4} lg={4}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                        Religion
                    </Typography>
                </Grid>
                <Grid item xs={1} md={1} lg={1}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                        :
                    </Typography>
                </Grid>
                <Grid item xs={12} md={7} lg={7}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                        {userData.religion}
                    </Typography>
                </Grid>
            </Grid>
            <Grid container spacing={5} alignItems="flex-end" justifyContent="space-between">
                <Grid item xs={11} md={4} lg={4}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                        Citizen
                    </Typography>
                </Grid>
                <Grid item xs={1} md={1} lg={1}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                        :
                    </Typography>
                </Grid>
                <Grid item xs={12} md={7} lg={7}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                        {userData.citizen}
                    </Typography>
                </Grid>
            </Grid>
        </>
    );
};

export default DataPersonal;
