import { Grid, Typography, Box, Button } from '@mui/material';
import { FormInput, SelectInput } from 'components/input/hook-form';
import { useController, Control, useFieldArray } from 'react-hook-form';
import { listHubungan, listTanggungan } from 'lib/helpers/dataSelect';

const KeluargaDataTab = ({ control }: { control: Control<any> }) => {
    const {
        fields: tanggungan,
        append,
        remove,
        insert
    } = useFieldArray<any>({
        control,
        name: 'tanggungan'
    });
    return (
        <>
            <Typography
                mb={0}
                variant="h5"
                sx={{
                    fontWeight: 'bold'
                }}
            >
                Kontak Darurat
            </Typography>
            <Grid container spacing={10} alignItems="flex-end">
                <Grid item xs={12} md={6} lg={3} paddingTop={5} marginTop={0}>
                    <SelectInput required name="darurat_hubungan" control={control} label="Hubungan" options={listHubungan} />
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                    <FormInput required control={control} label="Nama" type="text" name="darurat_name" />
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                    <FormInput control={control} label="Alamat" type="text" name="darurat_address" />
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                    <FormInput required control={control} label="No Telepon/HP" type="text" name="darurat_phone_number" />
                </Grid>
            </Grid>
            <Typography
                mb={0}
                variant="h5"
                sx={{
                    marginTop: 15,
                    fontWeight: 'bold'
                }}
            >
                Data Tanggungan
            </Typography>
            <Grid container spacing={10} alignItems="flex-end">
                {tanggungan.map((v, i) => {
                    return (
                        <Grid key={v.id} item xs={12} md={12}>
                            <Grid container columnSpacing={2}>
                                <Grid item lg={11} md={11} xs={12}>
                                    <Grid container rowSpacing={2} columnSpacing={5}>
                                        <Grid item lg={2} md={6} xs={12}>
                                            <SelectInput
                                                name={`tanggungan.${i}.tanggungan`}
                                                control={control}
                                                label="Tanggungan"
                                                options={listTanggungan}
                                            />
                                        </Grid>
                                        <Grid item lg={3} md={6} xs={12}>
                                            <FormInput label="Nama" control={control} type="text" name={`tanggungan.${i}.name`} />
                                        </Grid>
                                        <Grid item lg={3} md={6} xs={12}>
                                            <FormInput
                                                control={control}
                                                label="Tempat Lahir"
                                                type="text"
                                                name={`tanggungan.${i}.birth_place`}
                                            />
                                        </Grid>
                                        <Grid item lg={2} md={6} xs={12}>
                                            <FormInput
                                                isShrink
                                                control={control}
                                                label="Tanggal Lahir"
                                                type="date"
                                                name={`tanggungan.${i}.birth_date`}
                                            />
                                        </Grid>
                                        <Grid item lg={2} md={6} xs={12}>
                                            <FormInput
                                                control={control}
                                                label="Pendidikan Terakhir"
                                                type="text"
                                                name={`tanggungan.${i}.last_education`}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid
                                    item
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center'
                                    }}
                                    xs={12}
                                    md={1}
                                    lg={1}
                                >
                                    {i ? (
                                        <Button
                                            onClick={() => {
                                                remove(i);
                                            }}
                                        >
                                            Hapus
                                        </Button>
                                    ) : null}
                                </Grid>
                            </Grid>
                        </Grid>
                    );
                })}
                <Grid item md={6} xs={12}>
                    <Button
                        variant="contained"
                        onClick={() => {
                            append({
                                hubungan: '',
                                name: '',
                                birth_place: '',
                                birth_date: '',
                                last_education: ''
                            });
                        }}
                        // sx={{
                        //     width: '100%',
                        //     height: 40,
                        //     color: 'white'
                        // }}
                    >
                        Tambah Tanggungan
                    </Button>
                </Grid>
            </Grid>
        </>
    );
};

export default KeluargaDataTab;
