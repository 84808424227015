import config from 'config';
import Cookies from 'universal-cookie';
import { createHashId, decodeHashId } from './hasher';

const cookies = new Cookies();

export function removeToken() {
    cookies.remove(config.cookieToken, { path: '/' });
    cookies.remove(config.cookieCompanyId, { path: '/' });
    cookies.remove(config.cookieUserID, { path: '/' });
    cookies.remove(config.cookieUserName, { path: '/' });
    cookies.remove(config.cookieUser, { path: '/' });
    localStorage.removeItem(config.cookieUser);
}

export const setToken = (accessToken: string, companyId: string, firstName: string, userId: string, expireIn: number, user: string) => {
    cookies.set(config.cookieToken, createHashId(accessToken), {
        maxAge: expireIn,
        secure: true,
        sameSite: 'none',
        path: '/'
    });
    // cookies.set(config.cookieUser, createHashId(user), {
    //     maxAge: expireIn,
    //     secure: true,
    //     sameSite: 'none',
    //     path: '/'
    // });
    localStorage.setItem(config.cookieUser, createHashId(user));
    cookies.set(config.cookieCompanyId, createHashId(companyId), {
        maxAge: expireIn,
        secure: true,
        sameSite: 'none',
        path: '/'
    });
    cookies.set(config.cookieUserName, createHashId(firstName), {
        maxAge: expireIn,
        secure: true,
        sameSite: 'none',
        path: '/'
    });
    cookies.set(config.cookieUserID, createHashId(userId), {
        maxAge: expireIn,
        secure: true,
        sameSite: 'none',
        path: '/'
    });
};

export const getToken = () => {
    return decodeHashId(cookies.get(config.cookieToken));
};
