import crypto from 'crypto-js';

const createRandomString = (num: number) => {
    return [...Array(num)].map(() => Math.random().toString(36)[2]).join('');
};

const base64Url = (string: any) => {
    return string.toString(crypto.enc.Base64).replace(/\+/g, '-').replace(/\//g, '_').replace(/=/g, '');
};

export const verifier = createRandomString(128);
export const dataCrypt = {
    state: createRandomString(40),
    challenge: base64Url(crypto.SHA256(verifier))
};
