import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Employee } from 'interfaces/employee';

export interface UserState {
    userId: string;
    firstName: string;
    lastName: string;
    userTenant: string;
    userEmail: string;
    userAvatar: null | string;
    userRole: string;
    isFieldFocusRegistered: boolean;
}

const initialState: UserState = {
    /* User */
    userId: '',
    firstName: '',
    lastName: '',
    userTenant: '',
    userEmail: '',
    userAvatar: null,
    userRole: '',

    /* Field focus with ctrl+k (to register only once) */
    isFieldFocusRegistered: false
};

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setUser: (state, action: PayloadAction<Employee>) => {
            state.userId = action.payload.id as unknown as string;
            state.firstName = action.payload.first_name as string;
            state.lastName = action.payload.last_name as string;
            state.userEmail = action.payload.email as string;
            state.userAvatar = action.payload.url_avatar as string;
            state.userRole = action.payload.role as string;
        }
    }
});

// Action creators are generated for each case reducer function
export const { setUser } = userSlice.actions;

export default userSlice.reducer;
