import { Employee } from 'interfaces/employee';
import { Grid, Typography, Card } from '@mui/material';
import { dateToStringFullWIB } from 'lib/helpers/dateHelper';

const DataPekerjaan = ({ userData }: { userData: Employee }) => {
    return (
        <Card sx={{ padding: 10, marginTop: 10 }}>
            <Grid container spacing={5} alignItems="flex-end" justifyContent="space-between">
                <Grid item xs={11} md={4} lg={4}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                        NIK
                    </Typography>
                </Grid>
                <Grid item xs={1} md={1} lg={1}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                        :
                    </Typography>
                </Grid>
                <Grid item xs={12} md={7} lg={7}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                        {userData.nik}
                    </Typography>
                </Grid>
            </Grid>
            <Grid container spacing={5} alignItems="flex-end" justifyContent="space-between">
                <Grid item xs={11} md={4} lg={4}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                        Employment Status
                    </Typography>
                </Grid>
                <Grid item xs={1} md={1} lg={1}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                        :
                    </Typography>
                </Grid>
                <Grid item xs={12} md={7} lg={7}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                        {userData.status}
                    </Typography>
                </Grid>
            </Grid>
            <Grid container spacing={5} alignItems="flex-end" justifyContent="space-between">
                <Grid item xs={11} md={4} lg={4}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                        Title
                    </Typography>
                </Grid>
                <Grid item xs={1} md={1} lg={1}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                        :
                    </Typography>
                </Grid>
                <Grid item xs={12} md={7} lg={7}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                        {userData.title?.name}
                    </Typography>
                </Grid>
            </Grid>
            <Grid container spacing={5} alignItems="flex-end" justifyContent="space-between">
                <Grid item xs={11} md={4} lg={4}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                        Department
                    </Typography>
                </Grid>
                <Grid item xs={1} md={1} lg={1}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                        :
                    </Typography>
                </Grid>
                <Grid item xs={12} md={7} lg={7}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                        {userData.department?.name}
                    </Typography>
                </Grid>
            </Grid>
            <Grid container spacing={5} alignItems="flex-end" justifyContent="space-between">
                <Grid item xs={11} md={4} lg={4}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                        Supervisor
                    </Typography>
                </Grid>
                <Grid item xs={1} md={1} lg={1}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                        :
                    </Typography>
                </Grid>
                <Grid item xs={12} md={7} lg={7}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                        {userData.supervisor?.first_name} {userData.supervisor?.last_name}
                    </Typography>
                </Grid>
            </Grid>
            <Grid container spacing={5} alignItems="flex-end" justifyContent="space-between">
                <Grid item xs={11} md={4} lg={4}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                        Direct Supervisor
                    </Typography>
                </Grid>
                <Grid item xs={1} md={1} lg={1}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                        :
                    </Typography>
                </Grid>
                <Grid item xs={12} md={7} lg={7}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                        {userData.direct_spv?.first_name} {userData.direct_spv?.last_name}
                    </Typography>
                </Grid>
            </Grid>
            <Grid container spacing={5} alignItems="flex-end" justifyContent="space-between">
                <Grid item xs={11} md={4} lg={4}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                        Join Date
                    </Typography>
                </Grid>
                <Grid item xs={1} md={1} lg={1}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                        :
                    </Typography>
                </Grid>
                <Grid item xs={12} md={7} lg={7}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                        {dateToStringFullWIB({ date: userData.join_date })}
                    </Typography>
                </Grid>
            </Grid>
            <Grid container spacing={5} alignItems="flex-end" justifyContent="space-between">
                <Grid item xs={11} md={4} lg={4}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                        End Date
                    </Typography>
                </Grid>
                <Grid item xs={1} md={1} lg={1}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                        :
                    </Typography>
                </Grid>
                <Grid item xs={12} md={7} lg={7}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                        {dateToStringFullWIB({ date: userData.end_date })}
                    </Typography>
                </Grid>
            </Grid>
            <Grid container spacing={5} alignItems="flex-end" justifyContent="space-between">
                <Grid item xs={11} md={4} lg={4}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                        Permanent Date
                    </Typography>
                </Grid>
                <Grid item xs={1} md={1} lg={1}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                        :
                    </Typography>
                </Grid>
                <Grid item xs={12} md={7} lg={7}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                        {dateToStringFullWIB({ date: userData.permanent_date })}
                    </Typography>
                </Grid>
            </Grid>
            <Grid container spacing={5} alignItems="flex-end" justifyContent="space-between">
                <Grid item xs={11} md={4} lg={4}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                        Leave Balance
                    </Typography>
                </Grid>
                <Grid item xs={1} md={1} lg={1}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                        :
                    </Typography>
                </Grid>
                <Grid item xs={12} md={7} lg={7}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                        {userData.leave_balance}
                    </Typography>
                </Grid>
            </Grid>
            <Grid container spacing={5} alignItems="flex-end" justifyContent="space-between">
                <Grid item xs={11} md={4} lg={4}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                        Is Approver
                    </Typography>
                </Grid>
                <Grid item xs={1} md={1} lg={1}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                        :
                    </Typography>
                </Grid>
                <Grid item xs={12} md={7} lg={7}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                        {userData.is_approver ? 'Ya' : 'Tidak'}
                    </Typography>
                </Grid>
            </Grid>
        </Card>
    );
};

export default DataPekerjaan;
