import {
    FormLabel,
    Select,
    ListSubheader,
    TextField,
    InputAdornment,
    MenuItem,
    SelectChangeEvent,
    InputLabel,
    Typography
} from '@mui/material';
import { BaseForm } from 'components/input/hook-form/BaseForm';
import { PaginateRequest } from 'interfaces/api-request';
import { Employee } from 'interfaces/employee';
import React, { useEffect, useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import { useGetEmployeeSearchMutation } from 'lib/api/admin/adminEmployeeApi';
import { Control, FieldError, useController } from 'react-hook-form';

interface ISelectEmployeeProps {
    // onSelectedEmployee: (selectedEmployee: Employee | undefined) => void;
    control: Control<any>;
    name: string;
    label: string;
    initEmployee?: Employee;
    paginateRequest?: PaginateRequest & { name?: string; title?: string; role?: string; status?: string; is_approver?: string };
}

interface ISelectedEmployee {
    id: number;
    first_name: string;
    last_name: string;
    email: string;
}

const SelectEmployeeEdit = ({
    // onSelectedEmployee,
    control,
    name,
    label,
    initEmployee,
    paginateRequest = { per_page: 5 }
}: ISelectEmployeeProps) => {
    const [getEmployee] = useGetEmployeeSearchMutation();
    const [employees, setEmployees] = useState<Employee[]>([]);
    const [selectedEmployee, setSelectedEmployee] = useState<ISelectedEmployee>();
    const [searchText, setSearchText] = useState('');
    const [error, setError] = useState<FieldError | undefined>(undefined);

    const {
        field: { onChange }
    } = useController({ name, control });

    useEffect(() => {
        getEmployee(paginateRequest)
            .unwrap()
            .then((e) => {
                setEmployees(e.data ?? []);
            });
        if (initEmployee) {
            setSelectedEmployee(initEmployee);
            // onSelectedEmployee(initEmployee);
        }
    }, []);

    return (
        <BaseForm
            name={name}
            control={control}
            label={label}
            onError={(error) => {
                setError(error);
            }}
        >
            {/* <InputLabel id="search-select-label">{label}</InputLabel> */}
            <Select
                // Disables auto focus on MenuItems and allows TextField to be in focus
                error={Boolean(error)}
                MenuProps={{ autoFocus: false }}
                labelId="search-select-label"
                id="search-select"
                value={selectedEmployee ? JSON.stringify(selectedEmployee) : ''}
                // label={label}
                onChange={(e) => {
                    const val = JSON.parse(e.target.value as string) as Employee;
                    // setSelectedEmployee(e.target.value as unknown as Employee);
                    setSelectedEmployee(val);
                    onChange(val.id);
                }}
                onClose={() => setSearchText('')}
                // This prevents rendering empty string in Select's value
                // if search text would exclude currently selected option.
                renderValue={
                    // selectedEmployee?.id == initEmployee?.id && initEmployee?.id != undefined
                    //     ? (e) => {
                    //           console.log('selectedEmployee', selectedEmployee);
                    //           const option = selectedEmployee ?? initEmployee;
                    //           return (
                    //               <Typography sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }} variant="h5">
                    //                   {option ? `${option.first_name} ${option.last_name} (${option.email})` : ''}
                    //               </Typography>
                    //           );
                    //       }
                    //     : undefined
                    (e) => {
                        console.log('selectedEmployee--ttttt', selectedEmployee);
                        const option = selectedEmployee ?? initEmployee;
                        console.log('option--ttttt', option);
                        return (
                            <Typography sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }} variant="h5">
                                {option ? `${option.first_name} ${option.last_name} (${option.email})` : ''}
                            </Typography>
                        );
                    }
                }
            >
                <ListSubheader>
                    <TextField
                        size="small"
                        // Autofocus on textfield
                        // autoFocus
                        placeholder="Type to search..."
                        fullWidth
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                            autoFocus: true
                        }}
                        onChange={(e) => {
                            setSearchText(e.target.value);
                            // let valueEmployee = JSON.parse(e.target.value as string) as Employee;
                            // setSelectedEmployee({
                            //     id: valueEmployee.id,
                            //     first_name: valueEmployee.first_name,
                            //     last_name: valueEmployee.last_name,
                            //     email: valueEmployee.email
                            // });
                            // onSelectedEmployee(undefined);
                            // setValue('employee_id', undefined);
                            getEmployee({ q: searchText, per_page: 5 })
                                .unwrap()
                                .then((e) => {
                                    setEmployees(e.data ?? []);
                                });
                        }}
                        onKeyDown={(e) => {
                            if (e.key !== 'Escape') {
                                // Prevents autoselecting item while typing (default Select behaviour)
                                e.stopPropagation();
                            }
                        }}
                    />
                </ListSubheader>
                {employees.map((option, i) => (
                    <MenuItem key={i} value={JSON.stringify(option)}>
                        {`${option.first_name} ${option.last_name} (${option.email})`}
                    </MenuItem>
                ))}
            </Select>
        </BaseForm>
    );
};

export default SelectEmployeeEdit;
