import { Card, CardHeader, CardContent, CircularProgress, Grid, Collapse, IconButton, CardActionArea } from '@mui/material';
import { ReactNode, useState } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

interface ICardFilterProps {
    isLoading: boolean;
    children: ReactNode;
}

const CardFilter = ({ children, isLoading }: ICardFilterProps) => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <Card>
            <CardActionArea title="Filter Data" onClick={() => setIsOpen(!isOpen)}>
                <CardHeader title="Filter Data" action={isOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />} />
            </CardActionArea>
            <Collapse in={isOpen} timeout="auto" unmountOnExit>
                <CardContent>
                    {isLoading ? (
                        <Grid container>
                            <Grid item xs={12} alignItems={'center'} alignContent={'center'} textAlign={'center'}>
                                <CircularProgress />
                            </Grid>
                        </Grid>
                    ) : (
                        children
                    )}
                </CardContent>
            </Collapse>
        </Card>
    );
};

export default CardFilter;
