import { Box, FormControl, FormHelperText, InputProps, Typography } from '@mui/material';
import { FormInputProps } from 'interfaces/input';
import { ReactChild, ReactElement, ReactNode, useEffect } from 'react';
import { FieldError, useController } from 'react-hook-form';

interface BaseFormProps extends FormInputProps {
    type?: string;
    isShrink?: boolean;
    inputProps?: Partial<InputProps>;
    multiline?: boolean;
    minRows?: any;
    endAdornment?: ReactNode;
    startAdornment?: ReactNode;
    children?: ReactNode;
    onError?: (error: FieldError | undefined) => void;
    required?: boolean;
}

export const BaseForm = ({ name, control, children, onError, required, label }: BaseFormProps) => {
    const { field, fieldState } = useController({ name, control });
    const { error } = fieldState;
    useEffect(() => {
        onError && onError(error);
    }, [error]);

    return (
        <FormControl fullWidth>
            <Typography variant="caption" sx={{ paddingTop: '0', marginTop: '0' }}>
                {label}
                {required && <span style={{ color: 'red' }}> *</span>}
            </Typography>
            {children}
            {Boolean(error) && <FormHelperText sx={{ color: 'red', mx: 0 }}>{error?.message}</FormHelperText>}
        </FormControl>
    );
};
