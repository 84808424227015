import { Button, Card, CardContent, TableRow, TableCell, IconButton, Grid } from '@mui/material';
import { Layout } from 'components/layout';
import BaseTablePaginator from 'components/table/BaseTablePaginator';
import { PaginateRequest } from 'interfaces/api-request';
import { Order } from 'interfaces/table';
import CardFilter from 'modules/admin/employee/components/CardFilter';
import React, { useEffect, useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ChangeCircleOutlinedIcon from '@mui/icons-material/ChangeCircleOutlined';
import { useDeleteSubCompanyMutation, useGetSubCompaniesPaginateQuery } from 'lib/api/admin/adminSubCompanyApi';
import { SubCompany } from 'interfaces/sub-company';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { useDeleteDepartmentMutation, useGetDepartmentsPaginateQuery } from 'lib/api/admin/adminDepartmentApi';
import { Department } from 'interfaces/department';
import CreateDepartmentDialog from '../components/CreateDepartmentDialog';
import EditDepartmentDialog from '../components/EditDepartmentDialog';

const AdminDepartmentPage = () => {
    const [order, setOrder] = useState<Order>('asc');
    const [orderBy, setOrderBy] = useState<string>();
    useEffect(() => {
        setPaginateRequest({ ...paginateRequest, order: order });
    }, [order]);
    useEffect(() => {
        setPaginateRequest({ ...paginateRequest, order_by: orderBy });
    }, [orderBy]);
    const [paginateRequest, setPaginateRequest] = useState<PaginateRequest>({
        current_page: 0,
        per_page: 10,
        order: order
    });
    const { isLoading, error, data, isFetching } = useGetDepartmentsPaginateQuery({
        ...paginateRequest,
        current_page: +paginateRequest.current_page! + 1
    });
    const [selectedDepartment, setSelectedDepartment] = useState<Department>();
    const [openDialogCreateDepartment, setOpenDialogCreateDepartment] = useState(false);
    const [openDialogEditDepartment, setOpenDialogEditDepartment] = useState(false);

    //delete data
    const [deleteDepartment] = useDeleteDepartmentMutation();
    const handleDelete = (department: Department) => {
        const MySwal = withReactContent(Swal);
        MySwal.fire({
            title: 'Are you sure?',
            text: 'You will delete this item!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete item!'
        }).then((result) => {
            if (result.isConfirmed) {
                deleteDepartment({ id: department.id ?? '' })
                    .unwrap()
                    .then((res) => {
                        MySwal.fire('Sukses', 'Data berhasil dihapus!', 'success');
                    })
                    .catch((err) => {
                        console.error(err.message ? err.message : err.data.message);
                        MySwal.fire('Gagal', err.message ? err.message : err.data.message, 'error');
                    });
            }
        });
    };
    return (
        <Layout headerTitle="Department" pageTitle="Department" disableBreadcrumb>
            <Grid container direction={'column'} spacing={20}>
                <Grid item>
                    <Card>
                        <CardContent>
                            <Grid container direction={'column'} spacing={20}>
                                <Grid item container justifyContent="flex-end" spacing={8}>
                                    <Grid item>
                                        <Button
                                            variant="outlined"
                                            onClick={(val) => {
                                                setOpenDialogCreateDepartment(true);
                                            }}
                                        >
                                            Add Department
                                        </Button>
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <BaseTablePaginator
                                        isLoading={isLoading || isFetching || false}
                                        count={data?.meta.total ?? 0}
                                        rowsPerPage={+paginateRequest.per_page!}
                                        page={+paginateRequest.current_page!}
                                        onPageChange={function (
                                            event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
                                            newPage: number
                                        ): void {
                                            setPaginateRequest({ ...paginateRequest, current_page: newPage });
                                        }}
                                        onRowsPerPageChange={function (
                                            event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
                                        ): void {
                                            setPaginateRequest({
                                                ...paginateRequest,
                                                current_page: 0,
                                                per_page: parseInt(event.target.value, 10)
                                            });
                                        }}
                                        onSearchchange={function (e: string): void {
                                            setPaginateRequest({ ...paginateRequest, q: e, current_page: 0 });
                                        }}
                                        orderBy={orderBy}
                                        order={order}
                                        onRequestSort={(newOrderBy, newOrder) => {
                                            setOrderBy(newOrderBy);
                                            setOrder(newOrder);
                                            setPaginateRequest({ ...paginateRequest, current_page: 0 });
                                        }}
                                        headCells={[
                                            { id: 'name', label: 'Name', isSortable: true },
                                            { id: 'action', label: 'Actions', isSortable: false }
                                        ]}
                                    >
                                        {data?.data?.map((row) => (
                                            <TableRow key={row.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                <TableCell component="th" scope="row">
                                                    {row.name}
                                                </TableCell>
                                                <TableCell>
                                                    <IconButton
                                                        color="primary"
                                                        onClick={() => {
                                                            setSelectedDepartment(row);
                                                            setOpenDialogEditDepartment(true);
                                                        }}
                                                    >
                                                        <EditIcon />
                                                    </IconButton>
                                                    <IconButton
                                                        color="error"
                                                        onClick={() => {
                                                            handleDelete(row);
                                                        }}
                                                    >
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </BaseTablePaginator>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
            {!isLoading && (
                <>
                    <CreateDepartmentDialog
                        open={openDialogCreateDepartment}
                        handleClose={() => {
                            setOpenDialogCreateDepartment(false);
                        }}
                    />
                    {selectedDepartment && (
                        <EditDepartmentDialog
                            open={openDialogEditDepartment}
                            handleClose={() => {
                                setOpenDialogEditDepartment(false);
                            }}
                            department={selectedDepartment}
                        />
                    )}
                </>
            )}
        </Layout>
    );
};

export default AdminDepartmentPage;
