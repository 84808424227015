import { ApiResponse } from 'interfaces/api-response';
import { Employee } from 'interfaces/employee';
import { LoginRequestBody, AuthorizeRequestBody } from '../requests/loginRequest';
import { getBaseHeaderApi } from '../services/apiHelper';
import { adminApi } from '../services/services';
import { User } from 'interfaces/user';

export const AdminauthApi = adminApi.enhanceEndpoints({ addTagTypes: ['myprofile'] }).injectEndpoints({
    endpoints(builder) {
        return {
            adminLogin: builder.mutation({
                query: (data: LoginRequestBody) => ({
                    url: '/auth/admin/login',
                    method: 'POST',
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                        'Content-type': 'application/json'
                    },
                    data: { email: data.email, password: data.password, state: data.state, challenge: data.challenge }
                })
            }),
            adminAuthorize: builder.mutation({
                query: (data: AuthorizeRequestBody) => ({
                    url: '/auth/admin/authorize',
                    method: 'POST',
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                        'Content-type': 'application/json'
                    },
                    data: {
                        code: data.code,
                        verifier: data.verifier
                    }
                }),
                invalidatesTags: ['myprofile']
            }),
            getAdminProfile: builder.query<ApiResponse<User>, void>({
                query: () => ({
                    url: '/admin/my_profile',
                    method: 'GET',
                    headers: getBaseHeaderApi()
                }),
                providesTags: ['myprofile']
            })
        };
    }
});

export const { useAdminLoginMutation, useAdminAuthorizeMutation, useGetAdminProfileQuery } = AdminauthApi;
