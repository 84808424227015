import { Buffer } from 'buffer';
import Hashids from 'hashids';

const hashids = new Hashids('%13G_gZx-CKs&.&13G_m1s$-CKs&', 25, 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890-');

export const createHashId = (id: string | any) => {
    var hex = Buffer.from(id, 'utf8').toString('hex');
    return hashids.encodeHex(hex);
};

export const decodeHashId = (id: string | any) => {
    const decodedHex = hashids.decodeHex(id);
    const string = Buffer.from(decodedHex, 'hex').toString('utf8');
    return string;
};
