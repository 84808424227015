import { ApiResponse, ApiResponseWithPagination } from 'interfaces/api-response';
import { generateParamPaginate, getBaseHeaderApi, setParam } from '../services/apiHelper';
import { adminApi, baseApi } from '../services/services';
import { WorkCategory } from 'interfaces/work-category';
import { PaginateRequest } from 'interfaces/api-request';
import { CompanyId } from 'interfaces/company-id';
import { SubCompany } from 'interfaces/sub-company';
import { LeaveFlow } from 'interfaces/leave-flow';

export interface LeaveFlowRequestBody {
    approver_1?: string;
    approver_2?: string;
    approver_3?: string;
    department_id?: string;
}

export const AdminLeaveFlowApi = adminApi.enhanceEndpoints({ addTagTypes: ['leaveFlows'] }).injectEndpoints({
    endpoints(builder) {
        return {
            getLeaveFlows: builder.query<ApiResponse<LeaveFlow[]>, void>({
                query: () => ({
                    url: '/leave_flow',
                    method: 'GET',
                    headers: getBaseHeaderApi()
                }),
                providesTags: ['leaveFlows']
            }),
            getLeaveFlowsPaginate: builder.query<ApiResponseWithPagination<LeaveFlow[]>, PaginateRequest>({
                query: (request) => ({
                    url: `/leave_flow_paginate?${generateParamPaginate(request)}`,
                    method: 'GET',
                    headers: getBaseHeaderApi()
                }),
                providesTags: ['leaveFlows']
            }),
            createLeaveFlow: builder.mutation({
                query: (data: LeaveFlowRequestBody) => ({
                    url: '/leave_flow',
                    method: 'POST',
                    headers: getBaseHeaderApi(),
                    data
                }),
                invalidatesTags: ['leaveFlows']
            }),
            updateLeaveFlow: builder.mutation<any, { data: LeaveFlowRequestBody; id: string }>({
                query: ({ data, id }) => ({
                    url: `/leave_flow/${id}`,
                    method: 'PUT',
                    headers: getBaseHeaderApi(),
                    data
                }),
                invalidatesTags: ['leaveFlows']
            }),
            deleteLeaveFlow: builder.mutation<any, { id: string }>({
                query: ({ id }) => ({
                    url: `/leave_flow/${id}`,
                    method: 'DELETE',
                    headers: getBaseHeaderApi()
                }),
                invalidatesTags: ['leaveFlows']
            })
        };
    }
});

export const {
    useGetLeaveFlowsQuery,
    useGetLeaveFlowsPaginateQuery,
    useCreateLeaveFlowMutation,
    useUpdateLeaveFlowMutation,
    useDeleteLeaveFlowMutation
} = AdminLeaveFlowApi;
