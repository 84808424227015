export const getGreetingTime = (currentTime: Date) => {
    const splitAfternoon = 12; // 24hr time to split the afternoon
    const splitEvening = 18; // 24hr time to split the evening
    const currentHour = currentTime.getHours();

    if (currentHour >= splitAfternoon && currentHour <= splitEvening) {
        // Between 12 PM and 5PM
        return 'Selamat Siang';
    } else if (currentHour >= splitEvening) {
        // Between 6PM and Midnight
        return 'Selamat Malam';
    }
    // Between dawn and noon
    return 'Selamat Pagi';
};
