import { Avatar, Box, Button, Card, CardContent, Grid, IconButton, Skeleton, Typography } from '@mui/material';
import { Layout } from 'components/layout';
import { useEffect, useState } from 'react';
import PersonIcon from '@mui/icons-material/Person';
import DialogCheckIn from '../components/DialogCheckIn';
import { getGreetingTime } from 'lib/functions/greetingTime';
import { decodeHashId } from 'lib/helpers/hasher';
import Cookies from 'universal-cookie';
import { dateConverter } from 'lib/functions/dateConvert';
import config from 'config';
import DialogCheckOut from '../components/DialogCheckOut';
import { useGetCheckAttendanceQuery } from 'lib/api/attendanceApi';
import { useGetWorkCategoriesQuery } from 'lib/api/admin/adminWorkCategoryApi';
import HistoryIcon from '@mui/icons-material/History';
import { useNavigate } from 'react-router';

enum AttendanceType {
    newDay,
    alreadyClockIn,
    alreadyClockOut,
    alreadyBreakIn
}

interface IAttendancePageProps {}

const AttendancePage = (props: IAttendancePageProps) => {
    const navigate = useNavigate();
    const { isLoading: attendanceIsLoading, error, data: dataCheck } = useGetCheckAttendanceQuery();
    const { isLoading: workCategoriesIsLoading, error: workCategoriesError, data: dataCategories } = useGetWorkCategoriesQuery();

    const [workDurationInMs, setWorkDurationInMs] = useState(0);
    const [attendanceType, setAttendanceType] = useState(AttendanceType.newDay);
    const [dataLocation, setLocation] = useState({
        longitude: 0,
        latitude: 0
    });
    const [isLoading, setLoading] = useState(true);
    const [greetingTime, setGreetingTime] = useState('Hello');
    const [name, setName] = useState('');
    // const [attendance, setAttendance] = useState<Attendance>();

    useEffect(() => {
        setLoading(true);
        if (dataCheck) {
            if (dataCheck.data) {
                // setAttendance(dataCheck.data);
                const checkin = new Date(dataCheck.data.date_time_check_in);
                const now = new Date();
                setWorkDurationInMs(now.getTime() - checkin.getTime());
                setAttendanceType(AttendanceType.alreadyClockIn);
            } else {
                setAttendanceType(AttendanceType.newDay);
            }
        }
        setLoading(false);
    }, [dataCheck]);

    // const initialData = async () => {
    //     setLoading(true);
    //     await apiCheckAttendance().then(async (val) => {
    //         console.log(val);
    //         if (val.data) {
    //             setAttendance(val.data);
    //             const checkin = new Date(val.data.date_time_check_in * 1000);
    //             const now = new Date();
    //             setWorkDurationInMs(now.getTime() - checkin.getTime());
    //             setAttendanceType(AttendanceType.alreadyClockIn);
    //         } else {
    //             setAttendanceType(AttendanceType.newDay);
    //             await apiGetWorkCategories().then((val) => {
    //                 setWorkCategories(val.data);
    //                 console.log(val);
    //             });
    //         }
    //         setLoading(false);
    //     });
    // };

    useEffect(() => {
        setLoading(true);
        stopwatch();

        const cookies = new Cookies();
        setName(decodeHashId(cookies.get(config.cookieUserName)));
        navigator.geolocation.getCurrentPosition(function (position) {
            setLocation({
                longitude: position.coords.longitude,
                latitude: position.coords.latitude
            });
        });
        setGreetingTime(getGreetingTime(new Date()));
        setLoading(false);
    }, []);

    const stopwatch = () => {
        const interval = setInterval(() => {
            setWorkDurationInMs((old) => old + 1000);
        }, 1000);
        return () => {
            clearInterval(interval);
        };
    };

    const [openDialogCheckIn, setOpenDialogCheckIn] = useState(false);
    const [openDialogCheckOut, setOpenDialogCheckOut] = useState(false);

    const handleClickCheckInOpen = () => {
        setOpenDialogCheckIn(true);
    };

    const handleCheckInClose = () => {
        setOpenDialogCheckIn(false);
    };

    const handleClickCheckOutOpen = () => {
        setOpenDialogCheckOut(true);
    };

    const handleCheckOutClose = () => {
        setOpenDialogCheckOut(false);
    };
    return (
        <Layout headerTitle="Attendance" pageTitle="Attendance">
            <Grid container justifyContent="center">
                <Grid item container md={12} lg={7} textAlign="center">
                    <Grid item sx={{ width: '100%' }}>
                        <Card>
                            <CardContent>
                                <>
                                    {isLoading || attendanceIsLoading || workCategoriesIsLoading ? (
                                        <Skeleton variant="rounded" animation="wave" height={278} />
                                    ) : (
                                        <Grid container direction={'column'}>
                                            <Grid item>
                                                {(() => {
                                                    if (attendanceType == AttendanceType.newDay) {
                                                        return (
                                                            <Grid container direction="column" paddingY={48}>
                                                                <Grid item>
                                                                    <Typography variant="h2">
                                                                        {greetingTime}, {name}
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid item>
                                                                    <Typography color={'grey'}>
                                                                        (Long. {dataLocation.longitude}, Lat. {dataLocation.latitude})
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid item>
                                                                    <Typography color={'grey'} paddingTop={32} paddingBottom={14}>
                                                                        Yet to start work for today!
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid item>
                                                                    <Button
                                                                        onClick={() => {
                                                                            handleClickCheckInOpen();
                                                                        }}
                                                                        variant="contained"
                                                                    >
                                                                        Clock In
                                                                    </Button>
                                                                </Grid>
                                                            </Grid>
                                                        );
                                                    } else if (
                                                        attendanceType == AttendanceType.alreadyClockIn ||
                                                        attendanceType == AttendanceType.alreadyBreakIn
                                                    ) {
                                                        return (
                                                            <Grid container direction="column">
                                                                <Grid item container direction="row" justifyContent="space-between">
                                                                    <Grid item>
                                                                        <Grid container direction="row" spacing={8}>
                                                                            <Grid item>
                                                                                <Avatar>
                                                                                    <PersonIcon />
                                                                                </Avatar>
                                                                            </Grid>
                                                                            <Grid item>
                                                                                <Grid container direction="column" textAlign="start">
                                                                                    <Grid item>
                                                                                        <Typography>{name}</Typography>
                                                                                    </Grid>
                                                                                    <Grid item>
                                                                                        <Typography
                                                                                            variant="h4"
                                                                                            color={'grey'}
                                                                                            fontWeight={900}
                                                                                        >
                                                                                            {dateConverter(workDurationInMs)}
                                                                                        </Typography>
                                                                                    </Grid>
                                                                                </Grid>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Grid>
                                                                    <Grid item>
                                                                        <Grid container direction="row" spacing={8}>
                                                                            <Grid item>
                                                                                {attendanceType == AttendanceType.alreadyClockIn ? (
                                                                                    <Button
                                                                                        variant="contained"
                                                                                        color="warning"
                                                                                        onClick={() =>
                                                                                            setAttendanceType(AttendanceType.alreadyBreakIn)
                                                                                        }
                                                                                    >
                                                                                        Take a Break
                                                                                    </Button>
                                                                                ) : (
                                                                                    <Button
                                                                                        variant="contained"
                                                                                        color="primary"
                                                                                        onClick={() =>
                                                                                            setAttendanceType(AttendanceType.alreadyClockIn)
                                                                                        }
                                                                                    >
                                                                                        Resume Work
                                                                                    </Button>
                                                                                )}
                                                                            </Grid>
                                                                            <Grid item>
                                                                                <Button
                                                                                    variant="contained"
                                                                                    color="error"
                                                                                    onClick={() => {
                                                                                        handleClickCheckOutOpen();
                                                                                    }}
                                                                                >
                                                                                    Clock Out
                                                                                </Button>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                                <Grid item paddingTop={54}>
                                                                    <Typography variant="h2">
                                                                        {greetingTime}, {name}
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid item paddingBottom={48}>
                                                                    {attendanceType == AttendanceType.alreadyClockIn ? (
                                                                        <Typography color={'grey'} paddingTop={32} paddingBottom={14}>
                                                                            You are currently working
                                                                        </Typography>
                                                                    ) : (
                                                                        <Typography color={'grey'} paddingTop={32} paddingBottom={14}>
                                                                            You are on a break now
                                                                        </Typography>
                                                                    )}
                                                                </Grid>
                                                            </Grid>
                                                        );
                                                    } else if (attendanceType == AttendanceType.alreadyClockOut) {
                                                        return (
                                                            <Grid container direction="column" paddingY={48}>
                                                                <Grid item>
                                                                    <Typography variant="h2">Good Evening, Farli Nahrul Javier</Typography>
                                                                </Grid>
                                                                <Grid item>
                                                                    <Typography color={'grey'} paddingTop={32}>
                                                                        You have stopped working.
                                                                    </Typography>
                                                                    <Typography color={'grey'} paddingBottom={14}>
                                                                        You have worked for 10 hours 15 minutes today.
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid item>
                                                                    <Button
                                                                        onClick={() => {
                                                                            setAttendanceType(AttendanceType.alreadyClockIn);
                                                                        }}
                                                                        variant="contained"
                                                                    >
                                                                        Clock In
                                                                    </Button>
                                                                </Grid>
                                                            </Grid>
                                                        );
                                                    }

                                                    return <></>;
                                                })()}
                                            </Grid>
                                            <Grid item alignItems={'end'} alignContent={'end'} alignSelf={'end'} textAlign={'end'}>
                                                <IconButton
                                                    onClick={() => {
                                                        navigate('/attendance/history');
                                                    }}
                                                >
                                                    <HistoryIcon />
                                                </IconButton>
                                            </Grid>
                                        </Grid>
                                    )}
                                </>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Grid>
            {dataCategories?.data && (
                <DialogCheckIn
                    open={openDialogCheckIn}
                    handleClose={() => {
                        handleCheckInClose();
                        // initialData();
                    }}
                    longitude={dataLocation.longitude.toString()}
                    latitude={dataLocation.latitude.toString()}
                    workCategories={dataCategories?.data ?? []}
                />
            )}
            <DialogCheckOut
                open={openDialogCheckOut}
                handleClose={() => {
                    handleCheckOutClose();
                    // initialData();
                }}
                longitude={dataLocation.longitude.toString()}
                latitude={dataLocation.latitude.toString()}
                attendance={dataCheck?.data}
            />
        </Layout>
    );
};

export default AttendancePage;
