import { Dialog, DialogContent, CircularProgress, DialogTitle, Typography, DialogActions, Button, Box } from '@mui/material';
import { FormInput } from 'components/input/hook-form';
import { WorkCategoryType } from 'interfaces/work-category-type';
import React, { useEffect, useState } from 'react';
import { BaseForm } from '../../../../components/input/hook-form/BaseForm';
import { ActionMeta, InputActionMeta } from 'react-select';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { decodeHashId } from 'lib/helpers/hasher';
import Cookies from 'universal-cookie';
import config from 'config';
import Select from 'react-select';
import { WorkCategoryRequestBody, useCreateWorkCategoryMutation } from 'lib/api/admin/adminWorkCategoryApi';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';

interface ICreateWorkCategoryDialogProps {
    open: boolean;
    handleClose: () => void;
    workCategoryTypes: WorkCategoryType[];
}

const CreateWorkCategoryDialog = ({ open, handleClose, workCategoryTypes }: ICreateWorkCategoryDialogProps) => {
    const cookies = new Cookies();
    const [createWorkCategory] = useCreateWorkCategoryMutation();
    const initialFormData: WorkCategoryRequestBody = {
        name: '',
        category_type_id: '',
        company_id: +decodeHashId(cookies.get(config.cookieCompanyId))
    };
    const { handleSubmit, control, reset, setValue } = useForm({
        defaultValues: initialFormData,
        mode: 'onSubmit',
        resolver: yupResolver(
            yup
                .object({
                    // employeeId: yup.number().required('*Email is required'),
                    name: yup.string().required('*Name is required'),
                    category_type_id: yup.string().required('*Work Category Type is required')
                })
                .required()
        )
    });
    const onSubmit = async (data: WorkCategoryRequestBody) => {
        setLoading(true);
        await createWorkCategory(data)
            .unwrap()
            .then((e) => {
                setLoading(false);
                withReactContent(Swal).fire('Sukses', 'Berhasil menambahkan data!', 'success');
            })
            .catch((error) => {});
        reset.apply(initialFormData);
        setLoading(false);
        handleClosee();
    };
    const [selectedCategoryType, setSelectedCategoryType] = useState<WorkCategoryType | null>();
    useEffect(() => {
        setValue('category_type_id', selectedCategoryType?.id);
    }, [selectedCategoryType]);

    const [isLoading, setLoading] = useState(false);
    const handleClosee = (event?: any, reason?: any) => {
        if (!isLoading) {
            handleClose();
        }
        if (reason && reason == 'backdropClick') return;
        handleClose();
    };
    return (
        <Dialog open={open} onClose={handleClosee}>
            <form onSubmit={handleSubmit(onSubmit)}>
                {isLoading ? (
                    <DialogContent>
                        <CircularProgress />
                    </DialogContent>
                ) : (
                    <div>
                        <DialogTitle variant="h3">Create Work Category</DialogTitle>
                        <DialogContent>
                            <Box sx={{ mb: 20, mt: 20 }}>
                                <FormInput control={control} label="Name" type="text" name="name" />
                            </Box>
                            <BaseForm name="category_type_id" control={control} label="tes">
                                <Select
                                    placeholder={<Typography>Select Work Category Type</Typography>}
                                    getOptionLabel={(e) => e.name ?? ''}
                                    getOptionValue={(e) => e.id.toString() ?? ''}
                                    options={workCategoryTypes}
                                    value={selectedCategoryType}
                                    menuPortalTarget={document.body}
                                    styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                                    onChange={(newValue, actionMeta) => {
                                        setSelectedCategoryType(newValue);
                                    }}
                                />
                            </BaseForm>
                        </DialogContent>
                        <DialogActions>
                            {/* <Button onClick={handleClose}>Cancel</Button> */}
                            <Button size="large" type="submit">
                                Create
                            </Button>
                        </DialogActions>
                    </div>
                )}
            </form>
        </Dialog>
    );
};

export default CreateWorkCategoryDialog;
