import { Typography, Button, Grid, FormControl, InputLabel } from '@mui/material';
import { omit } from 'lodash';
import Select, { GroupBase, Props } from 'react-select';

interface CustomSelectAsyncProps {
    label: string;
    onSelectAll?: Function;
    disableSelectAll?: boolean;
    required?: boolean;
}

type SelectProps<Option, IsMulti extends boolean = false, Group extends GroupBase<Option> = GroupBase<Option>> = Props<
    Option,
    IsMulti,
    Group
> &
    CustomSelectAsyncProps;

const CustomSelect = <Option, IsMulti extends boolean = false, Group extends GroupBase<Option> = GroupBase<Option>>(
    props: SelectProps<Option, IsMulti, Group>
) => {
    props = { ...props, placeholder: <Typography>{props.label}</Typography> };
    const reactSelectProps = omit(props, ['label', 'className']);
    return (
        <div>
            <Grid container alignItems={'center'} paddingTop={0} marginTop={0}>
                <Typography variant="caption" paddingTop={0} marginTop={0}>
                    {props.label}
                </Typography>
                <Grid item xs={6} paddingTop={0} marginTop={0}></Grid>
                {props.disableSelectAll && (
                    <Grid item xs={6} textAlign={'right'}>
                        <Button
                            variant="text"
                            size="small"
                            onClick={() => {
                                if (props.onSelectAll) {
                                    props.onSelectAll!();
                                }
                            }}
                        >
                            select all
                        </Button>
                    </Grid>
                )}

                {/* {onSelectAll && (
                )} */}
            </Grid>
            <Select {...reactSelectProps} required={props.required} />
        </div>
    );
};

export default CustomSelect;
