import { getBaseHeaderApi } from '../services/apiHelper';
import { adminApi, baseApi } from '../services/services';
import { ApiResponse } from '../../../interfaces/api-response';
import { Dashboard } from 'interfaces/dashboard';
import { Title } from 'interfaces/title';

export const adminTitleApi = adminApi.injectEndpoints({
    endpoints(builder) {
        return {
            getTitles: builder.query<ApiResponse<Title[]>, void>({
                query: () => ({
                    url: '/title',
                    method: 'GET',
                    headers: getBaseHeaderApi()
                })
            })
        };
    }
});

export const { useGetTitlesQuery } = adminTitleApi;
