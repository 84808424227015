import { useState } from 'react';
import { Layout } from 'components/layout';
import { useGetAdminDashboardQuery } from 'lib/api/admin/adminDashboardApi';
import { Grid, Box } from '@mui/material';
import DashboardInfoCard from '../components/DashboardInfoCard';
import TableLoader from 'components/loader/TableLoader';
import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoff';
import InfoIcon from '@mui/icons-material/Info';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CheckIcon from '@mui/icons-material/Check';
import LeaveTable from '../components/leaveTable';
import EmployeeContractTable from '../components/employeeContractTable';
import ModalClockIn from '../components/modalClockIn';
// import { useGetDashboardQuery } from 'lib/api/rtk/dashboardApi';
// import { apiGetDashboard } from '../DashboardService';

const AdminDashboardPage = () => {
    const { isLoading, error, data, isFetching, currentData } = useGetAdminDashboardQuery();
    const [open, setOpen] = useState(false);

    return (
        <Layout headerTitle="Dashboard" pageTitle="Dashboard">
            <ModalClockIn open={open} handleClose={() => setOpen(false)} onClick={() => setOpen(true)} />
            <Grid container direction="column" spacing={20}>
                <Grid item container spacing={20}>
                    <Grid item xs={12} md={6} lg={3}>
                        <DashboardInfoCard
                            value={data?.data?.countClockIn}
                            subtitle={'Clock In Employee'}
                            icon={FlightTakeoffIcon}
                            cardColor={'primary.dark'}
                            onClick={() => setOpen(true)}
                            clikable
                        />
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                        <DashboardInfoCard
                            value={data?.data?.countEmployeesContract}
                            subtitle={'Contract Employee'}
                            icon={InfoIcon}
                            cardColor={'warning.dark'}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                        <DashboardInfoCard
                            value={data?.data?.countEmployeesPermanent}
                            subtitle={'Permanent Employee'}
                            icon={AccessTimeIcon}
                            cardColor={'success.dark'}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                        <DashboardInfoCard
                            value={data?.data?.countAllEmployee}
                            subtitle={'Jumlah Employee'}
                            icon={CheckIcon}
                            cardColor={'grey.500'}
                        />
                    </Grid>
                </Grid>
                {isLoading || isFetching ? (
                    <TableLoader />
                ) : (
                    data &&
                    data.data && (
                        <Grid item container spacing={20}>
                            <Grid item xs={12} md={12} lg={6}>
                                <p style={{ fontWeight: 'bold' }}>Daftar Leave Pending</p>
                                <LeaveTable tableData={data.data.leave} />
                            </Grid>
                            <Grid item xs={12} md={12} lg={6}>
                                <p style={{ fontWeight: 'bold' }}>{'Daftar Pegawai Kontrak < 1 Bulan'}</p>
                                <EmployeeContractTable tableData={data.data.employee} />
                            </Grid>
                        </Grid>
                    )
                )}
            </Grid>
        </Layout>
    );
};

export default AdminDashboardPage;
