// assets

import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import WorkOutlineIcon from '@mui/icons-material/WorkOutline';
import AccountTreeOutlinedIcon from '@mui/icons-material/AccountTreeOutlined';
import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoff';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import AddHomeWorkIcon from '@mui/icons-material/AddHomeWork';
// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const settingFeatures = {
    id: 'settingFeatures',
    title: 'Setting',
    type: 'group',
    children: [
        {
            id: 'work-categories',
            title: 'Work Categories',
            type: 'item',
            url: '/admin/work_categories',
            icon: WorkOutlineIcon,
            breadcrumbs: false
        },
        {
            id: 'cost-center',
            title: 'Cost Center',
            type: 'item',
            url: '/admin/cost_center',
            icon: AccountTreeOutlinedIcon,
            breadcrumbs: false
        },
        {
            id: 'department',
            title: 'Department',
            type: 'item',
            url: '/admin/department',
            icon: AccountBalanceIcon,
            breadcrumbs: false
        },
        {
            id: 'sub-company',
            title: 'Sub Company',
            type: 'item',
            url: '/admin/sub_company',
            icon: AddHomeWorkIcon,
            breadcrumbs: false
        },
        {
            id: 'leave-flow',
            title: 'Leave Flow',
            type: 'item',
            url: '/admin/leave_flow',
            icon: FlightTakeoffIcon,
            breadcrumbs: false
        }
    ]
};

export default settingFeatures;
