import { generateParamPaginate, getBaseHeaderApi, setParam } from '../services/apiHelper';
import { adminApi, baseApi } from '../services/services';
import { ApiResponse, ApiResponseWithPagination } from '../../../interfaces/api-response';
import { Dashboard } from 'interfaces/dashboard';
import { PaginateRequest } from 'interfaces/api-request';
import { Employee } from 'interfaces/employee';
import { CompanyId } from 'interfaces/company-id';

export interface EmployeeRequestBody extends CompanyId {
    company_id: number;
    first_name: string;
    last_name: string;
    email: string;
    password?: string;
    ktp_number?: string; // required
    status: string;
    join_date?: Date | string; // required
    gender: string;
    privilege_id?: string;
    title_id?: string;
    department_id?: string;
    direct_spv_id?: number;
    spv_id?:
        | number
        | {
              value: number;
              label: string;
          };
    bank_id?: string;
    bpjs_ks_family_id?: string;
    phone_number?: string;
    phone_number_address_now?: string;
    address?: string;
    address_now?: string;
    birth_date?: Date | string;
    birth_place?: string;
    marital_status?: string;
    blood_type?: string;
    religion?: string;
    citizen?: string;
    end_date?: Date | string;
    permanent_date?: Date | string;
    terminate_date?: Date | string;
    terminate_reason?: string;
    awards?: string;
    dicipline?: string;
    salary?: number;
    salary_type?: string;
    bank_account_number?: string;
    bank_account_name?: string;
    npwp?: string;
    ptkp?: string;
    tax_method?: string;
    tax_salary?: string;
    tax_date?: Date | string;
    tax_status?: string;
    bpjs_ktn_number?: string;
    bpjs_ktn_date?: Date | string;
    bpjs_ks_number?: string;
    bpjs_ks_date?: Date | string;
    bpjs_ks_cost?: string;
    retired_type?: string;
    retired_date?: Date | string;
    relative_name?: string;
    relative_phone_number?: string;
    npwp_address?: string;
    photo?: string;
    is_approver?: boolean;
    leave_flow_id?: string;
    leave_balance?: number;
    nik?: string;
    tanggungan?:
        | {
              tanggungan: string;
              name: string;
              birth_place: string;
              birth_date: Date | string | undefined;
              last_education: string;
          }[]
        | undefined;
    darurat_hubungan?: string;
    darurat_name?: string;
    darurat_address?: string;
    darurat_phone_number?: string;
}

export interface EmployeeResetPasswordRequestBody {
    id?: string | number;
}

export const adminEmployeeApi = adminApi.enhanceEndpoints({ addTagTypes: ['employees'] }).injectEndpoints({
    endpoints(builder) {
        return {
            getEmployeeSearch: builder.mutation<
                ApiResponseWithPagination<Employee[]>,
                PaginateRequest & { name?: string; title?: string; role?: string; status?: string; is_approver?: string }
            >({
                query: (request) => ({
                    url: `/employee?${generateParamPaginate(request)}${setParam('title', request.title)}${setParam(
                        'status',
                        request.status
                    )}${setParam('role', request.role)}${setParam('is_approver', request.is_approver)}`,
                    method: 'GET',
                    headers: getBaseHeaderApi()
                })
            }),
            getEmployee: builder.query<
                ApiResponseWithPagination<Employee[]>,
                PaginateRequest & { name?: string; title?: string; role?: string; status?: string; is_approver?: string }
            >({
                query: (request) => ({
                    url: `/employee?${generateParamPaginate(request)}${setParam('title', request.title)}${setParam(
                        'status',
                        request.status
                    )}${setParam('role', request.role)}`,
                    method: 'GET',
                    headers: getBaseHeaderApi()
                }),
                providesTags: ['employees']
            }),
            getEmployeeById: builder.query<ApiResponse<Employee>, { id: string }>({
                query: (request) => ({
                    url: `/employee/${request.id}`,
                    method: 'GET',
                    headers: getBaseHeaderApi()
                }),
                providesTags: ['employees']
            }),
            createEmployee: builder.mutation({
                query: (data: EmployeeRequestBody) => ({
                    url: '/employee',
                    method: 'POST',
                    headers: getBaseHeaderApi(),
                    data
                }),
                invalidatesTags: ['employees']
            }),
            updateEmployee: builder.mutation<any, { data: EmployeeRequestBody; id: string | number }>({
                query: ({ data, id }) => ({
                    url: `/employee/${id}`,
                    method: 'PUT',
                    headers: getBaseHeaderApi(),
                    data
                }),
                invalidatesTags: ['employees']
            }),
            deleteEmployee: builder.mutation<any, { id: string | number }>({
                query: ({ id }) => ({
                    url: `/employee/${id}`,
                    method: 'DELETE',
                    headers: getBaseHeaderApi()
                }),

                invalidatesTags: ['employees']
            }),
            resetPasswordEmployee: builder.mutation<any, EmployeeResetPasswordRequestBody>({
                query: (data) => ({
                    url: `/employee/reset_password`,
                    method: 'POST',
                    headers: getBaseHeaderApi(),
                    data
                }),

                invalidatesTags: ['employees']
            })
        };
    }
});

export const {
    useGetEmployeeSearchMutation,
    useGetEmployeeQuery,
    useCreateEmployeeMutation,
    useGetEmployeeByIdQuery,
    useUpdateEmployeeMutation,
    useDeleteEmployeeMutation,
    useResetPasswordEmployeeMutation
} = adminEmployeeApi;
