import { Box, Card, CardContent, Grid, Skeleton, SvgIcon, Typography } from '@mui/material';

interface IDashboardInfoCardProps {
    value?: string | number;
    subtitle: string;
    icon: typeof SvgIcon;
    cardColor: string;
    onClick?: () => void;
    clikable?: boolean;
}

const DashboardInfoCard = (props: IDashboardInfoCardProps) => {
    return (
        <Card sx={{ backgroundColor: props.cardColor }}>
            <CardContent>
                <Grid container direction="column" justifyContent="center">
                    <Grid item>
                        <Grid container direction="row" justifyContent="space-between" alignItems="center">
                            <Grid item>
                                <Box>
                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                        <Typography variant="h3" color={'background.paper'}>
                                            {props.value ?? <Skeleton animation="wave" />}
                                        </Typography>
                                        {props.clikable && (
                                            <Typography
                                                variant="h5"
                                                color={'background.paper'}
                                                style={{ marginLeft: '10px', cursor: 'pointer', textDecoration: 'underline' }}
                                                onClick={props.onClick} // Replace handleClick with your actual click handler function
                                            >
                                                {'detail'}
                                            </Typography>
                                        )}
                                    </div>
                                    <Typography variant="body1" color={'background.paper'}>
                                        {props.subtitle}
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item>{<props.icon fontSize="large" sx={{ color: 'background.paper' }} />}</Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

export default DashboardInfoCard;
