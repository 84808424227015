import { Dialog, DialogContent, CircularProgress, DialogTitle, DialogActions, Button, Box, Grid } from '@mui/material';
import { FormInput } from 'components/input/hook-form';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { decodeHashId } from 'lib/helpers/hasher';
import Cookies from 'universal-cookie';
import config from 'config';
import { WorkCategoryRequestBody } from 'lib/api/admin/adminWorkCategoryApi';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import { CostCenterRequestBody, useCreateCostCenterMutation } from 'lib/api/admin/adminCostCenterApi';
import Select from 'react-select';
import { useGetSubCompaniesQuery } from 'lib/api/admin/adminSubCompanyApi';
import { Department } from 'interfaces/department';
import { SubCompany } from 'interfaces/sub-company';
import { useGetDepartmentsQuery } from 'lib/api/admin/adminDepartmentApi';
import SelectEmployee from './SelectEmployee';
import { EmployeeResetPasswordRequestBody, useResetPasswordEmployeeMutation } from 'lib/api/admin/adminEmployeeApi';
import { Employee } from 'interfaces/employee';

interface IResetPasswordDialogProps {
    open: boolean;
    handleClose: () => void;
}

const ResetPasswordDialog = ({ open, handleClose }: IResetPasswordDialogProps) => {
    const [resetPassword] = useResetPasswordEmployeeMutation();
    const initialFormData: EmployeeResetPasswordRequestBody = {
        id: undefined
    };
    const { handleSubmit, control, reset, setValue } = useForm({
        defaultValues: initialFormData,
        mode: 'onSubmit',
        resolver: yupResolver(
            yup
                .object({
                    id: yup.string().required('*Employee is required')
                })
                .required()
        )
    });
    const [selectedEmployee, setSelectedEmployee] = useState<Employee>();

    useEffect(() => {
        setValue('id', selectedEmployee?.id);
    }, [selectedEmployee]);

    const onSubmit = async (data: EmployeeResetPasswordRequestBody) => {
        setLoading(true);
        await resetPassword(data)
            .unwrap()
            .then((e) => {
                setLoading(false);
                withReactContent(Swal).fire(
                    'Sukses',
                    `Berhasil reset password! Password employee dikirim ke ${selectedEmployee?.email}`,
                    'success'
                );
            })
            .catch((error) => {});
        reset.apply(initialFormData);
        setLoading(false);
        handleClosee();
    };

    const [isLoading, setLoading] = useState(false);
    const handleClosee = (event?: any, reason?: any) => {
        if (!isLoading) {
            handleClose();
        }
        if (reason && reason == 'backdropClick') return;
        handleClose();
    };
    return (
        <Dialog open={open} onClose={handleClosee}>
            <form onSubmit={handleSubmit(onSubmit)}>
                {isLoading ? (
                    <DialogContent>
                        <CircularProgress />
                    </DialogContent>
                ) : (
                    <div>
                        <DialogTitle variant="h3">Reset Password</DialogTitle>
                        <DialogContent>
                            <Grid container spacing={20} direction={'column'}>
                                <Grid item>
                                    <SelectEmployee
                                        control={control}
                                        label="Select Employee"
                                        name="id"
                                        onSelectedEmployee={(e) => {
                                            // setValue('id', e?.id);
                                            setSelectedEmployee(e);
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button size="large" type="submit">
                                Reset
                            </Button>
                        </DialogActions>
                    </div>
                )}
            </form>
        </Dialog>
    );
};

export default ResetPasswordDialog;
