import { yupResolver } from '@hookform/resolvers/yup';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import MapIcon from '@mui/icons-material/Map';
import {
    Button,
    Card,
    CardContent,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid,
    IconButton,
    ImageList,
    ImageListItem,
    Radio,
    RadioGroup,
    TableCell,
    TableRow,
    Typography
} from '@mui/material';
import { LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { BaseForm } from 'components/input/hook-form/BaseForm';
import { Layout } from 'components/layout';
import BaseTablePaginator from 'components/table/BaseTablePaginator';
import { API_BASE_URL } from 'configs/apiUrl';
import { PaginateRequest } from 'interfaces/api-request';
import { Employee } from 'interfaces/employee';
import { Order } from 'interfaces/table';
import { WorkCategory } from 'interfaces/work-category';
import {
    useExportExcelAttendanceEmployeeMutation,
    useExportPdfAttendanceEmployeeMutation,
    useGetAttendanceCompanyQuery
} from 'lib/api/attendanceApi';
import { useGetEmployeeSearchMutation } from 'lib/api/admin/adminEmployeeApi';
import { useGetWorkCategoriesQuery } from 'lib/api/admin/adminWorkCategoryApi';
import { dateToStringWIB, getMinOffsetFromJakartaToLocale } from 'lib/helpers/dateHelper';
import CardFilter from 'modules/admin/employee/components/CardFilter';
import moment from 'moment-timezone';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import Select from 'react-select';
import * as yup from 'yup';
import { EmployeeExportExcelRequestBody } from '../../../../lib/api/attendanceApi';
import SelectEmployee from '../../employee/components/SelectEmployee';
import FilterSelectContent from 'modules/admin/employee/components/FilterSelectContent';

interface TablePaginationActionsProps {
    count: number;
    page: number;
    rowsPerPage: number;
    onPageChange: (event: React.MouseEvent<HTMLButtonElement>, newPage: number) => void;
}

enum SortAttendanceEnum {
    name,
    date_time_check_in,
    date_time_check_out,
    lokasi,
    pekerjaan,
    work_category
}

function convertTZ(date: string | Date) {
    return new Date((typeof date === 'string' ? new Date(date) : date).toLocaleString('en-US', { timeZone: 'Asia/Jakarta' }));
}

const AttendanceAdminPage = () => {
    const [orderBy, setOrderBy] = useState<string>();
    const [order, setOrder] = useState<Order>('asc');
    const [paginateRequest, setPaginateRequest] = useState<PaginateRequest & { work_category?: string }>({
        current_page: 0,
        per_page: 10,
        order: order
    });
    //title needs
    const { isLoading: workCategoryIsLoading, error: workCategoryError, data: workCategoryData } = useGetWorkCategoriesQuery();
    const [selectedWorkCategory, setselectedworkCategory] = useState<readonly WorkCategory[]>([]);
    // useEffect(() => {
    //     setselectedworkCategory(workCategoryData?.data ?? []);
    // }, [workCategoryData]);
    // useEffect(() => {
    //     setPaginateRequest({
    //         ...paginateRequest,
    //         work_category: selectedWorkCategory.map((val) => val.name).join(',')
    //     });
    // }, [selectedWorkCategory]);
    useEffect(() => {
        setPaginateRequest({ ...paginateRequest, order: order });
    }, [order]);

    useEffect(() => {
        setPaginateRequest({ ...paginateRequest, order_by: orderBy });
    }, [orderBy]);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [page, setPage] = useState(0);
    const [querySearch, setQuerySearch] = useState('');
    const [dialogImageOpen, setDialogImageOpen] = useState(false);
    const [dialogExportOpen, setDialogExportOpen] = useState(false);
    const [selectedListUrlImage, setselectedListUrlImage] = useState<string[]>([]);
    const { isLoading, error, data, isFetching } = useGetAttendanceCompanyQuery({
        ...paginateRequest,
        current_page: +paginateRequest.current_page! + 1
    });
    const [employees, setEmployees] = useState<Employee[]>([]);
    const [selectedEmployee, setSelectedEmployee] = useState<Employee>();
    const [getEmployee] = useGetEmployeeSearchMutation();
    const [exportExcel] = useExportExcelAttendanceEmployeeMutation();
    const [exportPdf] = useExportPdfAttendanceEmployeeMutation();
    const [searchText, setSearchText] = useState('');
    const [fromDate, setFromDate] = useState<Date>();
    const [untilDate, setUntilDate] = useState<Date>();
    const [isDownloading, setisDownloading] = useState(false);

    const styles = {
        valueContainer: (base: any) => ({
            ...base,
            maxHeight: 80,
            overflowY: 'auto'
        }),
        multiValue: (base: any, state: any) => {
            return state.data.isFixed ? { ...base, backgroundColor: 'gray' } : base;
        },
        multiValueLabel: (base: any, state: any) => {
            return state.data.isFixed ? { ...base, fontWeight: 'bold', color: 'white', paddingRight: 6 } : base;
        },
        multiValueRemove: (base: any, state: any) => {
            return state.data.isFixed ? { ...base, display: 'none' } : base;
        }
    };

    const { setValue, handleSubmit, control, reset, watch, getValues } = useForm<
        EmployeeExportExcelRequestBody & {
            type?: string;
        }
    >({
        defaultValues: {},
        mode: 'onSubmit',
        resolver: yupResolver(
            yup
                .object({
                    from_date: yup.date().required('*fromDate wajib diisi'),
                    until_date: yup
                        .date()
                        .min(yup.ref('from_date'), "end date can't be before start date")
                        .required('*untilDate wajib diisi'),
                    type: yup.string().required('*Export Type wajib diisi'),
                    employee_id: yup.number().when('type', {
                        is: 'pdf',
                        then: yup.number().required('*Employee wajib diisi')
                    })
                })
                .required()
        )
    });

    const handleClickOpenDialogImage = (data: string[]) => {
        setselectedListUrlImage(data);
        setDialogImageOpen(true);
    };

    const handleCloseDialogImage = () => {
        setDialogImageOpen(false);
    };

    const handleClickOpenDialogExport = () => {
        setDialogExportOpen(true);
    };

    const handleCloseDialogExport = () => {
        setDialogExportOpen(false);
    };

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setPaginateRequest({ ...paginateRequest, current_page: newPage });
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setPaginateRequest({ ...paginateRequest, current_page: 0, per_page: parseInt(event.target.value, 10) });
    };

    const handleClickOpenMap = (latitude: string | null, longitude: string | null) => {
        if (latitude !== null && longitude !== null) {
            const googleMapsUrl = `https://www.google.com/maps?q=${latitude},${longitude}`;
            window.open(googleMapsUrl, '_blank');
        } else {
            // Handle the case where latitude or longitude is null
            console.error('Latitude or longitude is null');
        }
    };

    useEffect(() => {
        setValue('type', 'pdf');
        getEmployee({ per_page: 5 })
            .unwrap()
            .then((e) => {
                setEmployees(e.data ?? []);
            });
    }, []);

    const onSubmit = (
        data: EmployeeExportExcelRequestBody & {
            type?: string;
        }
    ) => {
        setisDownloading(true);
        console.log(data);
        if (data.type == 'pdf') {
            console.log('PDF');
            exportPdf(data)
                .unwrap()
                .then((res) => {
                    window.open(API_BASE_URL + '/download_file/' + res.data?.file_name, '_blank');
                    handleCloseDialogExport();
                    setisDownloading(false);
                });
        } else if (data.type == 'excel') {
            console.log('EXCEL');
            exportExcel(data)
                .unwrap()
                .then((res) => {
                    window.open(API_BASE_URL + '/download_file/' + res.data?.file_name, '_blank');
                    handleCloseDialogExport();
                    setisDownloading(false);
                });
        } else {
            console.error('ERROR: TYPE NULL');
        }
    };
    return (
        <Layout headerTitle="Attendance" pageTitle="Attendance" disableBreadcrumb>
            <Grid container direction={'column'} spacing={20}>
                <Grid item>
                    <CardFilter isLoading={workCategoryIsLoading}>
                        <Grid container spacing={20}>
                            <Grid item xs={12}>
                                <FilterSelectContent
                                    label="Select Work Category"
                                    onSelectAll={() => {
                                        setselectedworkCategory(workCategoryData?.data ?? []);
                                    }}
                                    inputId={'select-work-category'}
                                    isMulti
                                    closeMenuOnSelect={false}
                                    getOptionLabel={(e) => e.name ?? ''}
                                    getOptionValue={(e) => e.id ?? ''}
                                    options={workCategoryData?.data}
                                    value={selectedWorkCategory}
                                    menuPortalTarget={document.querySelector('body')}
                                    onChange={(newValue, actionMeta) => {
                                        setselectedworkCategory(newValue);
                                    }}
                                    styles={styles}
                                />
                            </Grid>
                            <Grid item xs={12} textAlign={'right'}>
                                <Button
                                    variant="contained"
                                    onClick={() => {
                                        setPaginateRequest({
                                            ...paginateRequest,
                                            work_category: selectedWorkCategory.map((val) => val.name).join(',')
                                        });
                                    }}
                                >
                                    Apply
                                </Button>
                            </Grid>
                        </Grid>
                    </CardFilter>
                </Grid>
                <Grid item>
                    <Card>
                        <CardContent>
                            <Grid container direction="column" spacing={10}>
                                <Grid item container justifyContent="flex-end" spacing={8}>
                                    <Grid item>
                                        <Button onClick={handleClickOpenDialogExport} variant="outlined">
                                            Export
                                        </Button>
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <BaseTablePaginator
                                        isLoading={isLoading || isFetching || workCategoryIsLoading}
                                        count={data?.meta.total ?? 0}
                                        rowsPerPage={+paginateRequest.per_page!}
                                        page={+paginateRequest.current_page!}
                                        onPageChange={handleChangePage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                        headCells={[
                                            { id: 'name', label: 'Name', isSortable: true },
                                            { id: 'date_time_check_in', label: 'Clock In', isSortable: true },
                                            { id: 'date_time_check_out', label: 'Clock Out', isSortable: true },
                                            { id: 'cost_center', label: 'Cost Center', isSortable: true },
                                            { id: 'lokasi', label: 'Lokasi', isSortable: true },
                                            { id: 'pekerjaan', label: 'Pekerjaan', isSortable: true },
                                            { id: 'work_category', label: 'Kategori Pekerjaan', isSortable: true }
                                        ]}
                                        onSearchchange={(v) => {
                                            setPaginateRequest({ ...paginateRequest, q: v, current_page: 0 });
                                        }}
                                        orderBy={orderBy}
                                        order={order}
                                        onRequestSort={(newOrderBy, newOrder) => {
                                            setOrderBy(newOrderBy);
                                            setOrder(newOrder);
                                            setPaginateRequest({ ...paginateRequest, current_page: 0 });
                                        }}
                                    >
                                        {data?.data?.map((row) => (
                                            <TableRow
                                                key={row.id + row.date_time_check_in}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                <TableCell component="th" scope="row">
                                                    {row.name}
                                                </TableCell>
                                                <TableCell>
                                                    {dateToStringWIB({ date: row.date_time_check_in, withTime: true })}
                                                    <IconButton size="small" onClick={() => handleClickOpenDialogImage(row.urlCI ?? [])}>
                                                        <AttachFileIcon color="primary" />
                                                    </IconButton>
                                                    <IconButton
                                                        size="small"
                                                        onClick={() => handleClickOpenMap(row.latitude_check_in, row.longitude_check_in)}
                                                    >
                                                        <MapIcon color="primary" />
                                                    </IconButton>
                                                </TableCell>
                                                <TableCell>
                                                    {dateToStringWIB({ date: row.date_time_check_out, withTime: true })}
                                                    {(row.urlCO?.length ?? 0) >= 1 && (
                                                        <>
                                                            <IconButton
                                                                size="small"
                                                                onClick={() => handleClickOpenDialogImage(row.urlCO ?? [])}
                                                            >
                                                                <AttachFileIcon color="primary" />
                                                            </IconButton>
                                                            <IconButton
                                                                size="small"
                                                                onClick={() =>
                                                                    handleClickOpenMap(row.latitude_check_out, row.longitude_check_out)
                                                                }
                                                            >
                                                                <MapIcon color="primary" />
                                                            </IconButton>
                                                        </>
                                                    )}
                                                </TableCell>
                                                <TableCell>{row.cost_center?.name}</TableCell>
                                                <TableCell>{row.lokasi}</TableCell>
                                                <TableCell>{row.pekerjaan ?? '-'}</TableCell>
                                                <TableCell>{row.work_category_name ?? '-'}</TableCell>
                                            </TableRow>
                                        ))}
                                    </BaseTablePaginator>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
            <Dialog scroll="body" fullWidth={true} maxWidth={'xl'} open={dialogImageOpen} onClose={handleCloseDialogImage}>
                <DialogTitle>List Image</DialogTitle>
                <DialogContent sx={{ justify: 'center', alignItems: 'center', textAlign: 'center' }}>
                    {/* <DialogContentText>
                        You can set my maximum width and whether to adapt or not.aw da wdaw daw daw daw daw daw daw da wd
                    </DialogContentText> */}
                    <ImageList
                        sx={{ alignItems: 'center', justify: 'center' }}
                        cols={selectedListUrlImage.length <= 2 ? 2 : 3}
                        // cols={3}
                        rowHeight={'auto'}
                    >
                        {selectedListUrlImage.map((item) => (
                            <ImageListItem key={item} sx={{ border: 1, borderColor: 'primary.main' }}>
                                <img
                                    src={`${item}`}
                                    srcSet={`${item}`}
                                    alt={item}
                                    // style={{ maxHeight: '200px', maxWidth: '100%' }}
                                    loading="lazy"
                                />
                            </ImageListItem>
                        ))}
                    </ImageList>
                </DialogContent>
            </Dialog>
            <Dialog fullWidth={isDownloading ? false : true} open={dialogExportOpen} onClose={handleCloseDialogExport}>
                {isDownloading ? (
                    <DialogContent>
                        <CircularProgress />
                    </DialogContent>
                ) : (
                    <div>
                        <DialogTitle>Export Data Attendance</DialogTitle>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <DialogContent>
                                <Grid container direction={'column'} spacing={10}>
                                    <Grid item>
                                        <BaseForm name="type" control={control} label="TES">
                                            <FormLabel id="demo-export-type-label">Export Type</FormLabel>
                                            <RadioGroup
                                                row
                                                aria-labelledby="demo-export-type-label"
                                                name="type"
                                                onChange={(event) => {
                                                    event.persist();
                                                    const { id, name, value, type } = event.target;

                                                    setValue('type', value);
                                                }}
                                                defaultValue={getValues('type')}
                                            >
                                                <FormControlLabel value="pdf" control={<Radio />} label="PDF" />
                                                <FormControlLabel value="excel" control={<Radio />} label="Excel" />
                                            </RadioGroup>
                                        </BaseForm>
                                    </Grid>
                                    <Grid item>
                                        <FormLabel id="date-range-radio-button">Date Range</FormLabel>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <Grid container direction={'row'} alignItems={'center'} justifyContent={'center'}>
                                                <Grid item xs>
                                                    <BaseForm name="from_date" control={control} label="TES">
                                                        <MobileDatePicker
                                                            slotProps={{ textField: { fullWidth: true } }}
                                                            onChange={(date: Date | null) => {
                                                                setFromDate(date || undefined);
                                                                console.log(
                                                                    moment(date?.toISOString())
                                                                        .add(getMinOffsetFromJakartaToLocale(), 'minutes')
                                                                        .toDate()
                                                                );
                                                                setValue(
                                                                    'from_date',
                                                                    moment(date?.toISOString())
                                                                        .add(getMinOffsetFromJakartaToLocale(), 'minutes')
                                                                        .toDate() || undefined
                                                                );
                                                            }}
                                                        />
                                                    </BaseForm>
                                                </Grid>
                                                <Typography paddingX={8}> - </Typography>
                                                <Grid item xs>
                                                    <BaseForm name="until_date" control={control} label="TES">
                                                        <MobileDatePicker
                                                            slotProps={{ textField: { fullWidth: true } }}
                                                            onChange={(date: Date | null) => {
                                                                setUntilDate(date || undefined);
                                                                // console.log(getMinOffsetFromJakartaToLocale());
                                                                console.log(
                                                                    moment(date?.toISOString())
                                                                        .endOf('day')
                                                                        .add(getMinOffsetFromJakartaToLocale(), 'minutes')
                                                                        .toDate()
                                                                );
                                                                // console.log(
                                                                //     moment(date?.toISOString())
                                                                //         .add(getMinOffsetFromJakartaToLocale(), 'minutes')
                                                                //         .toDate()
                                                                // );
                                                                // date?.setZone("Asia/Jakarta").toISO();
                                                                setValue(
                                                                    'until_date',
                                                                    moment(date?.toISOString())
                                                                        .endOf('day')
                                                                        .add(getMinOffsetFromJakartaToLocale(), 'minutes')
                                                                        .toDate()
                                                                );
                                                            }}
                                                        />
                                                    </BaseForm>
                                                </Grid>
                                            </Grid>
                                        </LocalizationProvider>
                                    </Grid>
                                    {watch().type == 'pdf' && (
                                        <Grid item>
                                            <SelectEmployee
                                                control={control}
                                                label="Select Employee"
                                                name="employee_id"
                                                onSelectedEmployee={(e) => {
                                                    setValue('employee_id', e?.id);
                                                }}
                                            />
                                            {/* <BaseForm name="employee_id" control={control} label="TES">
                                                <FormLabel id="search-select-label">Select Employee</FormLabel>
                                                <SelectMui
                                                    // Disables auto focus on MenuItems and allows TextField to be in focus

                                                    MenuProps={{ autoFocus: false }}
                                                    labelId="search-select-label"
                                                    id="search-select"
                                                    value={selectedEmployee || ''}
                                                    label=""
                                                    onChange={(e) => {
                                                        const val = JSON.parse(e.target.value as string) as Employee;
                                                        setSelectedEmployee(e.target.value as Employee);
                                                        setValue('employee_id', val.id);
                                                    }}
                                                    onClose={() => setSearchText('')}
                                                    // This prevents rendering empty string in Select's value
                                                    // if search text would exclude currently selected option.
                                                    // renderValue={() => selectedEmployee!}
                                                >
                                                    <ListSubheader>
                                                        <TextField
                                                            size="small"
                                                            // Autofocus on textfield
                                                            // autoFocus
                                                            placeholder="Type to search..."
                                                            fullWidth
                                                            InputProps={{
                                                                startAdornment: (
                                                                    <InputAdornment position="start">
                                                                        <SearchIcon />
                                                                    </InputAdornment>
                                                                )
                                                            }}
                                                            onChange={(e) => {
                                                                setSearchText(e.target.value);
                                                                setSelectedEmployee(undefined);
                                                                setValue('employee_id', undefined);
                                                                getEmployee({ q: searchText, per_page: 5 })
                                                                    .unwrap()
                                                                    .then((e) => {
                                                                        setEmployees(e.data ?? []);
                                                                    });
                                                            }}
                                                            onKeyDown={(e) => {
                                                                if (e.key !== 'Escape') {
                                                                    // Prevents autoselecting item while typing (default Select behaviour)
                                                                    e.stopPropagation();
                                                                }
                                                            }}
                                                        />
                                                    </ListSubheader>
                                                    {employees.map((option, i) => (
                                                        <MenuItem key={i} value={JSON.stringify(option)}>
                                                            {`${option.first_name} ${option.last_name} (${option.email})`}
                                                        </MenuItem>
                                                    ))}
                                                </SelectMui>
                                            </BaseForm> */}
                                        </Grid>
                                    )}
                                </Grid>
                            </DialogContent>
                            <DialogActions>
                                {/* <Button onClick={handleClose}>Cancel</Button> */}
                                <Button size="large" type="submit">
                                    Export Now
                                </Button>
                            </DialogActions>
                        </form>
                    </div>
                )}
            </Dialog>
        </Layout>
    );
};

export default AttendanceAdminPage;
