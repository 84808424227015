import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Box,
    CircularProgress,
    Autocomplete,
    TextField,
    Select as MuiSelect,
    MenuItem,
    SelectChangeEvent,
    ListSubheader,
    InputAdornment,
    InputLabel,
    FormControl,
    Typography,
    Grid
} from '@mui/material';
import DynamicMultipleInputFileWithButton from './DynamicMultipleInputFile';
import * as yup from 'yup';
import { useEffect, useMemo, useRef, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
// import { CheckInRequestBody, apiCheckIn } from '../AttendanceService';
import { FormInput } from 'components/input/hook-form';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import Cookies from 'universal-cookie';
import config from 'config';
import { decodeHashId } from 'lib/helpers/hasher';
const cookies = new Cookies();
import SearchIcon from '@mui/icons-material/Search';
import { WorkCategory } from 'interfaces/work-category';
import { CheckInRequestBody, useCheckInMutation } from 'lib/api/attendanceApi';
import { useGetCostCentersQuery } from 'lib/api/admin/adminCostCenterApi';
import Select from 'react-select';
import { CostCenter } from 'interfaces/cost-center';
import { BaseForm } from 'components/input/hook-form/BaseForm';

interface IDialogCheckInProps {
    open: boolean;
    handleClose: () => void;
    longitude: string;
    latitude: string;
    workCategories: WorkCategory[];
}

// const allOptions = ['Option One', 'Option Two', 'Option Three', 'Option Four'];

const containsText = (text: string, searchText: string) => text.toLowerCase().indexOf(searchText.toLowerCase()) > -1;

const DialogCheckIn = ({ open, handleClose, latitude, longitude, workCategories }: IDialogCheckInProps) => {
    const { isLoading: costCenterIsLoading, data: costCenterData, isFetching } = useGetCostCentersQuery();
    const [selectedCostCenter, setSelectedCostCenter] = useState<CostCenter | null>();
    useEffect(() => {
        setValue('cost_center_id', selectedCostCenter?.id);
    }, [selectedCostCenter]);

    const initialFormData: CheckInRequestBody = {
        location: '',
        jobdesc: '',
        longitude: '',
        latitude: '',
        photos: [],
        other_work_category: '',
        cost_center_id: undefined,
        company_id: +decodeHashId(cookies.get(config.cookieCompanyId)),
        employee_id: +decodeHashId(cookies.get(config.cookieUserID))
    };
    const [checkIn] = useCheckInMutation();
    const [error, setError] = useState('');
    const [isLoading, setLoading] = useState(false);
    const [listFile, setListFile] = useState<any[]>([]);
    const [selectedOption, setSelectedOption] = useState(workCategories[0]?.name);
    const [showOtherInput, setShowOtherInput] = useState(false);

    const [searchText, setSearchText] = useState('');
    const displayedOptions = useMemo(() => {
        const filteredData = workCategories.map((e) => e.name);
        return filteredData.filter((option) => containsText(option!, searchText));
    }, [searchText]);

    const { handleSubmit, control, reset, setValue } = useForm({
        defaultValues: initialFormData,
        mode: 'onSubmit',
        resolver: yupResolver(
            yup
                .object({
                    // employeeId: yup.number().required('*Email is required'),
                    location: yup.string().required('*Lokasi is required'),
                    jobdesc: yup.string().required('*Pekerjaan is required'),
                    other_work_category: yup.string().when([], {
                        is: () => showOtherInput,
                        then: yup.string().required('*Other Work Category required')
                    }),
                    cost_center_id: yup.string().required('*Cost Center is required')
                })
                .required()
        )
    });
    const handleClosee = (event?: any, reason?: any) => {
        if (!isLoading) {
            handleClose();
        }
        if (reason && reason == 'backdropClick') return;
        handleClose();
    };

    const onSubmit = async (data: CheckInRequestBody) => {
        setLoading(true);
        data.photos = listFile;
        data.longitude = longitude;
        data.latitude = latitude;
        data.work_category_id = workCategories.find((e) => e.name == selectedOption)?.id;
        await checkIn(data)
            .unwrap()
            .then((e) => {
                setLoading(false);
                withReactContent(Swal).fire('Sukses', 'Check In Berhasil!', 'success');
            })
            .catch((error) => {});
        reset.apply(initialFormData);
        setLoading(false);
        handleClosee();
    };

    return (
        <Dialog open={open} onClose={handleClosee}>
            <form onSubmit={handleSubmit(onSubmit)}>
                {isLoading ? (
                    <DialogContent>
                        <CircularProgress />
                    </DialogContent>
                ) : (
                    <div>
                        <DialogTitle variant="h3">Clock In</DialogTitle>
                        <DialogContent>
                            <Grid container spacing={20} direction={'column'}>
                                <Grid item sx={{ mt: 20 }}>
                                    <FormInput control={control} label="Location Description" type="text" name="location" />
                                </Grid>
                                <Grid item>
                                    <FormInput control={control} label="What are you going to do" type="text" name="jobdesc" />
                                </Grid>
                                <Grid item>
                                    <FormControl fullWidth>
                                        <InputLabel id="search-select-label">Work Category</InputLabel>
                                        <MuiSelect
                                            // Disables auto focus on MenuItems and allows TextField to be in focus
                                            MenuProps={{ autoFocus: false }}
                                            labelId="search-select-label"
                                            id="search-select"
                                            value={selectedOption}
                                            label="Work Category"
                                            onChange={(e) => {
                                                setSelectedOption(e.target.value);
                                                setShowOtherInput(e.target.value === 'Others');
                                            }}
                                            onClose={() => setSearchText('')}
                                            // This prevents rendering empty string in Select's value
                                            // if search text would exclude currently selected option.
                                            renderValue={() => selectedOption}
                                        >
                                            <ListSubheader>
                                                <TextField
                                                    size="small"
                                                    // Autofocus on textfield
                                                    // autoFocus
                                                    placeholder="Type to search..."
                                                    fullWidth
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <SearchIcon />
                                                            </InputAdornment>
                                                        )
                                                    }}
                                                    onChange={(e) => setSearchText(e.target.value)}
                                                    onKeyDown={(e) => {
                                                        if (e.key !== 'Escape') {
                                                            // Prevents autoselecting item while typing (default Select behaviour)
                                                            e.stopPropagation();
                                                        }
                                                    }}
                                                />
                                            </ListSubheader>
                                            {displayedOptions.map((option, i) => (
                                                <MenuItem key={i} value={option}>
                                                    {option}
                                                </MenuItem>
                                            ))}
                                            <MenuItem key={'Others'} value={'Others'}>
                                                Others
                                            </MenuItem>
                                        </MuiSelect>
                                        {showOtherInput && (
                                            <div>
                                                <Typography sx={{ mt: 14, mb: 14 }}>Please Specify</Typography>
                                                <Box>
                                                    <FormInput
                                                        control={control}
                                                        label="Work Category (Others)"
                                                        placeHolder="Please Specify"
                                                        type="text"
                                                        name="other_work_category"
                                                    />
                                                </Box>
                                            </div>
                                        )}
                                    </FormControl>
                                </Grid>
                                <Grid item>
                                    <BaseForm name="cost_center_id" control={control} label="Cost Center">
                                        <Select
                                            getOptionLabel={(e) => e.name ?? ''}
                                            getOptionValue={(e) => e.id.toString() ?? ''}
                                            options={costCenterData?.data}
                                            value={selectedCostCenter}
                                            menuPortalTarget={document.querySelector('body')}
                                            styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                                            onChange={(newValue, actionMeta) => {
                                                setSelectedCostCenter(newValue);
                                            }}
                                        />
                                    </BaseForm>
                                </Grid>
                                <Grid item>
                                    <DynamicMultipleInputFileWithButton
                                        onChange={(e) => {
                                            setListFile(e);
                                        }}
                                    />
                                </Grid>
                            </Grid>
                            <Box sx={{ mb: 20, mt: 20 }}></Box>
                            <Box sx={{ mb: 20 }}></Box>
                        </DialogContent>
                        <DialogActions>
                            {/* <Button onClick={handleClose}>Cancel</Button> */}
                            <Button size="large" type="submit">
                                Clock In
                            </Button>
                        </DialogActions>
                    </div>
                )}
            </form>
        </Dialog>
    );
};

export default DialogCheckIn;
