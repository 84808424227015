import { Dialog, DialogContent, CircularProgress, DialogTitle, DialogActions, Button, Box, Grid } from '@mui/material';
import { FormInput } from 'components/input/hook-form';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { decodeHashId } from 'lib/helpers/hasher';
import Cookies from 'universal-cookie';
import config from 'config';
import { WorkCategoryRequestBody } from 'lib/api/admin/adminWorkCategoryApi';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import { CostCenterRequestBody, useCreateCostCenterMutation } from 'lib/api/admin/adminCostCenterApi';
import Select from 'react-select';
import { useGetSubCompaniesQuery } from 'lib/api/admin/adminSubCompanyApi';
import { Department } from 'interfaces/department';
import { SubCompany } from 'interfaces/sub-company';
import { useGetDepartmentsQuery } from 'lib/api/admin/adminDepartmentApi';

interface ICreateCostCenterDialogProps {
    open: boolean;
    handleClose: () => void;
}

const styles = {
    valueContainer: (base: any) => ({
        ...base,
        maxHeight: 80,
        overflowY: 'auto'
    }),
    multiValue: (base: any, state: any) => {
        return state.data.isFixed ? { ...base, backgroundColor: 'gray' } : base;
    },
    multiValueLabel: (base: any, state: any) => {
        return state.data.isFixed ? { ...base, fontWeight: 'bold', color: 'white', paddingRight: 6 } : base;
    },
    multiValueRemove: (base: any, state: any) => {
        return state.data.isFixed ? { ...base, display: 'none' } : base;
    }
};

const CreateCostCenterDialog = ({ open, handleClose }: ICreateCostCenterDialogProps) => {
    const { isLoading: departmentLoading, data: departmentData } = useGetDepartmentsQuery();
    const { isLoading: subCompanyLoading, data: subCompanyData } = useGetSubCompaniesQuery();
    const [selectedDepartment, setSelectedDepartment] = useState<Department | null>();
    useEffect(() => {
        setValue('department_id', selectedDepartment?.id);
    }, [selectedDepartment]);
    const [selectedSubCompany, setSelectedSubCompany] = useState<SubCompany | null>();
    useEffect(() => {
        setValue('sub_company_id', selectedSubCompany?.id);
    }, [selectedSubCompany]);

    const cookies = new Cookies();
    const [createCostCenter] = useCreateCostCenterMutation();
    const initialFormData: CostCenterRequestBody = {
        name: '',
        description: '',
        amount: undefined,
        sub_company_id: undefined,
        department_id: undefined,
        company_id: +decodeHashId(cookies.get(config.cookieCompanyId))
    };
    const { handleSubmit, control, reset, setValue } = useForm({
        defaultValues: initialFormData,
        mode: 'onSubmit',
        resolver: yupResolver(
            yup
                .object({
                    name: yup.string().required('*Name is required')
                })
                .required()
        )
    });
    const onSubmit = async (data: CostCenterRequestBody) => {
        setLoading(true);
        await createCostCenter(data)
            .unwrap()
            .then((e) => {
                setSelectedDepartment(null);
                setSelectedSubCompany(null);
                setLoading(false);
                reset.apply(initialFormData);
                withReactContent(Swal).fire('Sukses', 'Berhasil menambahkan data!', 'success');
            })
            .catch((error) => {});
        setLoading(false);
        handleClosee();
    };

    const [isLoading, setLoading] = useState(false);
    const handleClosee = (event?: any, reason?: any) => {
        if (!isLoading) {
            handleClose();
        }
        if (reason && reason == 'backdropClick') return;
        handleClose();
    };
    return (
        <Dialog open={open} onClose={handleClosee}>
            <form onSubmit={handleSubmit(onSubmit)}>
                {isLoading || departmentLoading || subCompanyLoading ? (
                    <DialogContent>
                        <CircularProgress />
                    </DialogContent>
                ) : (
                    <div>
                        <DialogTitle variant="h3">Create Cost Center</DialogTitle>
                        <DialogContent>
                            <Grid container spacing={20} direction={'column'}>
                                <Grid item>
                                    <Box sx={{ mt: 20 }}>
                                        <FormInput control={control} label="Name" type="text" name="name" />
                                    </Box>
                                </Grid>
                                <Grid item>
                                    <FormInput control={control} label="Description" type="text" name="description" />
                                </Grid>
                                <Grid item>
                                    <FormInput control={control} label="Amount" type="number" name="amount" />
                                </Grid>
                                <Grid item>
                                    <Select
                                        getOptionLabel={(e) => e.name ?? ''}
                                        getOptionValue={(e) => e.id.toString() ?? ''}
                                        options={departmentData?.data}
                                        value={selectedDepartment}
                                        menuPortalTarget={document.querySelector('body')}
                                        styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                                        onChange={(newValue, actionMeta) => {
                                            setSelectedDepartment(newValue);
                                        }}
                                    />
                                </Grid>
                                <Grid item>
                                    <Select
                                        getOptionLabel={(e) => e.name ?? ''}
                                        getOptionValue={(e) => e.id.toString() ?? ''}
                                        options={subCompanyData?.data}
                                        value={selectedSubCompany}
                                        menuPortalTarget={document.querySelector('body')}
                                        styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                                        onChange={(newValue, actionMeta) => {
                                            setSelectedSubCompany(newValue);
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button size="large" type="submit">
                                Create
                            </Button>
                        </DialogActions>
                    </div>
                )}
            </form>
        </Dialog>
    );
};

export default CreateCostCenterDialog;
